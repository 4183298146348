/*
 *
 * Action definition.
 *
 */

import {
  SHNewProduct,
  SHNewProductArticle,
  SHNEwProductProducer,
  SHNewProductTypeCore,
} from "../../../../shared/src/models/types/type.newProduct";

export const INIT_NEW_PRODUCT_DIALOG = "new-product/init/dialog";
export const RESET_NEW_PRODUCT = "new-product/reset";
export const SELECT_NEW_PRODUCT_TYPE = "new-product/type";
export const SELECT_NEW_PRODUCT_ARTICLE = "new-product/article";
export const SELECT_NEW_PRODUCT_PRODUCER = "new-product/producer";
export const UPDATE_CACHE = "new-product/cache";
export const CREATE_NEW_PRODUCT = "new-product/create";
export const SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT = "new-product/select-from-other-context";

/*
 *
 * State types.
 *
 */
export interface NewProductStore {
  product: NewProduct;
  selectedNewProductTypeCore?: SHNewProductTypeCore;
  selectedNewProductArticle?: SHNewProductArticle;
  selectedNewProductProducer?: SHNEwProductProducer;
  isNewProductUpsertDialogOpen: boolean;
  cache: NewProductCache;
}

export interface NewProduct {
  name: string;
  type: string;
  serialNumber: string;
  productionDate: string;
  configuration: string;
  model: string;
  status: string;
  customer: string;
  receipt: string;
  orderNumber: string;
  orderCost: number;
  deliveryCost: number;
  invoiceNumber: string;
  ekNet: number;
  proportionalDeliveryCost: number;
  address: string;
}

export interface NewProductCache {
  cores: SHNewProductTypeCore[];
  articles: SHNewProductArticle[];
  producers: SHNEwProductProducer[];
}

/**
 *  Actions types
 *
 */
export interface InitNewProductDialogAction {
  type: typeof INIT_NEW_PRODUCT_DIALOG;
  payload: InitNewProductDialogPayload;
}

export interface ResetNewProductAction {
  type: typeof RESET_NEW_PRODUCT;
  payload: NewProductStorePayload;
}

export interface SelectNewProductTypeAction {
  type: typeof SELECT_NEW_PRODUCT_TYPE;
  payload: SelectNewProductTypePayload;
}

export interface SelectNewProductArticleAction {
  type: typeof SELECT_NEW_PRODUCT_ARTICLE;
  payload: SelectNewProductArticlePayload;
}

export interface SelectedNewProductProducerAction {
  type: typeof SELECT_NEW_PRODUCT_PRODUCER;
  payload: SelectedNewProductProducerPayload;
}

export interface UpdateCacheAction {
  type: typeof UPDATE_CACHE;
  payload: UpdateCachePayload;
}

export interface CreateNewProductAction {
  type: typeof CREATE_NEW_PRODUCT;
  payload: CreateNewProductPayload;
}

export interface SelectNewProductFromOtherContextAction {
  type: typeof SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT;
  payload: SelectNewProductFromOtherContextPayload;
}

/**
 *  Payloads types
 */

export type InitNewProductDialogPayload = {
  isNewProductUpsertDialogOpen: boolean;
};

export type ResetNewProductPayload = NewProductStore;

export type SelectNewProductTypePayload = SHNewProductTypeCore | undefined;

export type SelectNewProductArticlePayload = SHNewProductArticle | undefined;

export type SelectedNewProductProducerPayload = SHNEwProductProducer | undefined;

export type UpdateCachePayload = Partial<NewProductCache>;

export type CreateNewProductPayload = Omit<SHNewProduct, "id">;

export type SelectNewProductFromOtherContextPayload = SHNewProduct;

/**
 *  Unit type
 */
export type NewProductStoreAction =
  | SelectNewProductFromOtherContextAction
  | CreateNewProductAction
  | UpdateCacheAction
  | InitNewProductDialogAction
  | ResetNewProductAction
  | SelectNewProductArticleAction
  | SelectNewProductTypeAction
  | SelectedNewProductProducerAction;

export type NewProductStorePayload =
  | SelectNewProductFromOtherContextPayload
  | CreateNewProductPayload
  | UpdateCachePayload
  | InitNewProductDialogPayload
  | ResetNewProductPayload
  | SelectNewProductTypePayload
  | SelectNewProductArticlePayload
  | SelectedNewProductProducerPayload;
