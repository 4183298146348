//
// type.route.tsx
//
// Created by Thomas on 12.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//import { SHUserRole } from "./type.user";

import { LazyExoticComponent, MemoExoticComponent } from "react";
import { SHUserRole } from "../../../../shared/src/models/types/type.user";

export type NonLazyRouteComponent = MemoExoticComponent<(props?: any) => JSX.Element>;
export type LazyRouteComponent = LazyExoticComponent<MemoExoticComponent<(props?: any) => JSX.Element>>;
export type SHRouteGroup = "dashboard" | "user" | "shop" | "about" | "product" | "analytics" | "admin" | "sale";

export interface SHRoute<T extends NonLazyRouteComponent | LazyRouteComponent> {
  path: SHUrlPath;
  visual?: SHRouteVisualConfig;
  requiredRole?: SHUserRole;
  Component: T;
  group?: SHRouteGroup;
}

export interface SHRouteVisualConfig {
  Icon: any;
  label: string;
}

export enum SHUrlPath {
  InitApp = "/init-app",
  Dashboard = "/dashboard",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  Integrations = "/integrations",
  Profile = "/profile",
  Imprint = "/imprint",
  PrivacyPolicy = "/privacy-policy",
  Warehouse = "/warehouse",
  NewProduct = "/new-product",
  InputSuggestions = "/input-suggestions",
  ListUsers = "/list-users",
  AboutApp = "/about-app",
  DocumentTemplateManagement = "/edit-templates",
  CatalogSectionMappings = "/catalog-section-mappings",
  Sale = "/sale",
  Presale = "/pre-sale",
  Postsale = "/post-sale",
  ProductDisposal = "/disposal",
  DisposalProductCores = "/disposal-product-cores",
  Error = "/error",
  Register = "/register/:token",
  AdminSchemas = "/admin-schemas",
  ClientSettings = "/client-settings",
  TraderCoresSearchList = "/traders",
}
