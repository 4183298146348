//
// action.console.tsx
//
// Created by Thomas on 10.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  CLEAR_INPUT,
  ClearConsoleInputAction,
  UPDATE,
  UpdateConsoleAction,
  UpdateConsolePayload
} from "../types/type.console";

/*
 *
 * Functions.
 *
 */

export const updateConsoleAction = (payload: UpdateConsolePayload): UpdateConsoleAction => ({
  type: UPDATE,
  payload,
});

export const clearConsoleInputAction = (): ClearConsoleInputAction => ({
  type: CLEAR_INPUT,
});
