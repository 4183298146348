//
// type.shop.ts
// stockhouse
//
// Created by Thomas Schönmann on 03.09.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import {LazyListData, LazyListDataLoaderPayload} from "../../models/types/type.list";
import {SearchFilter, SearchNativeSelectContainer} from "../../models/types/type.search";

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "SHOP_UPDATE";

export const INIT_REQ = "SHOP_INIT_REQ";
export const INIT_SUCCEEDED = "SHOP_INIT_SUCCEEDED";
export const INIT_FAILED = "SHOP_INIT_FAILED";

export const UPDATE_RESULTS = "SHOP_UPDATE_RESULTS";
export const CLEAR_RESULTS = "SHOP_CLEAR_RESULTS";

export const ADD_TO_SHOPPING_CARD = "SHOP_ADD_TO_SHOPPING_CARD";
export const REMOVE_FROM_SHOPPING_CARD = "SHOP_REMOVE_FROM_SHOPPING_CARD";

/*
 *
 * Types.
 *
 */

export type ShopState = "INIT" | "ERROR" | "READY" | "QUERYING";

export interface ShopStore {
  state: ShopState;
  results: LazyListData;
  field?: string;
  section?: string;
  order?: string;
  filters: SearchFilter[];
  filterSelectors?: SearchNativeSelectContainer[];
  orderSelector?: SearchNativeSelectContainer;
  isShoppingCardOpen: boolean;
  shoppingCardProducts: any[];
}

/*
 *
 * Actions.
 *
 */

export interface UpdateShopStoreAction {
  type: typeof UPDATE;
  payload: UpdateShopStorePayload;
}

export type UpdateShopStorePayload = Partial<ShopStore>;

export interface InitAction {
  type: typeof INIT_REQ;
}

export interface InitSucceededAction {
  type: typeof INIT_SUCCEEDED;
}

export interface InitFailedAction {
  type: typeof INIT_FAILED;
}

export interface UpdateResultsAction {
  type: typeof UPDATE_RESULTS;
  payload: LazyListDataLoaderPayload;
}

export interface ClearResultsAction {
  type: typeof CLEAR_RESULTS;
}

export interface AddToShoppingCardAction {
  type: typeof ADD_TO_SHOPPING_CARD;
  payload: AddToShoppingCardPayload;
}

export interface AddToShoppingCardPayload {
  product: any;
}

export interface RemoveFromShoppingCardAction {
  type: typeof REMOVE_FROM_SHOPPING_CARD;
  payload: RemoveFromShoppingCardPayload;
}

export interface RemoveFromShoppingCardPayload {
  continousId: number;
}

/*
 *
 * Union Action Type.
 *
 */

export type ShopStoreAction =
  | UpdateShopStoreAction
  | InitAction
  | InitSucceededAction
  | InitFailedAction
  | UpdateResultsAction
  | ClearResultsAction
  | AddToShoppingCardAction
  | RemoveFromShoppingCardAction;

export type ShopStorePayload =
  | UpdateShopStorePayload
  | LazyListDataLoaderPayload
  | AddToShoppingCardPayload
  | RemoveFromShoppingCardPayload;
