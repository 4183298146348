//
// hook.canvas.ts
// stockhouse
//
// Created by Thomas Schönmann on 13.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { useEffect, useState } from "react";

import { getThemesAsArray, ThemeStorageKey } from "../models/model.theme";
import { useConnectedUserStore } from "./hook.user";
import { SHAppThemeVariant } from "../../../shared/src/models/types/type.theme";

/*
 *
 * Functions.
 *
 */

export function useConnectedThemeByPreferredColorSchemeFromHTML() {
  const { userSettings } = useConnectedUserStore();
  const [theme, setTheme] = useState(getThemesAsArray()[0].theme);
  const mode = usePreferredColorSchemeFromHTML();

  useEffect(() => {
    const cache: SHAppThemeVariant | null =
      userSettings?.themeVariant || (localStorage.getItem(ThemeStorageKey.ThemeVariant) as SHAppThemeVariant);

    if (Boolean(cache)) {
      switch (cache) {
        case "auto":
          setTheme(getThemesAsArray()[mode === "dark" ? 1 : 0].theme);
          break;
        case "dark":
          setTheme(getThemesAsArray()[1].theme);
          break;
        case "light":
          setTheme(getThemesAsArray()[0].theme);
          break;
      }
    } else if (!userSettings?.themeVariant || userSettings?.themeVariant === "auto")
      setTheme(getThemesAsArray()[mode === "dark" ? 1 : 0].theme);
    else if (userSettings?.themeVariant === "dark") setTheme(getThemesAsArray()[1].theme);
    else if (userSettings?.themeVariant === "light") setTheme(getThemesAsArray()[0].theme);
  }, [mode, userSettings]);

  return { theme, setTheme };
}

/**
 * Get the mode preferred by the user via HTML.
 *
 * @export
 * @returns
 */
export function usePreferredColorSchemeFromHTML() {
  const [mode, setMode] = useState<"dark" | "light" | "unset" | "unsupported">();

  useEffect(() => {
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches;
    const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
    const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

    if (isDarkMode) setMode("dark");
    else if (isLightMode) setMode("light");
    else if (isNotSpecified) setMode("unset");
    else if (hasNoSupport) setMode("unsupported");

    // Now add the listeners.
    window.matchMedia(`(prefers-color-scheme: dark)`).addListener((e) => e.matches && setMode("dark"));
    window.matchMedia(`(prefers-color-scheme: light)`).addListener((e) => e.matches && setMode("light"));
  }, []);

  return mode;
}

export function useStyleEffect(props: { theme: any; setTheme: any; setThemeIndex: any }) {
  const { setTheme, setThemeIndex } = props;
  /*
    useEffect(() => {
      document.body.style.background = theme.palette.primary.main;
    }, [theme]);
    */

  useEffect(() => {
    const previousIndex = localStorage.getItem("selectedThemeIndex");
    if (previousIndex !== null) {
      const i = JSON.parse(previousIndex);

      console.log("i", i);
      setTheme(getThemesAsArray()[i].theme);
      setThemeIndex(i);
    }
  }, [setTheme, setThemeIndex]);
}
