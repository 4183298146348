import { SHSchemaEntityInputSchema } from "../../../shared/src/models/types/type.schema";
import { updateEntity } from "./model.db";
import { SHDomain } from "../../../shared/src/models/types/type.db";
import { SHTableColumn, SHTableSettings } from "../../../shared/src/models/types/type.user";
import { format } from "date-fns";
import { SystemTableColumn, SystemTableVariant } from "./types/type.table";
import { SHPresaleReferenceResolution } from "../../../shared/src/models/types/type.presale";

export function getEntityTableColumns<V extends string, I extends string>(productInputSchema?: SHSchemaEntityInputSchema<V, I>) {
  if (!productInputSchema) return [];
  let columns: string[] = [];
  productInputSchema.groups.forEach((group) => {
    const groupColumns = group.group.properties.map((prop) => prop.id);
    columns = columns.concat(groupColumns);
  });
  return columns;
}

/**
 *
 */
function getNewProductsTableColumns() {
  return [
    "name",
    "type",
    "serialNumber",
    "productionDate",
    "configuration",
    "model",
    "status",
    "customer",
    "receipt",
    "orderNumber",
    "orderCost",
    "deliveryCost",
    "invoiceNumber",
    "ekNet",
    "proportionalDeliveryCost",
    "address",
  ];
}

/**
 *
 */
function getStandardSalesDraftColumns(): SHTableColumn[] {
  return [
    {
      id: "continuousId",
      visible: true,
      label: "ID (continuous)",
    },
    {
      id: "client",
      visible: true,
      label: "CLIENT",
    },
    {
      id: "traderCompany",
      visible: true,
      label: "COMPANY",
    },
    {
      id: "productCores",
      visible: true,
      label: "COUNT PRODUCTS",
    },
  ];
}

/**
 *
 * @param columns
 */
function getBasicTableColumns(columns: string[]): SHTableColumn[] {
  return columns.map((column) => ({
    id: column,
    label: column.replaceAll("_", "").toUpperCase(),
    visible: true,
  }));
}

/**
 *
 * @param columns
 */
function getBasicNewProductTableColumns(columns: string[]): SHTableColumn[] {
  return columns.map((column) => ({
    id: column,
    label: column
      .split("")
      .map((str) => (str === str.toUpperCase() ? `-${str}` : str.toUpperCase()))
      .join(""),
    visible: true,
  }));
}

/**
 *
 * @param basicColumns
 */
export function getBasicTableSettings(basicColumns: string[]): SHTableSettings {
  return {
    preSales: getStandardSalesDraftColumns(),
    warehouse: getBasicTableColumns(basicColumns),
    newProducts: getBasicNewProductTableColumns(getNewProductsTableColumns()),
  };
}

/**
 *
 * @param userSettingsId
 * @param basicColumns
 */
export async function addBasicTableSettings(userSettingsId: string, basicColumns: string[]) {
  const basicSettings = getBasicTableSettings(basicColumns);
  await updateTableSettings(userSettingsId, basicSettings);
  return basicSettings;
}

/**
 *
 * @param userSettingsId
 * @param tableSettings
 */
export async function updateTableSettings(userSettingsId: string, tableSettings: SHTableSettings) {
  await updateEntity({ domain: SHDomain.UserSettings, id: userSettingsId, data: { tableSettings: tableSettings } });
}

/**
 *
 * @param value
 * @param columnId
 * @param variant
 */
export function getTableCellContent<V>(value: V, columnId: string, variant: SystemTableVariant) {
  if (!value) return "-";
  if (columnId.includes("date") && typeof value === "number") {
    return format(new Date(value), "PP");
  }
  if (typeof value === "string" || typeof value === "number") {
    return value;
  }
  if ("searchable" in value) {
    return (value as any).value;
  }
  if ("name" in value) {
    return (value as any).name;
  }
  if (columnId === "productCores") {
    return (value as any).length;
  }

  return value;
}

/**
 *
 * @param label
 */
export function getColumnMinWidth(label: string) {
  if (label.length > 20) return 200;
  if (label.length > 15) return 140;
  if (label.length > 10) return 110;
  return undefined;
}

/**
 *
 * @param settings
 * @param variant
 */
export function getSystemTableColumns<T extends object>(
  settings: SHTableSettings,
  variant: SystemTableVariant
): SystemTableColumn<T>[] {
  // @ts-ignore
  return settings[variant]
    .filter((col) => col.visible)
    .map((col) => ({
      id: col.id,
      accessor: col.id,
      Header: col.label,
      // @ts-ignore
      Cell: ({ row, cell }) =>
        getTableCellContent(row.original[cell.column.id] || row.original.values?.[cell.column.id], cell.column.id, variant),
    }));
}
