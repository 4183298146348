//
// type.appBar.ts
// stockhouse
//
// Created by Thomas Schönmann on 18.09.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "APP_BAR_UPDATE";
export const UPDATE_NAV_DRAWER = "APP_BAR_UPDATE_NAV_DRAWER";
export const UPDATE_SHOPPING_CARD_ICON_VISIBILITY = "APP_BAR_UPDATE_SHOPPING_CARD_ICON_VISIBILITY";
export const UPDATE_PROGRESS = "appBar/update/progress";

/*
 *
 * Types.
 *
 */

export interface AppBarStore {
  isNavDrawerOpen: boolean;
  title: string;
  isShadowDisabled: boolean;
  isStickyEnforced: boolean;
  isShoppingCardIconVisible: boolean;
}

export interface UpdateAppBarStoreAction {
  type: typeof UPDATE;
  payload: UpdateAppBarStorePayload;
}

export type UpdateAppBarStorePayload = Partial<AppBarStore>;

export interface UpdateNavDrawer {
  type: typeof UPDATE_NAV_DRAWER;
  payload: UpdateNavDrawerPayload;
}

export interface UpdateNavDrawerPayload {
  isOpen: boolean;
}

export interface UpdateShoppingCardIconVisibilityAction {
  type: typeof UPDATE_SHOPPING_CARD_ICON_VISIBILITY;
  payload: UpdateShoppingCardIconVisibilityPayload;
}

export interface UpdateShoppingCardIconVisibilityPayload {
  isVisible: boolean;
}

/*
 *
 * Union Action Type.
 *
 */

export type AppBarStoreAction = UpdateAppBarStoreAction | UpdateNavDrawer | UpdateShoppingCardIconVisibilityAction;

export type AppBarStorePayload = UpdateAppBarStorePayload | UpdateNavDrawerPayload | UpdateShoppingCardIconVisibilityPayload;
