//
// action.progress.tsx
//
// Created by Thomas on 12.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  FINISH_THREAD,
  FinishProgressThreadAction,
  FinishProgressThreadPayload,
  RESET,
  ResetProgressAction,
  UpdateProgressAction,
  UpdateProgressPayload,
} from "../types/type.progress";
import { UPDATE_PROGRESS } from "../types/type.appBar";

/*
 *
 * Functions.
 *
 */

export const updateProgressAction = (payload: UpdateProgressPayload): UpdateProgressAction => ({
  type: UPDATE_PROGRESS,
  payload,
});

export const resetProgressAction = (): ResetProgressAction => ({
  type: RESET,
});

export const finishProgressThreadAction = (payload: FinishProgressThreadPayload): FinishProgressThreadAction => ({
  type: FINISH_THREAD,
  payload,
});
