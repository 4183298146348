//
// type.console.tsx
//
// Created by Thomas on 10.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "console/update";
export const CLEAR_INPUT = "console/clear/input";

/*
 *
 * State.
 *
 */

export interface ConsoleStore {
  input: string;
  isVisible: boolean;
}

/*
 *
 * Actions.
 *
 */

export interface UpdateConsoleAction {
  type: typeof UPDATE;
  payload: UpdateConsolePayload;
}

export type UpdateConsolePayload = Partial<ConsoleStore>;

export interface ClearConsoleInputAction {
  type: typeof CLEAR_INPUT;
}

/*
 *
 * Union Action.
 *
 */

export type ConsoleStoreAction = UpdateConsoleAction | ClearConsoleInputAction;

export type ConsoleStorePayload = UpdateConsolePayload;
