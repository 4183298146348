//
// action.trader.tsx
//
// Created by Thomas on 24.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  ADD_COMPANY_LOCATION_FROM_DRAFT,
  AddCompanyLocationFromDraftAction,
  CLOSE_TRADER_UPSERT_DIALOG,
  CloseTraderUpsertDialogAction,
  CREATE_COMPANY,
  CreateCompanyAction,
  INIT_ADD_COMPANY_LOCATION_FROM_DRAFT,
  INIT_ADD_TRADER_CORE,
  INIT_TRADER_COMPANY_CACHE,
  INIT_TRADER_UPSERT_DIALOG,
  InitAddCompanyLocationFromDraftAction,
  InitAddTraderCoreAction,
  InitTraderCompanyCacheAction,
  InitTraderUpsertDialogAction,
  InitTraderUpsertDialogProps,
  OPEN_TRADER_DIALOG,
  OpenTraderDialogAction,
  OpenTraderDialogPayload,
  REMOVE_SELECTED_TRADER_CORE,
  RemoveSelectedTraderCoreAction,
  SELECT_COMPANY,
  SELECT_TRADER_CORE,
  SelectTraderCompanyAction,
  SelectTraderCompanyPayload,
  SelectTraderCoreAction,
  SelectTraderCorePayload,
  SWITCH_TRADER_COMPANY_OPEN,
  SwitchTraderCompanyOpenAction,
  UNSELECT_TRADER_CORE,
  UnselectTraderCoreAction,
  UPDATE_SELECTED_TRADER_CORE,
  UPDATE_SELECTED_TRADER_CORE_DRAFT_VALUES,
  UPDATE_SELECTED_TRADER_CORE_VALUES,
  UPDATE_TRADER_CACHE,
  UPDATE_TRADER_COMPANY_LOCATION_DRAFT,
  UPDATE_TRADER_DRAFT,
  UPDATE_TRADER_INPUT,
  UPDATE_TRADER_STORE,
  UpdateSelectedTraderCoreAction,
  UpdateSelectedTraderCoreDraftValuesAction,
  UpdateSelectedTraderCoreDraftValuesPayload,
  UpdateSelectedTraderCorePayload,
  UpdateSelectedTraderCoreValuesAction,
  UpdateSelectedTraderCoreValuesPayload,
  UpdateTraderCacheAction,
  UpdateTraderCachePayload,
  UpdateTraderInputAction,
  UpdateTraderInputPayload,
  UpdateTraderStoreAction,
  UpdateTraderStoreCompanyLocationDraftAction,
  UpdateTraderStoreCompanyLocationDraftPayload,
  UpdateTraderStoreDraftAction,
  UpdateTraderStoreDraftPayload,
  UpdateTraderStorePayload,
  UPSERT_SELECTED_TRADER_CODE_REJ,
  UPSERT_SELECTED_TRADER_CODE_REQ,
  UPSERT_SELECTED_TRADER_CODE_RES,
  UpsertSelectedTraderCoreAction,
  UpsertSelectedTraderCoreRejAction,
  UpsertSelectedTraderCoreResAction,
  UpsertSelectedTraderCoreResPayload
} from "../types/type.trader";

/*
 *
 * MARK: Actions.
 *
 */

export const updateTraderStoreAction = (payload: UpdateTraderStorePayload): UpdateTraderStoreAction => ({
  type: UPDATE_TRADER_STORE,
  payload,
});

export const initTraderStoreUpsertDialogAction = (payload: InitTraderUpsertDialogProps): InitTraderUpsertDialogAction => ({
  type: INIT_TRADER_UPSERT_DIALOG,
  payload,
});

export const closeTraderStoreUpsertDialogAction = (): CloseTraderUpsertDialogAction => ({
  type: CLOSE_TRADER_UPSERT_DIALOG,
});

export const switchTraderCompanyOpenAction = (): SwitchTraderCompanyOpenAction => ({
  type: SWITCH_TRADER_COMPANY_OPEN,
});

export const updateTraderCacheActions = (payload: UpdateTraderCachePayload): UpdateTraderCacheAction => ({
  type: UPDATE_TRADER_CACHE,
  payload,
});

export const updateTraderInputAction = (payload: UpdateTraderInputPayload): UpdateTraderInputAction => ({
  type: UPDATE_TRADER_INPUT,
  payload,
});

export const selectTraderCompanyAction = (payload: SelectTraderCompanyPayload): SelectTraderCompanyAction => ({
  type: SELECT_COMPANY,
  payload,
});

export const createTraderCompanyAction = (): CreateCompanyAction => ({
  type: CREATE_COMPANY,
});

export const addCompanyLocationFromDraftAction = (): AddCompanyLocationFromDraftAction => ({
  type: ADD_COMPANY_LOCATION_FROM_DRAFT,
});

export const updateTraderDraftAction = (payload: UpdateTraderStoreDraftPayload): UpdateTraderStoreDraftAction => ({
  type: UPDATE_TRADER_DRAFT,
  payload,
});

export const updateTraderStoreCompanyLocationDraftAction = (
  payload: UpdateTraderStoreCompanyLocationDraftPayload
): UpdateTraderStoreCompanyLocationDraftAction => ({
  type: UPDATE_TRADER_COMPANY_LOCATION_DRAFT,
  payload,
});

export const initAddCompanyLocationFromDraftAction = (): InitAddCompanyLocationFromDraftAction => ({
  type: INIT_ADD_COMPANY_LOCATION_FROM_DRAFT,
});

export const initAddTraderCoreAction = (): InitAddTraderCoreAction => ({
  type: INIT_ADD_TRADER_CORE,
});

export const unselectTraderCoreAction = (): UnselectTraderCoreAction => ({
  type: UNSELECT_TRADER_CORE,
});

export const updateSelectedTraderCoreAction = (payload: UpdateSelectedTraderCorePayload): UpdateSelectedTraderCoreAction => ({
  type: UPDATE_SELECTED_TRADER_CORE,
  payload,
});

export const updateSelectedTraderCoreValuesAction = (
  payload: UpdateSelectedTraderCoreValuesPayload
): UpdateSelectedTraderCoreValuesAction => ({
  type: UPDATE_SELECTED_TRADER_CORE_VALUES,
  payload,
});

export const updateSelectedTraderCoreDraftValuesAction = (
  payload: UpdateSelectedTraderCoreDraftValuesPayload
): UpdateSelectedTraderCoreDraftValuesAction => ({
  type: UPDATE_SELECTED_TRADER_CORE_DRAFT_VALUES,
  payload,
});

export const upsertSelectedTraderCoreAction = (): UpsertSelectedTraderCoreAction => ({
  type: UPSERT_SELECTED_TRADER_CODE_REQ,
});

export const upsertSelectedTraderCoreResAction = (
  payload: UpsertSelectedTraderCoreResPayload
): UpsertSelectedTraderCoreResAction => ({
  type: UPSERT_SELECTED_TRADER_CODE_RES,
  payload,
});

export const upsertSelectedTraderCoreRejAction = (): UpsertSelectedTraderCoreRejAction => ({
  type: UPSERT_SELECTED_TRADER_CODE_REJ,
});

export const removeSelectedTraderCoreAction = (): RemoveSelectedTraderCoreAction => ({
  type: REMOVE_SELECTED_TRADER_CORE,
});

export const initTraderCompanyCacheAction = (): InitTraderCompanyCacheAction => ({
  type: INIT_TRADER_COMPANY_CACHE,
});

export const openTraderDialogAction = (payload: OpenTraderDialogPayload): OpenTraderDialogAction => ({
  type: OPEN_TRADER_DIALOG,
  payload,
});

export const selectTraderCoreAction = (payload: SelectTraderCorePayload): SelectTraderCoreAction => ({
  type: SELECT_TRADER_CORE,
  payload,
});
