//
// actions.appBar.js
// stockhouse
//
// Created by Thomas Schönmann on 20.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// Some redux for the AppBar.
//

import {
    UPDATE,
    UPDATE_NAV_DRAWER,
    UPDATE_SHOPPING_CARD_ICON_VISIBILITY,
    UpdateAppBarStoreAction,
    UpdateAppBarStorePayload,
    UpdateNavDrawer,
    UpdateNavDrawerPayload,
    UpdateShoppingCardIconVisibilityAction,
    UpdateShoppingCardIconVisibilityPayload
} from "../types/type.appBar";

/*
 *
 * Actions.
 *
 */

export const update = (payload: UpdateAppBarStorePayload): UpdateAppBarStoreAction => ({
  type: UPDATE,
  payload
});

export const updateNavDrawer = (payload: UpdateNavDrawerPayload): UpdateNavDrawer => ({
  type: UPDATE_NAV_DRAWER,
  payload
});

export const updateShoppingCardIconVisibility = (
  payload: UpdateShoppingCardIconVisibilityPayload
): UpdateShoppingCardIconVisibilityAction => ({
  type: UPDATE_SHOPPING_CARD_ICON_VISIBILITY,
  payload
});
