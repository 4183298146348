//
// type.manageUsers.ts.tsx
//
// Created by Lukas Jäger  on 2020-07-16
// Copyright © expressFlow GmbH. All rights reserved.
//

import { SHUser, SHUserOrderBy } from "../../../../shared/src/models/types/type.user";

export const CHANGE_ORDER = "CHANGE_ORDER";

export const USER_MANAGEMENT_CHANGE_USER_REQ = "USER_MANAGEMENT_CHANGE_USER_REQ";
export const USER_MANAGEMENT_CHANGE_USER_SUCCEEDED = "USER_MANAGEMENT_CHANGE_USER_SUCCEEDED";
export const USER_MANAGEMENT_CLEANUP_USER = "USER_MANAGEMENT_CLEANUP_USER";
export const USER_MANAGEMENT_PERSONAL_INFO = "USER_MANAGEMENT_PERSONAL_INFO";

export interface ManageUsersStore {
  orderBy: SHUserOrderBy;
  order: UserOrder;
  isLoading: boolean;
  uid?: string;
  user?: SHUser;
  personalInfo?: UserPersonalInfo;
  isManageUserDialogOpen: boolean;
}

export interface ChangeUserOrderAction {
  type: typeof CHANGE_ORDER;
  payload: ChangeUserOrderPayload;
}

export type UserOrder = "asc" | "desc";

export interface ChangeUserOrderPayload {
  orderBy: SHUserOrderBy;
}

// load current user for user managment

export interface ChangeUserAction {
  type: typeof USER_MANAGEMENT_CHANGE_USER_REQ;
  payload: ChangeUserPayload;
}

export interface ChangeUserPayload {
  isLoading: boolean;
  uid: string;
}

export interface ChangeUserSucceededAction {
  type: typeof USER_MANAGEMENT_CHANGE_USER_SUCCEEDED;
  payload: ChangeUserSucceededPayload;
}

export interface ChangeUserSucceededPayload {
  isLoading: boolean;
  user: SHUser;
}

export interface CleanupUserAction {
  type: typeof USER_MANAGEMENT_CLEANUP_USER;
}

// store changes to personal info

type UserPersonalInfo = { firstName: string; lastName: string; email: string; hasUpdates: boolean };

export interface StoreUserPersonalInfoAction {
  type: typeof USER_MANAGEMENT_PERSONAL_INFO;
  payload: StoreUserPersonalInfoPayload;
}

export interface StoreUserPersonalInfoPayload {
  firstName: string;
  lastName: string;
  email: string;
  hasUpdates: boolean;
}

export type ManageUsersAction =
  | ChangeUserOrderAction
  | ChangeUserAction
  | ChangeUserSucceededAction
  | CleanupUserAction
  | StoreUserPersonalInfoAction;
export type ManageUsersPayload =
  | ChangeUserOrderPayload
  | ChangeUserPayload
  | ChangeUserSucceededPayload
  | StoreUserPersonalInfoPayload;
