//
// RootProvider.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { PropsWithChildren } from "react";

import StoreProvider from "./StoreProvider";
import ThemeProvider from "./ThemeProvider";
import InAppNotificationProvider from "./InAppNotificationProvider";
import NavigationProvider from "./NavigationProvider";

/*
 *
 * Components.
 *
 */

export default (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <StoreProvider>
      <ThemeProvider>
        <InAppNotificationProvider>
          <NavigationProvider>{children}</NavigationProvider>
        </InAppNotificationProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};
