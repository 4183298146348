//
// util.type.draft.tsx
//
// Created by Thomas on 26.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHEntity } from "../../../../shared/src/models/types/type.db";

/*
 *
 * Interfaces.
 *
 */

export interface DraftEntity<T extends SHEntity> {
  draftId: string;
  entity: T;
}

export interface CountableDraftEntity<T extends SHEntity> extends DraftEntity<T> {
  count: number;
}

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param drafts
 * @param selectedDraft
 */
export function getHotDraftBackIntoAllDrafts<T extends SHEntity, D extends DraftEntity<T>>(
  drafts: D[],
  selectedDraft: D
): Array<D> {
  const indexOfSelected = drafts.findIndex((d) => d.draftId === selectedDraft.draftId);
  const latestDrafts = [...drafts];
  if (indexOfSelected >= 0) latestDrafts[indexOfSelected] = { ...selectedDraft };

  return latestDrafts;
}
