//
// action.shop.ts
// stockhouse
//
// Created by Thomas Schönmann on 03.09.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import {
    ADD_TO_SHOPPING_CARD,
    AddToShoppingCardAction,
    AddToShoppingCardPayload,
    CLEAR_RESULTS,
    ClearResultsAction,
    INIT_FAILED,
    INIT_REQ,
    INIT_SUCCEEDED,
    InitAction,
    InitFailedAction,
    InitSucceededAction,
    REMOVE_FROM_SHOPPING_CARD,
    RemoveFromShoppingCardAction,
    RemoveFromShoppingCardPayload,
    UPDATE,
    UPDATE_RESULTS,
    UpdateResultsAction,
    UpdateShopStoreAction,
    UpdateShopStorePayload
} from "../types/type.shop";
import {LazyListDataClearer, LazyListDataLoader, LazyListDataLoaderPayload} from "../../models/types/type.list";

/*
 *
 * Functions.
 *
 */

export const updateAction = (payload: UpdateShopStorePayload): UpdateShopStoreAction => ({
  type: UPDATE,
  payload
});

export const initAction = (): InitAction => ({
  type: INIT_REQ
});

export const initSucceededAction = (): InitSucceededAction => ({
  type: INIT_SUCCEEDED
});

export const initFailedAction = (): InitFailedAction => ({
  type: INIT_FAILED
});

export const updateResultsAction: LazyListDataLoader = (payload: LazyListDataLoaderPayload): UpdateResultsAction => ({
  type: UPDATE_RESULTS,
  payload
});

export const clearResultsAction: LazyListDataClearer = (): ClearResultsAction => ({
  type: CLEAR_RESULTS
});

export const addToShoppingCardAction = (payload: AddToShoppingCardPayload): AddToShoppingCardAction => ({
  type: ADD_TO_SHOPPING_CARD,
  payload
});

export const removeFromShoppingCardAction = (payload: RemoveFromShoppingCardPayload): RemoveFromShoppingCardAction => ({
  type: REMOVE_FROM_SHOPPING_CARD,
  payload
});
