//
// reducer.root.ts
// stockhouse
//
// Created by Thomas Schönmann on 06.03.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {RESET, RootAction} from "../types/type.root";
import {Reducer} from "react";
import {AnyAction} from "redux";
import {SHStore} from "./index";

/*
 *
 * Functions.
 *
 */

/**
 * Wrapper to reset the whole store by action without implementing
 * a complete-reset in every reducer.
 *
 * @param reducer
 */
export default (reducer: Reducer<SHStore, AnyAction>) => (s: any | undefined, a: RootAction) => {
  if (a.type === RESET) {
    s = undefined;
  }

  return reducer(s, a);
};
