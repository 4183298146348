//
// effect.user.js
// stockhouse
//
// Created by Thomas Schönmann on 28.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// User's react effects.
//

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { loginUserAction, logoutUserAction } from "../store/actions/action.user";
import { UserStoreAction, UserStorePayload } from "../store/types/type.user";
import { auth, firebase, firestore } from "../models/model.firebase";
import { SHUserInvitation } from "../../../shared/src/models/types/type.user";
import { getEntity } from "../models/model.db";
import { SHDomain } from "../../../shared/src/models/types/type.db";
import { addDays } from "date-fns";
import { getIsEmailValid } from "../models/model.auth";
import { createAccountInvitation } from "../serverless/serverless.user";
import { getConnectedActionHook, getConnectedEmptyActionHook, getConnectedStore } from "../store/util/util.hook";

/*
 *
 * Functions.
 *
 */

export const useConnectedUserStore = getConnectedStore("user");
export const useConnectedUserAction = getConnectedActionHook<UserStoreAction, UserStorePayload>();
export const useConnectedUserEmptyAction = getConnectedEmptyActionHook<UserStoreAction>();

/**
 * Listen to auth-changes for a user. This includes
 * login and logout, nothing else.
 *
 */
export function useConnectedUserAuthListener() {
  const dispatch = useDispatch();

  const login = useCallback(({ auth }) => dispatch(loginUserAction({ auth })), [dispatch]);
  const logout = useCallback(() => dispatch(logoutUserAction()), [dispatch]);

  useEffect(() => {
    return auth.onAuthStateChanged((auth) => {
      if (auth) {
        firestore
          .collection("users")
          .doc(auth.uid)
          .update({
            lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            login({ auth });
          });
      } else {
        logout();
      }
    });
  }, [login, logout]);
}

/**
 * Hook composition to handle state + callbacks
 * for the add-user-scenario.
 *
 * @returns   Object of state + handlers.
 */
export function useAddUser() {
  throw Error("unsupported");
}

/**
 * Fetch and store a user's meta data from firestore by
 * providing the correlated ID.
 *
 * @export
 * @param {string} [id]
 * @returns
 */
export function useUserMetaData(id?: string) {
  const [data, setData] = useState<firebase.firestore.DocumentData>();

  useEffect(() => {
    if (!id) return;

    firestore
      .collection(SHDomain.Users)
      .doc(id)
      .get()
      .then((snap) => {
        if (snap.exists) setData(snap.data());
        else throw Error("No user meta data found");
      })
      .catch(console.error);
  }, [id]);

  return data;
}

/**
 *
 *
 * @export
 * @param {{ token: string }} props
 * @returns
 */
export function useAccountRegister(props: { token: string }) {
  const { token } = props;

  const [reservation, setReservation] = useState<SHUserInvitation>();
  const [isReservationFetched, setIsReservationFetched] = useState(false);
  const [isReservationValid, setIsReservationValid] = useState<boolean>(false);
  const [isInitialLoggedOut, setIsInitialLoggedOut] = useState(false);

  // Make sure no user is logged-in when mounting.
  useEffect(() => {
    auth
      .signOut()
      .then(() => setIsInitialLoggedOut(true))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(token)) {
      return setIsReservationValid(false);
    }

    getEntity<SHUserInvitation>({ domain: SHDomain.UserInvitations, id: token }).then((reservation) => {
      if (reservation && reservation.expires <= Date.now()) setIsReservationValid(true);
      setIsReservationFetched(true);
      setReservation(reservation);
    });
  }, [token, setReservation, setIsReservationFetched, setIsReservationValid]);

  return { isReservationFetched, isReservationValid, isInitialLoggedOut, reservation };
}

/**
 * Hook to use for creating an account-invitation.
 */
export function useUserInviteCreation() {
  const user = useConnectedUserStore();

  const [state, setState] = useState<"awaitValidInput" | "inviting" | "succeeded" | "error">("awaitValidInput");
  const [createdInvitation, setCreatedInvitation] = useState<SHUserInvitation>();
  const [store, setStore] = useState<SHUserInvitation>({
    id: "",
    createdAt: Date.now(),
    expires: addDays(new Date(), 1).getTime(),
    firstName: "",
    lastName: "",
    mail: "",
    permissions: [],
    link: "",
    client: user.user?.client || "",
  });

  const update = useCallback(
    (key: keyof SHUserInvitation) => (e: ChangeEvent<HTMLInputElement>) => {
      setStore({ ...store, [key]: e.target.value });
    },
    [store, setStore]
  );

  const isInviteAllowed = useMemo(() => {
    return store.firstName.length > 0 && store.lastName.length > 0 && getIsEmailValid(store.mail);
  }, [store.firstName.length, store.lastName.length, store.mail]);

  const invite = useCallback(
    (props: { onSuccess: (iv: SHUserInvitation) => void; onError: (error: any) => void }) => {
      if (isInviteAllowed) {
        setState("inviting");
        createAccountInvitation({ invitation: store })
          .then(({ invitation }) => {
            setState("succeeded");
            setCreatedInvitation(invitation);
            props.onSuccess(invitation);
          })
          .catch((e) => {
            setState("error");
            props.onError(e);
          });
      }
    },
    [store, isInviteAllowed, setState]
  );

  useEffect(() => {
    if (store.client === "" && user) setStore({ ...store });
  }, [store, user, setStore]);

  return { store, isInviteAllowed, state, createdInvitation, update, invite };
}
