//
// saga.warehouse.tsx
//
// Created by Thomas on 14.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOGIN_USER_SUCCEEDED, LoginUserSucceededAction, UserStore } from "../types/type.user";
import {
  getClientDocumentsQueryRef,
  getClientSingleDocumentData,
  getCollectionRef,
  getDocumentsQueryEntities,
  getDocumentsQueryEntity,
  getEntity,
  removeEntity,
  updateEntity,
  upsertEntity,
  upsertEntityWithContinuousId,
} from "../../models/model.db";
import {
  productUpsertSucceededAction,
  updateProducerArticleImagesAction,
  updateProducerWarehouseAction,
  updateProductCacheAction,
  updateWarehouseAction,
} from "../actions/action.warehouse";
import {
  CREATE_PRODUCER_REQ,
  CreateProducerReqAction,
  DELETE_PRODUCT_CORE,
  DeleteProductCoreAction,
  INIT_NEW_PRODUCT,
  InitNewProductAction,
  PRODUCT_UPSERT_REQ,
  ProductUpsertAction,
  REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA,
  RemoveImageFromProducerArticleMediaAction,
  SELECT_PRODUCER_CORE,
  SELECT_PRODUCT_FROM_OTHER_CONTEXT,
  SelectProducerCoreAction,
  SelectProductFromOtherContextAction,
  UPDATE_PRODUCER,
  UpdateProducerAction,
  UPLOAD_IMAGES_FROM_INPUT,
  UploadImagesFromInputAction,
  WarehouseStore,
  WarehouseStoreProductCoreDraft,
} from "../types/type.warehouse";
import { SHSearchFilterLensConfig } from "../../models/types/type.search";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import { SHStore } from "../reducers";
import { firestore } from "firebase";
import { finishProgressThreadAction, resetProgressAction, updateProgressAction } from "../actions/action.progress";
import {
  SHProductCore,
  SHProductProducerArticle,
  SHProductProducerArticleImageAsset,
  SHProductProducerCore,
  SHProductProducerMedia,
} from "../../../../shared/src/models/types/type.product";
import { getEmptyProductCore, getEmptyProductProducerArticle } from "../../../../shared/src/models/model.product";
import { v4 as uuidv4 } from "uuid";
import { uploadImagesFromInputEffect } from "../../models/model.image";
import { removeStorageElementEffect } from "../../models/model.storage";
import { SHSchemaEntityInputSchema } from "../../../../shared/src/models/types/type.schema";
import { SHProductInputSchemaId, SHProductPropertiesGroupVariant } from "../../../../shared/src/models/types/type.product.schema";
import { getHotDraftBackIntoAllDrafts } from "../util/util.mix.draft";
import { tryCatchSaga } from "../util/util.saga";
import { SHPresale } from "../../../../shared/src/models/types/type.presale";

/*
 *
 * Watcher.
 *
 */

export default function* () {
  yield takeEvery(LOGIN_USER_SUCCEEDED, loginUserWarehouseSaga);
  yield takeEvery(UPDATE_PRODUCER, updateProducerSaga);
  yield takeEvery(CREATE_PRODUCER_REQ, tryCatchSaga(createProducerArticleSaga, { withProgress: true }));
  yield takeEvery(INIT_NEW_PRODUCT, initNewProductSaga);
  yield takeEvery(SELECT_PRODUCER_CORE, selectProducerCoreSaga);
  yield takeEvery(PRODUCT_UPSERT_REQ, tryCatchSaga(upsertProductReqSaga, { withProgress: true }));
  yield takeEvery(DELETE_PRODUCT_CORE, tryCatchSaga(deleteProductCoreSaga, { withProgress: true }));
  yield takeEvery(UPLOAD_IMAGES_FROM_INPUT, tryCatchSaga(uploadImagesFromInputSaga, { withProgress: true }));
  yield takeEvery(REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA, removeImageFromProducerArticleMediaSaga);
  yield takeEvery(SELECT_PRODUCT_FROM_OTHER_CONTEXT, tryCatchSaga(selectProductFromOtherContextSaga, { withProgress: true }));
}

/*
 *
 * MARK: Sagas.
 *
 */

/**
 * Fetch schemas + configs.
 *
 * @param a
 */
function* loginUserWarehouseSaga(a: LoginUserSucceededAction) {
  const { user } = a.payload;

  const productInputSchema: SHSchemaEntityInputSchema<SHProductPropertiesGroupVariant, SHProductInputSchemaId> = yield call(
    getClientSingleDocumentData,
    {
      client: user.client,
      domain: SHDomain.ProductInputSchemas,
    }
  );

  const searchFilterLensConfig: SHSearchFilterLensConfig = yield call(getClientSingleDocumentData, {
    client: user.client,
    domain: SHDomain.SearchFilterLenses,
  });

  yield put(
    updateWarehouseAction({
      productInputSchema,
      searchFilterLensConfig,
    })
  );
}

/**
 *
 * @param a
 */
function* updateProducerSaga(a: UpdateProducerAction) {
  const { producerArticle } = a.payload;
  const store: WarehouseStore = yield select((s: SHStore) => s.warehouse);

  if (store.state !== "productsList" && store.state !== "selectProducer") {
    return;
  }

  const next = { ...store, ...a.payload };

  if (producerArticle) {
    next.inputProducer = {
      ...next.inputProducer,
      producerArea: producerArticle.area,
      producerArticleNr: producerArticle.articleNr,
      producerPosition: producerArticle.position,
    };
  }

  if (next.producerCore && next.producerArticle && next.products === undefined) {
    const threadId = uuidv4();
    console.warn("updateProducerSaga threadid open", threadId);
    yield put(updateProgressAction({ isLoading: true, threadId }));
    next.products = yield call(() =>
      getDocumentsQueryEntities<SHProductCore>({
        domain: SHDomain.ProductCores,
        key: "producerArticleRef",
        value: next.producerArticle!.id,
        op: "==",
        order: {
          key: "continuousId",
          dir: "desc",
        },
      })
    );

    const mediaDocs: Array<SHProductProducerMedia> = yield call(() =>
      getDocumentsQueryEntities<SHProductProducerMedia>({
        domain: SHDomain.ProductProducerMedia,
        key: "producerArticleRef",
        value: next.producerArticle!.id,
        op: "==",
      })
    );
    if (mediaDocs.length === 1) next.producerMedia = mediaDocs[0];
    else if (mediaDocs.length > 1) console.error("mediaDocs.length > 1", mediaDocs);

    console.warn("updateProducerSaga threadid close", threadId);
    yield put(finishProgressThreadAction({ threadId }));
  }

  yield put(updateWarehouseAction(next));
}

/**
 * Fetch articles after the producer has been determined by the user.
 * @param a
 */
function* selectProducerCoreSaga(a: SelectProducerCoreAction) {
  const { id } = a.payload.core;
  const fetch = () =>
    getCollectionRef(SHDomain.ProductProducerArticles)
      .where("producerCoreRef", "==", id)
      .get()
      .then((res) => {
        if (res.empty) return [];
        else return res.docs.map((d) => d.data());
      });

  const producerArticles: SHProductProducerArticle[] = yield call(fetch);
  yield put(updateProductCacheAction({ producerArticles }));
}

/**
 * Creates the article for a producer. Producer gets created if non existent.
 *
 * @param a
 */
function* createProducerArticleSaga(a: CreateProducerReqAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const client = user!.client;

  const query = <T>(key: keyof T, value: string) =>
    getClientDocumentsQueryRef({ domain: SHDomain.ProductProducerCores, client })
      .where(key as string, "==", value)
      .get();

  // Fetch from DB to see what has to be created.
  const coreSnap: firestore.QuerySnapshot = yield call(() => query<SHProductProducerCore>("name", a.payload.name));
  const articleSnap: firestore.QuerySnapshot = yield call(() => query<SHProductProducerArticle>("name", a.payload.article));

  let producerCore: SHProductProducerCore;
  let producerArticle: SHProductProducerArticle;

  console.log("core empty", coreSnap.empty);
  console.log("article empty", articleSnap.empty);

  // Create, if necessary
  if (coreSnap.empty) {
    const data: SHProductProducerCore = { id: "", client, name: a.payload.name };
    producerCore = yield call(() => upsertEntity({ domain: SHDomain.ProductProducerCores, client, data }));
  } else {
    producerCore = coreSnap.docs[0].data() as SHProductProducerCore;
  }

  if (articleSnap.empty) {
    console.log("producerCore", producerCore);
    const { inputProducer: input }: WarehouseStore = yield select((s: SHStore) => s.warehouse);
    console.warn("input", input);
    const data = getEmptyProductProducerArticle({
      client,
      producerCoreRef: producerCore!.id,
      name: input.producerArticle,
      position: input.producerPosition,
      area: input.producerArea,
      articleNr: input.producerArticleNr,
    });
    console.warn("data", data);
    producerArticle = yield call(() =>
      upsertEntity<SHProductProducerArticle>({ domain: SHDomain.ProductProducerArticles, client, data })
    );
    console.warn("producerArticle", producerArticle);
  } else {
    producerArticle = articleSnap.docs[0].data() as SHProductProducerArticle;
  }

  // We're done here.
  yield put(updateProducerWarehouseAction({ producerCore, producerArticle }));
}

/**
 * Get an empty product core and store in redux. Upon creation (when the user
 * has finished all inputs), the entity + its ID get created.
 *
 * @param a
 */
function* initNewProductSaga(a: InitNewProductAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const { producerCore, producerArticle }: WarehouseStore = yield select((s: SHStore) => s.warehouse);

  const productCore = getEmptyProductCore({
    client: user!.client!,
    producerCoreRef: producerCore!.id,
    producerArticleRef: producerArticle!.id,
  });

  const draft: WarehouseStoreProductCoreDraft = { draftId: uuidv4(), count: 1, entity: productCore };

  yield put(
    updateWarehouseAction({
      drafts: [draft],
      selectedDraft: draft,
    })
  );
}

/**
 * Saga to upsert a product.
 * If no product is found in the payload we use the current edit product in the warehouse store
 *
 * @param a
 */
function* upsertProductReqSaga(a: ProductUpsertAction) {
  const { selectedProductCore, selectedDraft, drafts }: WarehouseStore = yield select((s: SHStore) => s.warehouse);
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const client = user!.client!

  // Update the existing product.
  if (selectedProductCore) {
    const upsert = () =>
      upsertEntityWithContinuousId<SHProductCore>({
        client,
        domain: SHDomain.ProductCores,
        data: selectedProductCore!,
      });
    const upserted: SHProductCore = yield call(upsert);
    yield put(productUpsertSucceededAction({ productCores: [upserted] }));
  }
  // Create, but sanitize drafts regarding serial-nr/count before using them.
  else if (selectedDraft && drafts.length > 0) {
    const all = getHotDraftBackIntoAllDrafts(drafts, selectedDraft!).map((d) => ({
      ...d,
      count: d.entity?.values?.["_serial-nr"] ? 1 : d.count,
    }));
    const upserts: SHProductCore[] = [];

    console.log("all", all);

    for (const singleDraft of all) {
      for (let i = 0; i < singleDraft.count; i++) {
        console.log("singleDraft", singleDraft);
        const upsert = () =>
          upsertEntityWithContinuousId<SHProductCore>({
            client,
            domain: SHDomain.ProductCores,
            data: singleDraft.entity,
          });
        const upserted: SHProductCore = yield call(upsert);
        upserts.push(upserted);

        console.log("upserted", upserted);
      }
    }

    yield put(productUpsertSucceededAction({ productCores: upserts }));
  }
}

/**
 *
 * @param a
 */
function* deleteProductCoreSaga(a: DeleteProductCoreAction) {
  const core: SHProductCore = yield call(() => getEntity<SHProductCore>({ domain: SHDomain.ProductCores, id: a.payload.coreId }));

  yield call(() => removeEntity({ domain: SHDomain.ProductCores, id: a.payload.coreId }));

  // Remove reservation, if needed.
  if (Boolean(core.reservation?.ref)) {
    const presale: SHPresale = yield call(() => getEntity<SHPresale>({ domain: SHDomain.PreSales, id: core.reservation!.ref }));
    yield call(() =>
      updateEntity<SHPresale>({
        domain: SHDomain.PreSales,
        id: core.reservation!.ref,
        data: {
          productCores: presale.productCores.filter((p) => p.productCoreRef !== core.id),
        },
      })
    );
  }

  const { products }: WarehouseStore = yield select((s: SHStore) => s.warehouse);
  yield put(
    updateWarehouseAction({
      products: products?.filter((p) => p.id !== a.payload.coreId),
      state: "productsList",
      isProducerFormCollapsed: false,
      selectedProductCore: undefined,
    })
  );
}

/**
 *
 * @param a
 */
function* uploadImagesFromInputSaga(a: UploadImagesFromInputAction) {
  const { event } = a.payload;
  const images: SHProductProducerArticleImageAsset[] = yield call(uploadImagesFromInputEffect, { event });
  const { producerCore, producerArticle, ...store }: WarehouseStore = yield select((s: SHStore) => s.warehouse);

  let producerMedia = store.producerMedia;
  console.log("images", images);

  // Create entity in DB if required or update the existing doc.
  if (!producerMedia) {
    const data: SHProductProducerMedia = {
      id: "",
      createdAt: Date.now(),
      client: producerCore!.client,
      images,
      producerArticleRef: producerArticle!.id,
    };
    producerMedia = yield call(() =>
      upsertEntity<SHProductProducerMedia>({ domain: SHDomain.ProductProducerMedia, client: producerCore!.client, data })
    );

    console.log("entity", producerMedia);
    yield put(updateWarehouseAction({ producerMedia }));
  } else {
    yield call(() =>
      updateEntity<SHProductProducerMedia>({
        id: producerMedia!.id,
        domain: SHDomain.ProductProducerMedia,
        data: {
          images: firestore.FieldValue.arrayUnion(...images),
        },
      })
    );
    yield put(updateProducerArticleImagesAction({ variant: "append", images }));
  }
}

/**
 *
 * @param a
 */
function* removeImageFromProducerArticleMediaSaga(a: RemoveImageFromProducerArticleMediaAction) {
  const { url, previewUrl } = a.payload.image;
  const { producerMedia }: WarehouseStore = yield select((s: SHStore) => s.warehouse);

  yield put(updateProgressAction({ isLoading: true }));
  try {
    yield call(removeStorageElementEffect, { url });
    if (previewUrl) yield call(removeStorageElementEffect, { url: previewUrl });
  } catch (e) {
    console.error(e);
  }
  yield put(updateProducerArticleImagesAction({ variant: "remove", images: [a.payload.image] }));

  yield call(() =>
    updateEntity<SHProductProducerMedia>({
      id: producerMedia!.id,
      domain: SHDomain.ProductProducerMedia,
      data: {
        images: firestore.FieldValue.arrayRemove(a.payload.image),
      },
    })
  );

  yield put(resetProgressAction());
}

/**
 *
 * @param a
 */
function* selectProductFromOtherContextSaga(a: SelectProductFromOtherContextAction) {
  yield put(updateProgressAction({ isLoading: true }));

  const core: SHProductCore = yield a.payload.core
    ? call(() => a.payload.core)
    : call(() => getEntity<SHProductCore>({ domain: SHDomain.ProductCores, id: a.payload.coreId! }));

  const producerCore: SHProductProducerCore = yield call(() =>
    getEntity({ domain: SHDomain.ProductProducerCores, id: core.producerCoreRef })
  );
  const producerArticle: SHProductProducerArticle = yield call(() =>
    getEntity({ domain: SHDomain.ProductProducerArticles, id: core.producerArticleRef })
  );
  const producerArticleMedia: SHProductProducerMedia | undefined = yield call(() =>
    getDocumentsQueryEntity<SHProductProducerMedia>({
      domain: SHDomain.ProductProducerMedia,
      key: "producerArticleRef",
      value: producerArticle.id,
      op: "==",
    })
  );

  yield put(
    updateWarehouseAction({
      producerCore,
      producerArticle,
      producerMedia: producerArticleMedia,
      selectedProductCore: core,
      isProductUpsertDialogOpen: true,
      isProducerFormCollapsed: true,
      inputProducer: {
        producerArticle: producerArticle.name,
        producerArea: producerArticle.area,
        producerArticleNr: producerArticle.articleNr,
        producerPosition: producerArticle.position,
        producerName: producerCore.name,
        countNewProducts: 1,
      },
      state: "productDetail",
    })
  );
}
