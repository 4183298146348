//
// hook.console.tsx
//
// Created by Thomas on 10.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { shallowEqual, useSelector } from "react-redux";
import { SHStore } from "../store/reducers";
import { ConsoleStoreAction, ConsoleStorePayload } from "../store/types/type.console";
import { getConnectedActionHook, getConnectedEmptyActionHook } from "../store/util/util.hook";

/*
 *
 * Functions.
 *
 */

export function useConnectedConsoleStore() {
  return useSelector((s: SHStore) => s.console, shallowEqual);
}

export const useConnectedConsoleAction = getConnectedActionHook<ConsoleStoreAction, ConsoleStorePayload>();
export const useConnectedConsoleEmptyAction = getConnectedEmptyActionHook<ConsoleStoreAction>();
