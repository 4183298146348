//
// util.hook.tsx
//
// Created by Thomas on 12.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {SHStore} from "../reducers";

/*
 *
 * Functions.
 *
 */

/**
 * Get a generic hook to access a given slice of the SHStore,
 * selected by the key provided.

 * @param key
 */
export function getConnectedStore<K extends keyof SHStore>(key: K) {
  return () => useSelector((s: SHStore) => s[key], shallowEqual);
}

/**
 * Get a generic hook-function to use with Action/Payload-types.
 * Removes overhead of manually writing out hooks for actions.
 */
export function getConnectedActionHook<A, P>() {
  return <Ax extends A, Px extends P>(action: (p: Px) => Ax) => {
    const dispatch = useDispatch();
    return useCallback((p: Px) => dispatch(action(p)), [dispatch, action]);
  };
}

/**
 * Get a generic hook-function to use with Actions only without
 * a payload.
 */
export function getConnectedEmptyActionHook<A>() {
  return <Ax extends A>(action: () => Ax) => {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(action()), [dispatch, action]);
  };
}
