//
// actions.user.js
// stockhouse
//
// Created by Thomas Schönmann on 20.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//

import {
  LOGIN_USER_FAILED,
  LOGIN_USER_REQ,
  LOGIN_USER_SUCCEEDED,
  LoginUserAction,
  LoginUserFailedAction,
  LoginUserFailedPayload,
  LoginUserPayload,
  LoginUserSucceededAction,
  LoginUserSucceededPayload,
  LOGOUT_USER,
  LogoutUserAction,
  UPDATE,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_STORE_VIEW,
  UpdateUserSettingsAction,
  UpdateUserSettingsPayload,
  UpdateUserStoreAction,
  UpdateUserStorePayload,
  UpdateUserStoreViewAction,
  UpdateUserStoreViewPayload,
} from "../types/type.user";

/*
 *
 * Actions.
 *
 */

export const updateUserAction = (payload: UpdateUserStorePayload): UpdateUserStoreAction => ({
  type: UPDATE,
  payload,
});

// User.

export const loginUserAction = (payload: LoginUserPayload): LoginUserAction => ({
  type: LOGIN_USER_REQ,
  payload,
});

export const loginUserSucceededAction = (payload: LoginUserSucceededPayload): LoginUserSucceededAction => ({
  type: LOGIN_USER_SUCCEEDED,
  payload,
});

export const loginUserFailedAction = (payload: LoginUserFailedPayload): LoginUserFailedAction => ({
  type: LOGIN_USER_FAILED,
  payload,
});

export const logoutUserAction = (): LogoutUserAction => ({
  type: LOGOUT_USER,
});

export const updateUserSettings = (payload: UpdateUserSettingsPayload): UpdateUserSettingsAction => ({
  type: UPDATE_USER_SETTINGS,
  payload,
});

export const updateUserStoreView = (payload: UpdateUserStoreViewPayload): UpdateUserStoreViewAction => ({
  type: UPDATE_USER_STORE_VIEW,
  payload,
});
