//
// type.aboutApp.tsx
//
// Created by Thomas on 12.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

export enum AppStoreKeys {
  // Schema: Mayor.Minor.Patch
  LastLocalVersion = "app-last-local-version"
}

export class AppError extends Error {
  tTitle: string;
  tDesc?: string;
  path?: string[];
  raw?: Error;

  constructor(data: { tTitle: string; tDesc?: string; path?: string[]; raw?: Error }) {
    super(data.tTitle);
    this.tTitle = data.tTitle;
    this.tDesc = data.tDesc;
    this.path = data.path;
    this.raw = data.raw;
  }
}
