//
// saga.trader.tsx
//
// Created by Thomas on 24.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_COMPANY_LOCATION_FROM_DRAFT,
  AddCompanyLocationFromDraftAction,
  CREATE_COMPANY,
  CreateCompanyAction,
  INIT_ADD_TRADER_CORE,
  INIT_TRADER_COMPANY_CACHE,
  INIT_TRADER_UPSERT_DIALOG,
  InitAddTraderCoreAction,
  InitTraderCompanyCacheAction,
  InitTraderUpsertDialogAction,
  OPEN_TRADER_DIALOG,
  OpenTraderDialogAction,
  REMOVE_SELECTED_TRADER_CORE,
  SELECT_COMPANY,
  SELECT_TRADER_CORE,
  SelectTraderCompanyAction,
  SelectTraderCoreAction,
  TraderStore,
  TraderStoreTraderCoreDraft,
  UPSERT_SELECTED_TRADER_CODE_REQ
} from "../types/type.trader";
import {
  getClientEntities,
  getClientSingleDocumentData,
  getDocumentsQueryEntities,
  getEntity,
  getReferencedEntities,
  removeEntity,
  updateEntity,
  upsertEntityWithContinuousId
} from "../../models/model.db";
import { SHStore } from "../reducers";
import { SHDomain, SHEntityRef } from "../../../../shared/src/models/types/type.db";
import { LOGIN_USER_SUCCEEDED, LoginUserSucceededAction, UserStore } from "../types/type.user";
import {
  updateTraderCacheActions,
  updateTraderInputAction,
  updateTraderStoreAction,
  upsertSelectedTraderCoreRejAction,
  upsertSelectedTraderCoreResAction
} from "../actions/action.trader";
import { SHTraderCompany, SHTraderCore } from "../../../../shared/src/models/types/type.trader";
import { resetProgressAction, updateProgressAction } from "../actions/action.progress";
import { getEmptyTraderCompany, getEmptyTraderCore } from "../../../../shared/src/models/model.trader";
import { firebase } from "../../models/model.firebase";
import { v4 as uuidv4 } from "uuid";
import { getHotDraftBackIntoAllDrafts } from "../util/util.mix.draft";
import { SHSchemaEntityInputSchema } from "../../../../shared/src/models/types/type.schema";
import {
  SHTraderCoreInputSchemaId,
  SHTraderCorePropertiesGroupVariant
} from "../../../../shared/src/models/types/type.trader.schema";
import { tryCatchSaga } from "../util/util.saga";

/*
 *
 * MARK: Watcher.
 *
 */

export default function* () {
  yield takeEvery(LOGIN_USER_SUCCEEDED, initTraderStoreAfterLoginSaga);
  yield takeEvery(INIT_TRADER_UPSERT_DIALOG, initTraderUpsertDialogSaga);
  yield takeEvery(SELECT_COMPANY, tryCatchSaga(selectCompanySaga, { withProgress: true }));
  yield takeEvery(SELECT_TRADER_CORE, tryCatchSaga(selectTraderCoreSaga, { withProgress: true }));
  yield takeEvery(CREATE_COMPANY, tryCatchSaga(createCompanySaga, { withProgress: true }));
  yield takeEvery(ADD_COMPANY_LOCATION_FROM_DRAFT, tryCatchSaga(addCompanyLocationFromDraftSaga, { withProgress: true }));
  yield takeEvery(INIT_ADD_TRADER_CORE, initAddTraderCoreSaga);
  yield takeEvery(UPSERT_SELECTED_TRADER_CODE_REQ, upsertSelectedTraderCoreSaga);
  yield takeEvery(REMOVE_SELECTED_TRADER_CORE, tryCatchSaga(removeSelectedTraderCoreSaga, { withProgress: true }));
  yield takeEvery(
    INIT_TRADER_COMPANY_CACHE,
    tryCatchSaga(initCompanyCacheIfNeededSaga, { withProgress: true, withStore: "trader" })
  );
  yield takeEvery(OPEN_TRADER_DIALOG, tryCatchSaga(openTraderDialogSaga, { withProgress: true, withStore: "trader" }));
}

/*
 *
 * MARK: Sagas.
 *
 */

/**
 *
 * @param a
 */
function* initTraderStoreAfterLoginSaga(a: LoginUserSucceededAction) {
  const { user } = a.payload;

  const traderCoreInputSchema: SHSchemaEntityInputSchema<
    SHTraderCorePropertiesGroupVariant,
    SHTraderCoreInputSchemaId
  > = yield call(getClientSingleDocumentData, {
    client: user.client,
    domain: SHDomain.TraderCoreInputSchemas,
  });

  yield put(updateTraderStoreAction({ traderCoreInputSchema }));
}

/**
 *
 * @param a
 */
function* initTraderUpsertDialogSaga(a: InitTraderUpsertDialogAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const companies: SHTraderCompany[] = yield call(() =>
    getClientEntities({ domain: SHDomain.TraderCompanies, client: user!.client })
  );

  yield put(updateTraderCacheActions({ companies }));
}

/**
 *
 * @param a
 */
function* selectCompanySaga(a: SelectTraderCompanyAction) {
  const { company } = a.payload;
  const traders: SHTraderCore[] = yield call(() =>
    getDocumentsQueryEntities<SHTraderCore>({ domain: SHDomain.TraderCores, key: "companyRef", op: "==", value: company.id })
  );

  yield put(updateTraderCacheActions({ traders }));
}

/**
 *
 * @param a
 */
function* createCompanySaga(a: CreateCompanyAction) {
  const { input, cache }: TraderStore = yield select((s: SHStore) => s.trader);
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const data = getEmptyTraderCompany({
    name: input.companyName,
    uid: input.companyUid,
    note: { title: "Note", body: input.companyNote },
    client: user!.client,
    locations: input.companyLocations,
    languages: [],
  });

  const upserted: SHTraderCompany = yield call(() =>
    upsertEntityWithContinuousId({ domain: SHDomain.TraderCompanies, client: user!.client, data })
  );

  const traders: SHTraderCore[] = yield call(() =>
    getDocumentsQueryEntities<SHTraderCore>({ domain: SHDomain.TraderCores, key: "companyRef", op: "==", value: upserted.id })
  );

  yield put(
    updateTraderStoreAction({
      state: "trader-cores-overview",
      selectedTraderCompany: upserted,
      cache: {
        ...cache,
        traders,
        companies: [...cache.companies, upserted],
      },
    })
  );
}

/**
 *
 * @param a
 */
function* addCompanyLocationFromDraftSaga(a: AddCompanyLocationFromDraftAction) {
  const { selectedTraderCompany, input, draft }: TraderStore = yield select((s: SHStore) => s.trader);

  if (!draft.companyLocation) {
    console.error("addCompanyLocationFromDraftSaga - no draft");
  }

  // Update existing company by appending the element.
  else if (selectedTraderCompany) {
    if (!Boolean(selectedTraderCompany.id)) {
      throw Error("!selectedTraderCompany.id");
    }

    yield call(() =>
      updateEntity<SHTraderCompany>({
        domain: SHDomain.TraderCompanies,
        id: selectedTraderCompany.id,
        data: {
          locations: firebase.firestore.FieldValue.arrayUnion(draft.companyLocation),
        },
      })
    );

    yield put(
      updateTraderStoreAction({
        isTraderCompanyAddLocationDrawerOpen: false,
        draft: { ...draft, companyLocation: undefined },
        selectedTraderCompany: {
          ...selectedTraderCompany,
          locations: [...selectedTraderCompany.locations, draft.companyLocation],
        },
      })
    );
  } else {
    yield put(updateTraderInputAction({ companyLocations: [...input.companyLocations, draft.companyLocation] }));
    yield put(
      updateTraderStoreAction({ isTraderCompanyAddLocationDrawerOpen: false, draft: { ...draft, companyLocation: undefined } })
    );
  }
}

/**
 *
 */
function* initAddTraderCoreSaga(a: InitAddTraderCoreAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const { selectedTraderCompany }: TraderStore = yield select((s: SHStore) => s.trader);

  const selectedTraderCore = getEmptyTraderCore({
    companyRef: selectedTraderCompany!.id,
    client: user!.client,
  });
  const selectedTraderCoreDraft: TraderStoreTraderCoreDraft = { draftId: uuidv4(), entity: selectedTraderCore };

  yield put(
    updateTraderStoreAction({
      state: "trader-core-detail",
      selectedTraderCoreDraft,
      traderCoreDrafts: [selectedTraderCoreDraft],
    })
  );
}

/**
 *
 */
function* upsertSelectedTraderCoreSaga() {
  try {
    yield put(updateProgressAction({ isLoading: true }));
    const client: SHEntityRef = yield select(({ user }) => user.user!.client);

    const { selectedTraderCore, selectedTraderCoreDraft, traderCoreDrafts }: TraderStore = yield select((s: SHStore) => s.trader);

    if (selectedTraderCore) {
      const upsert = () =>
        upsertEntityWithContinuousId<SHTraderCore>({
          client,
          domain: SHDomain.TraderCores,
          data: selectedTraderCore,
        });
      const upserted: SHTraderCore = yield call(upsert);
      yield put(upsertSelectedTraderCoreResAction({ cores: [upserted] }));
    } else if (selectedTraderCoreDraft && traderCoreDrafts.length > 0) {
      const all = getHotDraftBackIntoAllDrafts(traderCoreDrafts, selectedTraderCoreDraft!);
      const upserts: SHTraderCore[] = [];

      console.log("all trader cores", all);

      for (const draft of all) {
        const upsert = () =>
          upsertEntityWithContinuousId<SHTraderCore>({
            client,
            domain: SHDomain.TraderCores,
            data: draft.entity,
          });
        const upserted: SHTraderCore = yield call(upsert);
        upserts.push(upserted);
      }

      yield put(upsertSelectedTraderCoreResAction({ cores: upserts }));
    } else {
      console.error("No draft and/or drafts.length === 0");
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(resetProgressAction());
    yield put(upsertSelectedTraderCoreRejAction());
  }
}

/**
 *
 */
function* removeSelectedTraderCoreSaga() {
  const { selectedTraderCore, cache }: TraderStore = yield select((s: SHStore) => s.trader);

  if (selectedTraderCore) {
    yield call(() => removeEntity<SHTraderCore>({ domain: SHDomain.TraderCores, id: selectedTraderCore.id }));
    yield put(
      updateTraderStoreAction({
        selectedTraderCore: undefined,
        state: "trader-cores-overview",
        cache: { ...cache, traders: cache.traders.filter((t) => t.id !== selectedTraderCore.id) },
      })
    );
  }
}

/**
 *
 */
function* initCompanyCacheIfNeededSaga(a: InitTraderCompanyCacheAction, s?: TraderStore) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);

  const companies: SHTraderCompany[] = yield call(() =>
    getClientEntities<SHTraderCompany>({
      domain: SHDomain.TraderCompanies,
      client: user!.client,
    })
  );

  yield put(updateTraderStoreAction({ cache: { ...s!.cache, companies } }));
}

/**
 *
 * @param a
 * @param s
 */
function* openTraderDialogSaga(a: OpenTraderDialogAction, s?: TraderStore) {
  const { traderCore: core, company } = a.payload;
  const { input } = s!;

  if (company) {
    const traderCores: SHTraderCore[] = yield call(() =>
      getReferencedEntities<SHTraderCore>({ domain: SHDomain.TraderCores, refKey: "companyRef", refId: company.id })
    );

    yield put(
      updateTraderStoreAction({
        isTraderUpsertDialogOpen: true,
        selectedTraderCompany: company,
        state: "trader-cores-overview",
        input: {
          ...input,
          companyName: company.name,
          companyUid: company.uid || "",
          companyLocations: company.locations,
        },
      })
    );
    yield put(updateTraderCacheActions({ traders: traderCores }));
    return;
  }

  if (core) {
    const company: SHTraderCompany = yield call(() =>
      getEntity<SHTraderCompany>({ domain: SHDomain.TraderCompanies, id: core.companyRef })
    );
    const traderCores: SHTraderCore[] = yield call(() =>
      getReferencedEntities<SHTraderCore>({ domain: SHDomain.TraderCores, refKey: "companyRef", refId: core.companyRef })
    );

    yield put(
      updateTraderStoreAction({
        isTraderUpsertDialogOpen: true,
        selectedTraderCompany: company,
        state: "trader-core-detail",
        selectedTraderCore: core,
        input: {
          ...input,
          companyName: company.name,
          companyUid: company.uid || "",
          companyLocations: company.locations,
        },
      })
    );
    yield put(updateTraderCacheActions({ traders: traderCores }));
    return;
  }
}

/**
 *
 * @param a
 */
function* selectTraderCoreSaga(a: SelectTraderCoreAction) {
  const { core } = a.payload;
  const { selectedTraderCompany, input }: TraderStore = yield select((s: SHStore) => s.trader);

  if (!selectedTraderCompany || core.companyRef !== selectedTraderCompany.id) {
    const company: SHTraderCompany = yield call(() =>
      getEntity<SHTraderCompany>({ domain: SHDomain.TraderCompanies, id: core.companyRef })
    );
    const traderCores: SHTraderCore[] = yield call(() =>
      getReferencedEntities<SHTraderCore>({ domain: SHDomain.TraderCores, refKey: "companyRef", refId: core.companyRef })
    );

    yield put(
      updateTraderStoreAction({
        isTraderUpsertDialogOpen: true,
        selectedTraderCompany: company,
        input: {
          ...input,
          companyName: company.name,
          companyUid: company.uid || "",
          companyLocations: company.locations,
        },
      })
    );
    yield put(updateTraderCacheActions({ traders: traderCores }));
  }
}
