//
// db.d.ts
//
// Created by Thomas Schönmann on 14.07.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Enums.
 *
 */

export enum SHDomain {
  ProductCores = "product-cores",
  ContinuousIds = "continuous-ids",
  ProductProducerCores = "product-producer-cores",
  ProductProducerArticles = "product-producer-articles",
  ProductProducerMedia = "product-producer-media",
  ProductInputSchemas = "product-input-schemas",
  /**
   * Collection for the disposals, which each have
   * references to products, a company, etc.
   * */
  ProductDisposals = "product-disposals",
  /** Collection for disposed products. */
  ProductCoreDisposals = "product-core-disposals",
  PreSales = "pre-sales",
  Sales = "product-sales",
  TraderCores = "trader-cores",
  TraderCompanies = "trader-companies",
  TraderCoreInputSchemas = "trader-core-input-schemas",
  Users = "users",
  UserSettings = "user-settings",
  UserInvitations = "user-invitations",
  UserPermissions = "user-permissions",
  Suggestions = "suggestions",
  Clients = "clients",
  ClientSettings = "client-settings",
  Integrations = "integrations",
  SearchFilterLenses = "search-filter-lenses",
  NewProduct = "new-product",
  NewProductTypeCore = "new-product-type-core",
  NewProductArticles = "new-product-articles",
  NewProductProducer = "new-product-producer",
}

export enum SHIntegrationProvider {
  GraphQL = "graphql",
}

export enum SHSearchProvider {
  Firebase = "firebase",
}

/*
 *
 * Interfaces.
 *
 */

/**
 * Empty shape to declare something a stockhouse-entity.
 * Used for improved generics.
 */
export interface SHEntity {}

/**
 * Entity contains an ID.
 */
export interface Identifiable {
  id: string;
}

/**
 * Human-readable, incremental ID. Required by customer.
 * Only recommended to use together with 'Identifiable'.
 */
export interface Continuous {
  continuousId: number;
}

/**
 * Entity is created with a timestamp in UNIX-format.
 */
export interface Timeable {
  createdAt: number;
}

/**
 * Entity is directly associated to a client.
 */
export interface Clientable {
  client: string;
}

/**
 * Timestamp when the entity is expired.
 */
export interface Expireable {
  expires: number;
}

/**
 *
 */
export interface Historical {}

/**
 *
 */
export interface Assignable {
  assigneeRef: SHEntityRef;
}

/*
 *
 * SHEntity.
 *
 */

/**
 * Just an alias for string, but maye be need to modify
 * reference-types a later point, then everything
 * is easy to refactor.
 */
export type SHEntityRef = string;

export interface SearchableStringTuple {
  value: string;
  searchable: string;
}

/**
 * Special type to indicate that the string is only used
 * for translations. Helpful with generics.
 */
export type SHTranslationKeyAlias = string;

export interface SHCrudEntity extends SHEntity, Identifiable, Timeable, Clientable {}

export interface SHIntegration extends SHEntity, Identifiable, Timeable, Clientable {
  isActive: boolean;
  apiKey: string;
  provider: SHIntegrationProvider;
}

/**
 * TODO: Work out model which represents a generic history-entry (aka undo-entity).
 */
export interface SHHistory extends Identifiable, Clientable, Timeable {
  owner: SHEntityRef;
}
