//
// AppBarConsoleInput.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React from "react";
import { fade, Fade, IconButton, InputBase, Theme } from "@material-ui/core";
import { useConnectedConsoleAction, useConnectedConsoleStore } from "../../hooks/hook.console";
import { updateConsoleAction } from "../../store/actions/action.console";
import { Close as CloseIcon, ConsoleLine as ConsoleLineIcon } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import { I18Namespace } from "../../i18n/i18n";
import { createStyles, makeStyles } from "@material-ui/styles";

/*
 *
 * Components.
 *
 */

/**
 *
 * @constructor
 */
export default function ConsoleInputField() {
  const classes = useStyles();
  //const [input, setInput] = useState("");
  const { input, isVisible } = useConnectedConsoleStore();
  const updateConsole = useConnectedConsoleAction(updateConsoleAction);
  //const clearConsoleInput = useConnectedConsoleEmptyAction(clearConsoleInputAction)
  const { t: tAct } = useTranslation(I18Namespace.Activity);

  return (
    <div className={classes.search}>
      <InputBase
        value={input}
        placeholder={tAct("Find")}
        classes={{
          root: classes.base,
          input: classes.input,
        }}
        inputProps={{ "aria-label": "search" }}
        startAdornment={
          <Fade in={!isVisible}>
            <ConsoleLineIcon fontSize="small" style={{ marginLeft: 8 }} />
          </Fade>
        }
        endAdornment={
          <Fade in={isVisible}>
            <IconButton
              size="small"
              style={{ marginRight: 4 }}
              onClick={() => {
                updateConsole({ isVisible: false, input: "" });
              }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fade>
        }
        onChange={(e) => updateConsole({ input: e.target.value })}
        onFocus={() => updateConsole({ isVisible: true })}
        onBlur={() => {
          updateConsole({ isVisible: false, input: "" });
        }}
      />
    </div>
  );
}

/*
 *
 * MARK: Styles.
 *
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flex: 1,
      borderRadius: theme.shape.borderRadius,
      border: theme.palette.type === "light" ? "solid" : undefined,
      borderWidth: theme.palette.type === "light" ? 2 : undefined,
      borderColor: theme.palette.type === "light" ? theme.palette.grey.A100 : undefined,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      //marginLeft: 0,
      //width: "100%",
      //width: "auto",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        //width: "auto",
      },
    },
    icon: {
      fontSize: theme.spacing(2),
    },
    base: {
      color: "inherit",
      width: "100%",
    },
    input: {
      padding: theme.spacing(1, 1, 1, 1),
      textAlign: "center",
    },
  })
);
