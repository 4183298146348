//
// RouteAssistant.tsx
// stockhouse
//
// Created by Thomas on 06.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { memo, PropsWithChildren, useMemo } from "react";
import { useCurrentRouteMetaData } from "../../hooks/hook.route";
import { useLeftSectionStyles } from "./styles";
import {
  ButtonBase,
  Divider,
  Fade,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { ArrowTopRight as ArrowTopRightIcon } from "mdi-material-ui";
import { usePopper } from "../../hooks/hook.layout";
import { getNextLazyRouteRecommendations } from "../../models/model.route";
import { useHistory } from "react-router";
import { LazyRouteComponent, SHRoute } from "../../models/types/type.route";

/*
 *
 * Interfaces.
 *
 */

interface RouteAssistantProps {}

/*
 *
 * Components.
 *
 */

export default memo<RouteAssistantProps>(() => {
  const routeMetaData = useCurrentRouteMetaData();
  const classes = useLeftSectionStyles();
  const { anchorEl, isPopperOpen, placement, handleClick, close } = usePopper();

  return (
    <Hidden xsDown>
      <div onMouseEnter={handleClick} onMouseLeave={close}>
        {routeMetaData && (
          <ButtonBase className={classes.currentRouteButtonBase} onClick={handleClick}>
            <div className={classes.currentRouteContainer}>
              <Typography className={classes.currentRoutePrimary}>{routeMetaData.primary}</Typography>
              {routeMetaData.secondary && (
                <Typography className={classes.currentRouteSecondary}>{routeMetaData.secondary?.toUpperCase()}</Typography>
              )}
            </div>
          </ButtonBase>
        )}
        <Popper open={isPopperOpen} anchorEl={anchorEl} placement={placement} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <PopperContent route={routeMetaData?.route} close={close} />
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Hidden>
  );
});

const PopperContent = (props: { route?: SHRoute<LazyRouteComponent>; close: () => void }) => {
  const { route, close } = props;
  const nodes = useMemo(() => (route ? getNextLazyRouteRecommendations(route.path) : []), [route]);

  return (
    <PopperList title={"Recommended"} close={close}>
      {(nodes.length > 0 && nodes.map((route, i) => <RouteLinkListItem key={i} route={route} onClick={close} />)) || (
        <ListItem>
          <ListItemText primary="No recommendation" />
        </ListItem>
      )}
    </PopperList>
  );
};

const PopperList = (props: PropsWithChildren<{ title: string; close: () => void }>) => {
  const { title, children, close } = props;
  const titleId = useMemo(() => `nested-${title}-list-subheader`, [title]);

  return children ? (
    <List
      dense
      style={{ maxWidth: 300, maxHeight: 500 }}
      component="nav"
      aria-labelledby={titleId}
      subheader={
        <ListSubheader disableSticky component="div" id={titleId}>
          {title}
        </ListSubheader>
      }>
      <Divider />
      {children}
    </List>
  ) : null;
};

const RouteLinkListItem = memo<{ route: SHRoute<LazyRouteComponent>; onClick?: () => void }>((props) => {
  const { route, onClick } = props;
  const history = useHistory();

  return (
    <ListItem
      button
      onClick={() => {
        if (onClick) onClick();
        history.push(route.path);
      }}>
      <ListItemIcon>{route.visual?.Icon ? <route.visual.Icon /> : <ArrowTopRightIcon />}</ListItemIcon>
      <ListItemText primary={route.visual?.label} secondary={route.group?.toUpperCase()} />
    </ListItem>
  );
});
