//
// type.aboutApp.tsx
//
// Created by Thomas on 16.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

export const UPDATE = "app/update";
export const SET_LAST_LOCAL_VERSION = "app/set-last-local-version";
export const BUMP_TO_LATEST_VERSION = "app/bump-to-latest-version";

export const UPDATE_APP_VISIBILITY = "app/update/visibility";

/*
 *
 * Store.
 *
 */

export interface AppStore {
  lastLocalVersion?: string;
  isLocalVersionDeprecated?: boolean;
  isAppVersionNew?: boolean;
  isAppVisible?: boolean;
}

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateAppAction {
  type: typeof UPDATE;
  payload: UpdateAppPayload;
}

export type UpdateAppPayload = Partial<AppStore>;

export interface SetLastLocalVersionAction {
  type: typeof SET_LAST_LOCAL_VERSION;
  payload: SetLastLocalVersionPayload;
}

export interface SetLastLocalVersionPayload {
  lastLocalVersion?: string;
}

export interface BumpToLatestVersionAction {
  type: typeof BUMP_TO_LATEST_VERSION;
}

export interface UpdateAppVisibilityAction {
  type: typeof UPDATE_APP_VISIBILITY;
  payload: UpdateAppVisibilityPayload;
}

export type UpdateAppVisibilityPayload = Required<Pick<AppStore, "isAppVisible">>;

/*
 *
 * Union action type.
 *
 */

export type AppStoreAction = UpdateAppAction | SetLastLocalVersionAction | BumpToLatestVersionAction | UpdateAppVisibilityAction;

export type AppStorePayload = UpdateAppPayload | SetLastLocalVersionPayload | UpdateAppVisibilityPayload;
