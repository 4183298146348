//
// type.trader.tsx
//
// Created by Thomas on 24.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  SHTraderCompany,
  SHTraderCompanyLocation,
  SHTraderCore
} from "../../../../shared/src/models/types/type.trader";
import { DraftEntity } from "../util/util.mix.draft";
import { SHSchemaEntityInputSchema, SHSchemaEntityValues } from "../../../../shared/src/models/types/type.schema";
import {
  SHTraderCoreInputSchemaId,
  SHTraderCorePropertiesGroupVariant
} from "../../../../shared/src/models/types/type.trader.schema";

/*
 *
 * MARK: Action types.
 *
 */

export const UPDATE_TRADER_STORE = "trader/update";
export const UPDATE_TRADER_CACHE = "trader/update/cache";
export const UPDATE_TRADER_INPUT = "trader/update/input";
export const UPDATE_TRADER_DRAFT = "trader/update/draft";
export const UPDATE_TRADER_COMPANY_LOCATION_DRAFT = "trader/update/draft/company-location";
export const UPDATE_SELECTED_TRADER_CORE = "trader/update/selected-trader-core";
export const UPDATE_SELECTED_TRADER_CORE_VALUES = "trader/update/selected-trader-core-values";
export const UPDATE_SELECTED_TRADER_CORE_DRAFT_VALUES = "trader/update/selected-trader-core-draft-values";

export const CREATE_COMPANY = "trader/create/company";

export const INIT_TRADER_UPSERT_DIALOG = "trader/upsert-dialog/init";
export const CLOSE_TRADER_UPSERT_DIALOG = "trader/upsert-dialog/close";

export const SWITCH_TRADER_COMPANY_OPEN = "trader/company/switch-open";

export const SELECT_COMPANY = "trader/select/company";
export const SELECT_TRADER_CORE = "trader/select/trader-core";
export const UNSELECT_TRADER_CORE = "trader/unselect/trader-core";

export const INIT_ADD_COMPANY_LOCATION_FROM_DRAFT = "trader/init/add-company-location-from-draft";
export const ADD_COMPANY_LOCATION_FROM_DRAFT = "trader/add/company-location-from-draft";

export const INIT_ADD_TRADER_CORE = "trader/init/add-trader-core";

export const UPSERT_SELECTED_TRADER_CODE_REQ = "trader/upsert/selected-trader-core/req";
export const UPSERT_SELECTED_TRADER_CODE_RES = "trader/upsert/selected-trader-core/res";
export const UPSERT_SELECTED_TRADER_CODE_REJ = "trader/upsert/selected-trader-core/rej";

export const REMOVE_SELECTED_TRADER_CORE = "trader/remove/selected-trader-core";

export const INIT_TRADER_COMPANY_CACHE = "trader/init/cache/trader-company";

export const OPEN_TRADER_DIALOG = "trader/open/dialog";

/*
 *
 * MARK: Store.
 *
 */

export interface TraderStore {
  state: TraderStoreState;

  traderCoreInputSchema?: SHSchemaEntityInputSchema<SHTraderCorePropertiesGroupVariant, SHTraderCoreInputSchemaId>;

  /** The currently visible input. Single special entities, such as locations, are in 'draft'. */
  input: {
    companyName: string;
    companyUid: string;
    companyLanguage: string;
    companyNote: string;
    companyLocations: SHTraderCompanyLocation[];
  };

  draft: { companyLocation?: SHTraderCompanyLocation };
  traderCoreDrafts: TraderStoreTraderCoreDraft[];
  selectedTraderCoreDraft?: TraderStoreTraderCoreDraft;

  /** Existing entities. */
  selectedTraderCompany?: SHTraderCompany;
  selectedTraderCore?: SHTraderCore;

  /** Suggestions. */
  cache: {
    companies: SHTraderCompany[];
    traders: SHTraderCore[];
  };

  /** View flags. */
  traderCompanySubviewIndex: number;
  selectedTabIndex: TraderStoreTabIndex;
  isTraderUpsertDialogOpen: boolean;
  isTraderCompanyViewOpen: boolean;
  isTraderCompanyAddLocationDrawerOpen?: boolean;
}

export type TraderStoreState = "select-company" | "trader-cores-overview" | "trader-core-detail";

export enum TraderStoreTabIndex {
  Core,
  LinkedSales,
}

export type TraderStoreTraderCoreDraft = DraftEntity<SHTraderCore>;

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateTraderStoreAction {
  type: typeof UPDATE_TRADER_STORE;
  payload: UpdateTraderStorePayload;
}

export type UpdateTraderStorePayload = Partial<TraderStore>;

export interface InitTraderUpsertDialogAction {
  type: typeof INIT_TRADER_UPSERT_DIALOG;
  payload: InitTraderUpsertDialogProps;
}

export type InitTraderUpsertDialogProps = Partial<Omit<TraderStore, "isTraderUpsertDialogOpen">>;

export interface CloseTraderUpsertDialogAction {
  type: typeof CLOSE_TRADER_UPSERT_DIALOG;
}

export interface SwitchTraderCompanyOpenAction {
  type: typeof SWITCH_TRADER_COMPANY_OPEN;
}

export interface UpdateTraderCacheAction {
  type: typeof UPDATE_TRADER_CACHE;
  payload: UpdateTraderCachePayload;
}

export type UpdateTraderCachePayload = Partial<TraderStore["cache"]>;

export interface UpdateTraderInputAction {
  type: typeof UPDATE_TRADER_INPUT;
  payload: UpdateTraderInputPayload;
}

export type UpdateTraderInputPayload = Partial<TraderStore["input"]>;

export interface SelectTraderCompanyAction {
  type: typeof SELECT_COMPANY;
  payload: SelectTraderCompanyPayload;
}

export interface SelectTraderCompanyPayload {
  company: SHTraderCompany;
}

export interface CreateCompanyAction {
  type: typeof CREATE_COMPANY;
}

export interface AddCompanyLocationFromDraftAction {
  type: typeof ADD_COMPANY_LOCATION_FROM_DRAFT;
}

export interface UpdateTraderStoreDraftAction {
  type: typeof UPDATE_TRADER_DRAFT;
  payload: UpdateTraderStoreDraftPayload;
}

export type UpdateTraderStoreDraftPayload = Partial<TraderStore["draft"]>;

export interface UpdateTraderStoreCompanyLocationDraftAction {
  type: typeof UPDATE_TRADER_COMPANY_LOCATION_DRAFT;
  payload: UpdateTraderStoreCompanyLocationDraftPayload;
}

export type UpdateTraderStoreCompanyLocationDraftPayload = Partial<SHTraderCompanyLocation>;

export interface InitAddCompanyLocationFromDraftAction {
  type: typeof INIT_ADD_COMPANY_LOCATION_FROM_DRAFT;
}

export interface InitAddTraderCoreAction {
  type: typeof INIT_ADD_TRADER_CORE;
}

export interface UnselectTraderCoreAction {
  type: typeof UNSELECT_TRADER_CORE;
}

export interface UpdateSelectedTraderCoreAction {
  type: typeof UPDATE_SELECTED_TRADER_CORE;
  payload: UpdateSelectedTraderCorePayload;
}

export type UpdateSelectedTraderCorePayload = Partial<SHTraderCore>;

export interface UpdateSelectedTraderCoreDraftValuesAction {
  type: typeof UPDATE_SELECTED_TRADER_CORE_DRAFT_VALUES;
  payload: UpdateSelectedTraderCoreDraftValuesPayload;
}

export type UpdateSelectedTraderCoreDraftValuesPayload = Partial<SHSchemaEntityValues<SHTraderCoreInputSchemaId>>;

export interface UpsertSelectedTraderCoreAction {
  type: typeof UPSERT_SELECTED_TRADER_CODE_REQ;
}

export interface UpsertSelectedTraderCoreResAction {
  type: typeof UPSERT_SELECTED_TRADER_CODE_RES;
  payload: UpsertSelectedTraderCoreResPayload;
}

export interface UpsertSelectedTraderCoreResPayload {
  cores: SHTraderCore[];
}

export interface UpsertSelectedTraderCoreRejAction {
  type: typeof UPSERT_SELECTED_TRADER_CODE_REJ;
}

export interface UpdateSelectedTraderCoreValuesAction {
  type: typeof UPDATE_SELECTED_TRADER_CORE_VALUES;
  payload: UpdateSelectedTraderCoreValuesPayload;
}

export type UpdateSelectedTraderCoreValuesPayload = SHSchemaEntityValues<SHTraderCoreInputSchemaId>;

export interface RemoveSelectedTraderCoreAction {
  type: typeof REMOVE_SELECTED_TRADER_CORE;
}

export interface InitTraderCompanyCacheAction {
  type: typeof INIT_TRADER_COMPANY_CACHE;
}

export interface OpenTraderDialogAction {
  type: typeof OPEN_TRADER_DIALOG;
  payload: OpenTraderDialogPayload;
}

export interface OpenTraderDialogPayload {
  company?: SHTraderCompany;
  traderCore?: SHTraderCore;
}

export interface SelectTraderCoreAction {
  type: typeof SELECT_TRADER_CORE;
  payload: SelectTraderCorePayload;
}

export interface SelectTraderCorePayload extends Partial<Pick<TraderStore, "isTraderUpsertDialogOpen">> {
  core: SHTraderCore;
}

/*
 *
 * MARK: Union types.
 *
 */

export type TraderStoreAction =
  | UpdateTraderStoreAction
  | UpdateTraderCacheAction
  | InitTraderUpsertDialogAction
  | CloseTraderUpsertDialogAction
  | SwitchTraderCompanyOpenAction
  | UpdateTraderInputAction
  | SelectTraderCompanyAction
  | CreateCompanyAction
  | AddCompanyLocationFromDraftAction
  | UpdateTraderStoreDraftAction
  | UpdateTraderStoreCompanyLocationDraftAction
  | InitAddCompanyLocationFromDraftAction
  | InitAddTraderCoreAction
  | UnselectTraderCoreAction
  | UpdateSelectedTraderCoreAction
  | UpdateSelectedTraderCoreDraftValuesAction
  | UpsertSelectedTraderCoreAction
  | UpsertSelectedTraderCoreResAction
  | UpsertSelectedTraderCoreRejAction
  | UpdateSelectedTraderCoreValuesAction
  | RemoveSelectedTraderCoreAction
  | InitTraderCompanyCacheAction
  | OpenTraderDialogAction
  | SelectTraderCoreAction;

export type TraderStorePayload =
  | UpdateTraderStorePayload
  | UpdateTraderCachePayload
  | InitTraderUpsertDialogProps
  | UpdateTraderInputPayload
  | SelectTraderCompanyPayload
  | UpdateTraderStoreDraftPayload
  | UpdateTraderStoreCompanyLocationDraftPayload
  | UpdateSelectedTraderCorePayload
  | UpdateSelectedTraderCoreDraftValuesPayload
  | UpsertSelectedTraderCoreResPayload
  | UpdateSelectedTraderCoreValuesPayload
  | OpenTraderDialogPayload
  | SelectTraderCorePayload;
