//
// model.disposal.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHProductCore, SHProductDisposal, SHProductDisposalResolution } from "../../../shared/src/models/types/type.product";
import { getEntity } from "./model.db";
import { SHDomain } from "../../../shared/src/models/types/type.db";
import { SHTraderCompany, SHTraderCore } from "../../../shared/src/models/types/type.trader";
import { SHUser } from "../../../shared/src/models/types/type.user";

/*
 *
 * Functions.
 *
 */

/**
 * Load all refs, if possible.
 *
 * @param source
 */
export async function transformToDisposalResolution(source: SHProductDisposal): Promise<SHProductDisposalResolution> {
  const res: SHProductDisposalResolution = {
    ...source,
    productCores: await Promise.all(
      source.productCoreRefs.map((ref) => getEntity<SHProductCore>({ domain: SHDomain.ProductCores, id: ref }))
    ).then((res) =>
      res.map((r) => {
        if (r === undefined) throw Error("transformToDisposalResolution - undefined product core res");
        return r;
      })
    ),
  };

  if (source.traderCompanyRef) {
    res.traderCompany = await getEntity<SHTraderCompany>({ domain: SHDomain.TraderCompanies, id: source.traderCompanyRef });
  }
  if (source.traderCoreRef) {
    res.traderCore = await getEntity<SHTraderCore>({ domain: SHDomain.TraderCores, id: source.traderCoreRef });
  }
  if (source.assigneeRef) {
    res.assignee = await getEntity<SHUser>({ domain: SHDomain.Users, id: source.assigneeRef });
  }

  return res;
}

/**
 *
 * @param source
 */
export function revertFromDisposalResolution(source: SHProductDisposalResolution): SHProductDisposal {
  return {
    id: source.id,
    continuousId: source.continuousId,
    client: source.client,
    createdAt: source.createdAt,
    traderCoreRef: source.traderCoreRef,
    traderCompanyRef: source.traderCompanyRef,
    productCoreRefs: source.productCoreRefs,
    documents: source.documents,
    state: source.state,
    notes: source.notes,
    assigneeRef: source.assigneeRef,
    traderLocation: source.traderLocation,
  };
}
