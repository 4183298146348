//
// InitLoading.tsx
// stockhouse
//
// Created by Thomas Schönmann on 16.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useConnectedUserStore } from "../../hooks/hook.user";
import { getIsBrowserSupported } from "../../models/model.app";
import { SHUrlPath } from "../../models/types/type.route";
import FullscreenLoader from "./FullscreenLoader";

/*
 *
 * Components.
 *
 */

export default memo(() => {
  const authStore = useConnectedUserStore();
  const history = useHistory();

  useEffect(() => {
    if (!getIsBrowserSupported()) {
      history.replace(SHUrlPath.Error, { reason: "unsupported-browser" });
    }
    // Either redirect to the path a user has entered before the login-check completed
    // or go to events overview as fallback.
    else if (authStore.state === "loggedIn") {
      // @ts-ignore 'from' in location's state not defined.
      history.replace(history.location.state ? history.location.state?.from : SHUrlPath.Dashboard);
    } else if (authStore.state === "loggedOut") {
      history.replace(SHUrlPath.SignIn);
    }
  }, [authStore, history]);

  return <FullscreenLoader label="stockhou.se" />;
});
