import {
  CHANGE_ORDER,
  ManageUsersAction,
  ManageUsersStore,
  USER_MANAGEMENT_CHANGE_USER_SUCCEEDED,
  USER_MANAGEMENT_CLEANUP_USER,
  USER_MANAGEMENT_PERSONAL_INFO
} from "../types/type.manageUsers";
import { SHUserOrderBy } from "../../../../shared/src/models/types/type.user";

const store: ManageUsersStore = {
  orderBy: SHUserOrderBy.Name,
  order: "desc",
  isLoading: false,
  isManageUserDialogOpen: false
};

export default function(s = store, a: ManageUsersAction): ManageUsersStore {
  switch (a.type) {
    case CHANGE_ORDER:
      const { type, payload } = a;
      const { orderBy } = payload;
      if (orderBy === s.orderBy) {
        return { ...s, orderBy: orderBy, order: s.order === "asc" ? "desc" : "asc" };
      } else {
        return { ...s, orderBy: orderBy, order: "desc" };
      }
    case USER_MANAGEMENT_CHANGE_USER_SUCCEEDED:
      return { ...s, isLoading: a.payload.isLoading, user: a.payload.user, isManageUserDialogOpen: true };
    case USER_MANAGEMENT_CLEANUP_USER:
      return {
        ...s,
        isLoading: false,
        user: undefined,
        uid: undefined,
        isManageUserDialogOpen: false,
        personalInfo: { firstName: "", lastName: "", email: "", hasUpdates: false }
      };
    case USER_MANAGEMENT_PERSONAL_INFO:
      return {
        ...s,
        personalInfo: {
          firstName: a.payload.firstName,
          lastName: a.payload.lastName,
          email: a.payload.email,
          hasUpdates: a.payload.hasUpdates
        }
      };
    default:
      return s;
  }
}
