//
// index.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//
// Aut viam inveniam aut faciam!
//

import { createElement } from "react";
import ReactDOM from "react-dom";
import App from "./views/App/AppInit";
import * as serviceWorker from "./utils/serviceWorker";
import { disableLoggingIfNeeded } from "./models/model.app";
import "./index.css";
import "./i18n/i18n";

disableLoggingIfNeeded();

/*
 *
 * Functions.
 *
 */

interface Module {
  hot?: {
    accept: (path: string, render: () => void) => void;
  };
}

/*
 *
 * Functions.
 *
 */

function render() {
  ReactDOM.render(createElement(App), document.getElementById("root"));
}

render();
(module as Module)?.hot?.accept("./views/App/App", render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
