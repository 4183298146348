//
// model.customer.tsx
//
// Created by Thomas on 24.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { getEmptyEntity } from "./model.db";
import { SHTraderCompany, SHTraderCompanyLocation, SHTraderCore } from "./types/type.trader";
import { v4 as uuidv4 } from "uuid";

/*
 *
 * Interfaces.
 *
 */

/**
 *
 */
export const getEmptyTraderCore = getEmptyEntity<SHTraderCore>({
  id: "",
  continuousId: -1,
  createdAt: Date.now(),
  client: "",
  companyRef: "",
  values: {},
});

/**
 *
 */
export const getEmptyTraderCompany = getEmptyEntity<SHTraderCompany>({
  id: "",
  continuousId: -1,
  client: "",
  createdAt: Date.now(),
  name: "",
  locations: [],
  roles: [],
});

/**
 *
 */
export const getEmptyTraderLocation = getEmptyEntity<SHTraderCompanyLocation>({
  id: uuidv4(),
  street: "",
  postalCode: "",
  place: "",
  countryCode: "",
});
