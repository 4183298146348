//
// model.firebase.tsx
//
// Created by Thomas on 15.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import * as fire from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { auth as uiAuth } from "firebaseui";
import { configs } from "../configs";

/*
 *
 * Attributes.
 *
 */

fire.initializeApp(configs.firebase!);

export const firebase = fire;
export const auth = fire.auth();
export const firestore = fire.firestore();
export const storage = fire.storage();
export const functions = fire.app().functions("europe-west1");

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param props
 */
export function getFirebaseUiConfig(props: {
  onSuccess: (res: any) => void;
  onError: (error: uiAuth.AuthUIError) => Promise<void>;
}): uiAuth.Config {
  const { onSuccess, onError } = props;

  return {
    signInFlow: "popup",
    signInOptions: [
      { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: "select_account",
        },
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (result) => {
        onSuccess(result);
        return false;
      },
      signInFailure: onError,
    },
  };
}
