//
// AppBar.styles.js
// stockhouse
//
// Created by Thomas Schönmann on 02.10.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export const useAppBarStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    bar: {
      backgroundColor: palette.primary.main,
      paddingTop: `env(safe-area-inset-top)`,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
    },
    barError: {
      backgroundColor: palette.error.main,
    },
  })
);

export const useLeftSectionStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    spacer: {
      [breakpoints.down("sm")]: { width: spacing(2) },
      [breakpoints.up("md")]: { width: spacing(2) },
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingLeft: spacing(3),
      [breakpoints.down("sm")]: {
        paddingLeft: spacing(1),
        height: 40,
      },
      [breakpoints.up("md")]: {
        height: 40,
      },
      [breakpoints.up("lg")]: {
        height: 60,
      },
      [breakpoints.up("xl")]: {
        height: 70,
      },
    },
    logo: {
      [breakpoints.down("sm")]: {
        height: 45,
      },
      [breakpoints.up("md")]: {
        height: 55,
      },
      [breakpoints.up("lg")]: {
        height: 70,
      },
      [breakpoints.up("xl")]: {
        height: 80,
      },
    },
    currentRouteButtonBase: {
      marginLeft: spacing(3),
      padding: spacing(2),
      borderRadius: 2,
      textAlign: "left",
    },
    currentRouteContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    currentRoutePrimary: {
      [breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [breakpoints.up("md")]: {
        fontSize: 15,
      },
    },
    currentRouteSecondary: {
      fontSize: 11,
      fontWeight: "bold",
      opacity: 0.7,
    },
  })
);

export const useCenterSectionStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 4,
      [breakpoints.up("sm")]: {
        flex: 3,
      },
      [breakpoints.up("md")]: {
        flex: 1.5,
      },
    },
  })
);

export const useRightSectionStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    menuButton: {
      color: "#ADB5C6",
    },
    spacer: {
      [breakpoints.down("sm")]: { width: spacing(2) },
      [breakpoints.up("md")]: { width: spacing(2) },
    },
    offlineAvatar: {
      marginRight: spacing(1),
      color: "#fff",
      backgroundColor: red["A200"],
    },
  })
);
