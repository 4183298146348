//
// effect.aboutApp.js
// stockhouse
//
// Created by Thomas Schönmann on 12.06.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Effects for shared usage to retrieve information about
// the current app-instance.
//

import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {
  bumpToLatestVersion as bumpToLatestVersionAction,
  updateAppAction,
  updateAppVisibilityAction,
} from "../store/actions/action.app";
import {getLatestAppVersion, getLocalAppVersion, updateLocalAppVersionToLatestEffect} from "../models/model.app";
import {useConnectedUserStore} from "./hook.user";
import {getConnectedActionHook, getConnectedEmptyActionHook, getConnectedStore} from "../store/util/util.hook";
import {AppStoreAction, AppStorePayload} from "../store/types/type.app";
import {useTranslation} from "react-i18next";
import {I18Namespace} from "../i18n/i18n";
import {AppVersionMetadataTuple} from "../../../shared/src/models/types/type.config";

// @ts-ignore
import useVisibilityChange from "use-visibility-change";
import {isDev} from "../utils/util.dev";

/*

 *
 * Functions.
 *
 */

export const useConnectedAppStore = getConnectedStore("app");

export const useConnectedAppAction = getConnectedActionHook<AppStoreAction, AppStorePayload>();

export const useConnectedAppEmptyAction = getConnectedEmptyActionHook<AppStoreAction>();

/**
 *
 */
export function useAboutAppInfo() {
  const dispatch = useDispatch();
  const [latestVersion] = useState(getLatestAppVersion());
  const latestVersionPrettified = latestVersion.split("_").join(".");

  const [isWebWorkerSupported] = useState("Worker" in window);
  const [isNotificationSupported] = useState("Notification" in window);
  const [isServiceWorkerSupported] = useState(navigator && "serviceWorker" in navigator);
  const [isPushSupported] = useState(isServiceWorkerSupported && "PushManager" in window);

  const bumpToLatestVersion = useCallback(() => dispatch(bumpToLatestVersionAction()), [dispatch]);

  return {
    latestVersion,
    latestVersionPrettified,
    bumpToLatestVersion,
    isWebWorkerSupported,
    isServiceWorkerSupported,
    isPushSupported,
    isNotificationSupported,
  };
}

/**
 *
 */
export function useConnectedGdprNotification() {
  const { user } = useConnectedUserStore();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    localStorage.setItem("isGdrpInfoAck", "true");
  }, [setIsOpen]);

  useEffect(() => {
    setIsOpen(Boolean(user) && localStorage.getItem("isGdrpInfoAck") !== "true");
  }, [setIsOpen, user]);

  return { isOpen, onClose };
}

/**
 *
 */
export function useNetworkDetection() {
  const [isSupported] = useState(Boolean(navigator));
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    if (!isSupported) return () => {};

    const onOnline = () => setIsOnline(true);
    const onOffline = () => setIsOnline(false);

    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, [setIsOnline]);

  return { isSupported, isOnline };
}

/**
 *
 */
export function useChannelToServiceWorker() {
  const [imageUpdates, setImageUpdates] = useState(null);

  useEffect(() => {
    if (!("serviceWorker" in window)) return () => {};

    const channel = new BroadcastChannel("sw-images-updates");

    const onMessage = ({ update }: { update: any }) => async (event: any) => {
      update(event.data);

      //const { cacheName, updatedUrl } = event.data.payload;

      // Do something with cacheName and updatedUrl.
      // For example, get the cached content and updateWarehouseAction
      // the content on the page.
      //const cache = await caches.open(cacheName);
      //const updatedResponse = await cache.match(updatedUrl);
      //const updatedText = await updatedResponse.text();
      //event.ports[0].postMessage("Client 1 Says 'Hello back!'");
    };

    channel.addEventListener("message", onMessage({ update: setImageUpdates }));

    // @ts-ignore
    navigator.serviceWorker.addEventListener("message", onMessage);
    return () => {
      // @ts-ignore
      navigator.serviceWorker.removeEventListener(onMessage);
    };
  }, []);

  return { imageUpdates };
}

/**
 *
 */
export function useConnectedAppVersionInfo() {
  const updateAppStore = useConnectedAppAction(updateAppAction);
  const [latestVersion] = useState(getLatestAppVersion());
  const [localVersion, setLocalVersion] = useState(getLocalAppVersion());

  // The local version is only deprecated if it exists, thus no warning
  // shows if the user visits the site for the first time.
  const isLocalVersionDeprecated = useMemo(() => Boolean(localVersion) && latestVersion !== localVersion, [
    localVersion,
    latestVersion,
  ]);

  const updateToLatestEffect = useCallback(() => {
    updateLocalAppVersionToLatestEffect();
    updateAppStore({ isAppVersionNew: false });
    setLocalVersion(latestVersion);
  }, [latestVersion, updateAppStore]);

  // Only run at mount.
  useEffect(() => {
    updateLocalAppVersionToLatestEffect();

    console.warn("localVersion", localVersion);
    console.warn("latestVersion", latestVersion);
    if (localVersion && latestVersion !== localVersion) {
      updateAppStore({ isAppVersionNew: true });
    }
  }, []);

  return { latestVersion, localVersion, isLocalVersionDeprecated, updateToLatestEffect };
}

/**
 *
 */
export function useConnectedAppVersionMetaData(): AppVersionMetadataTuple | undefined {
  const { t: tVer } = useTranslation(I18Namespace.Versions);
  const { latestVersion } = useConnectedAppVersionInfo();

  return useMemo(() => {
    return tVer(latestVersion.split(".").join("_"), { returnObjects: true }) as AppVersionMetadataTuple;
  }, [latestVersion, tVer]);
}

/**
 *
 */
export function useConnectedVisibilityDetection() {
  if (isDev) return;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateAppVisibility = useConnectedAppAction(updateAppVisibilityAction);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    updateAppVisibility({ isAppVisible: document?.visibilityState === "visible" });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useVisibilityChange({
    onHide: () => updateAppVisibility({ isAppVisible: false }),
    onShow: () => updateAppVisibility({ isAppVisible: true }),
  });
}
