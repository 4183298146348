//
// saga.aboutApp.js
// stockhouse
//
// Created by Thomas Schönmann on 16.06.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Redux side effects for about-app state.
//
/*
 *
 * Watcher.
 *
 */

export default function*() {
  yield console.log("no app-saga yet");
}

/*
 *
 * Sagas.
 *
 */
