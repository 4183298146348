//
// model.app.ts
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Functions.
 *
 */

import { AppStoreKeys } from "./types/type.app";
import config from "../configs/config.version.json";

/*
 *
 * MARK: Attributes.
 *
 */

/** Check if the app is installed as PWA. */
export const isAppStandalone = window.matchMedia("(display-mode: standalone)").matches;

/*
 *
 * Functions.
 *
 */

/**
 * Override console.*-functions.
 */
export function disableLoggingIfNeeded() {
  if (process.env.NODE_ENV !== "development") {
    const noop = () => {};
    console.log = noop;
    console.info = noop;
    console.warn = noop;
    console.error = noop;
  }
}

/**
 *
 */
export function getIsBrowserSupported() {
  return !getIsIE();
}

/**
 * Get bool if we're running in IE.
 */
export function getIsIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");

  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
}

export function getIsWebWorkerSupported() {
  return typeof Worker !== "undefined";
}

export function getLocalAppVersion() {
  return localStorage.getItem(AppStoreKeys.LastLocalVersion);
}

export function updateLocalAppVersionToLatestEffect() {
  localStorage.setItem(AppStoreKeys.LastLocalVersion, config.app);
}

export function getLatestAppVersion(): string {
  return config.app;
}
