//
// saga.auth.js
// stockhouse
//
// Created by Thomas Schönmann on 28.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// User's side effects.
//

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { loginUserFailedAction, loginUserSucceededAction, updateUserAction } from "../actions/action.user";
import {
  LOGIN_USER_REQ,
  LOGIN_USER_SUCCEEDED,
  LoginUserAction,
  LoginUserSucceededAction,
  LOGOUT_USER,
  LogoutUserAction,
  UPDATE_USER_SETTINGS,
  UpdateUserSettingsAction,
  UserStore,
} from "../types/type.user";
import {
  getClientEntities,
  getClientForFirebaseUid,
  getDbUserForUid,
  getEntity,
  updateEntity,
  upsertEntity,
} from "../../models/model.db";
import { init as initIntegrations } from "../actions/action.integrations";
import { reset as resetRootAction } from "../actions/action.root";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import { SHUser, SHUserSettings } from "../../../../shared/src/models/types/type.user";
import { SHPermissionGroups } from "../../../../shared/src/models/types/type.permission";
import { SHClient, SHClientSettings } from "../../../../shared/src/models/types/type.client";
import { SHStore } from "../reducers";
import { getEmptyUserSettings } from "../../../../shared/src/models/model.user";
import { ThemeStorageKey } from "../../models/model.theme";
import { UPDATE_APP_VISIBILITY, UpdateAppVisibilityAction } from "../types/type.app";
import { tryCatchSaga } from "../util/util.saga";
import { addBasicTableSettings, getEntityTableColumns } from "../../models/model.table";
import { WarehouseStore } from "../types/type.warehouse";
import { SHProductInputSchemaId, SHProductPropertiesGroupVariant } from "../../../../shared/src/models/types/type.product.schema";
import { SHSchemaEntityInputSchema } from "../../../../shared/src/models/types/type.schema";

/*
 *
 * Watcher.
 *
 */

export default function* watcher() {
  yield takeEvery(LOGIN_USER_REQ, loginUserSaga);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
  yield takeEvery(LOGIN_USER_SUCCEEDED, initAfterLoginSaga);
  yield takeEvery(UPDATE_USER_SETTINGS, tryCatchSaga(updateUserSettingsSaga, { withProgress: true }));
  yield takeEvery(UPDATE_APP_VISIBILITY, updateAppVisibilityUserSaga);
}

/*LOGIN_USER_REQ
 *
 * Sagas.
 *
 */

/**
 *
 * @param action
 */
function* loginUserSaga(action: LoginUserAction) {
  try {
    const { auth } = action.payload;

    const client: SHClient = yield call(getClientForFirebaseUid, auth.uid);
    const user: SHUser = yield call(getDbUserForUid, auth.uid, client.id);
    const clientSettings: SHClientSettings = yield call(() =>
      getEntity<SHClientSettings>({ domain: SHDomain.ClientSettings, id: client.id })
    );

    yield put(initIntegrations({ client: client.id }));
    yield put(loginUserSucceededAction({ auth, user, clientSettings }));
  } catch (error) {
    yield put(loginUserFailedAction({ error }));
  }
}

/**
 *
 * @param a
 */
function* logoutUserSaga(a: LogoutUserAction) {
  console.warn("saga - user - logoutUserSaga - reset");
  yield put(resetRootAction());
  console.warn("saga - user - logoutUserSaga - enforce loggedOut");
  // Force the state to 'loggedOut' after the store got reset
  // to trigger LoginAppLoader to redirect to the SignIn-view.
  // If not done, an edge case could occur where the user has
  // reset the account's PW at another browser and now tries to
  // login on a different machine. The auth-listener for Firebase
  // would immediately trigger a logout (due to outdated PW) and
  // the app would never show anything beyond the loader, as the state
  // then was reset to 'init' and no further changes happen.
  yield put(updateUserAction({ state: "loggedOut" }));
}

/**
 *
 * @param action
 */
function* initAfterLoginSaga(action: LoginUserSucceededAction) {
  const { user } = action.payload;

  const [settings, permissions]: [SHUserSettings, SHPermissionGroups] = yield all([
    call(() => getEntity<SHUserSettings>({ domain: SHDomain.UserSettings, id: user.id })),
    // TODO: Update/create interface for user's permissions.
    call(() => getEntity<any>({ domain: SHDomain.UserPermissions, id: user.id })),
  ]);

  if (settings.themeVariant) {
    yield call(() => localStorage.setItem(ThemeStorageKey.ThemeVariant, settings.themeVariant!));
  }

  if (!settings.tableSettings) {
    const productInputSchema: SHSchemaEntityInputSchema<SHProductPropertiesGroupVariant, SHProductInputSchemaId>[] = yield call(
      () =>
        getClientEntities<SHSchemaEntityInputSchema<SHProductPropertiesGroupVariant, SHProductInputSchemaId>>({
          domain: SHDomain.ProductInputSchemas,
          client: settings.client,
        })
    );
    const basicColumns = getEntityTableColumns(productInputSchema[0]);
    settings.tableSettings = yield call(() => addBasicTableSettings(user.id, basicColumns));
  }

  yield put(updateUserAction({ userSettings: settings, permissions }));
}

/**
 *
 * @param a
 */
function* updateUserSettingsSaga(a: UpdateUserSettingsAction) {
  const { userSettings, user }: UserStore = yield select((s: SHStore) => s.user);

  // Special case: user updates theme, which also has to be cached locally,
  // else the theme would be "auto" during init, when no theme from the DB is loaded yet.
  //
  // Note: has to be called first, as theme-effect in ThemeProvider reads from cache first only
  // defaults when none is found.
  if (a.payload.themeVariant) {
    localStorage.setItem(ThemeStorageKey.ThemeVariant, a.payload.themeVariant!);
  }

  if (!userSettings) {
    const upserted: SHUserSettings = yield call(() =>
      upsertEntity<SHUserSettings>({ domain: SHDomain.UserSettings, client: user!.client, data: getEmptyUserSettings({}) })
    );
    yield put(updateUserAction({ userSettings: { ...upserted, ...a.payload } }));
  } else {
    const update: SHUserSettings = { ...userSettings!, ...a.payload };
    yield all([
      put(updateUserAction({ userSettings: update })),
      call(() => updateEntity<SHUserSettings>({ domain: SHDomain.UserSettings, id: user!.id, data: update })),
    ]);
  }
}

/**
 *
 * @param a
 */
function* updateAppVisibilityUserSaga(a: UpdateAppVisibilityAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);

  if (user) {
    yield call(() =>
      updateEntity<SHUser>({ id: user.id, domain: SHDomain.Users, data: { isActiveNow: a.payload.isAppVisible } })
    );
    yield put(updateUserAction({ user: { ...user, isActiveNow: a.payload.isAppVisible } }));
  }
}
