//
// reducer.error.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {ErrorStore, ErrorStoreAction, UPDATE} from "../types/type.error";
import {PRODUCT_UPSERT_FAILED} from "../types/type.warehouse";

/*
 *
 * Attributes.
 *
 */

const store: ErrorStore = {
  errors: []
};

/*
 *
 * Functions.
 *
 */

export default function(s = store, a: ErrorStoreAction): ErrorStore {
  switch (a.type) {
    case UPDATE:
      return { ...s, ...a.payload };

    case PRODUCT_UPSERT_FAILED:
      return { ...s, errors: [...s.errors, { origin: "warehouse", raw: a.payload.error }] };

    default:
      return s;
  }
}
