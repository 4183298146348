//
// model.product.ts
//
// Created by Thomas Schönmann on 19.08.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  SHProductCore,
  SHProductDisposal,
  SHProductProducerArticle,
  SHProductProducerArticleImageAsset,
  SHProductProducerMedia,
} from "./types/type.product";
import { getEmptyEntity } from "./model.db";

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param props
 */
export function getEmptyProductProducerArticle(
  props: Omit<Partial<SHProductProducerArticle>, "producerCoreRef"> & Pick<SHProductProducerArticle, "producerCoreRef">
): SHProductProducerArticle {
  return {
    id: "",
    createdAt: Date.now(),
    name: "",
    client: props.client,
    articleNr: "",
    area: "",
    position: "",
    ...props,
  };
}

/**
 *
 * @param props
 */
export const getEmptyProductCore = getEmptyEntity<SHProductCore>({
  id: "",
  continuousId: 0,
  client: "",
  createdAt: Date.now(),
  values: {},
  producerCoreRef: "",
  producerArticleRef: "",
});

/**
 *
 * @param props
 */
export const getEmptyProductProducerMedia = getEmptyEntity<SHProductProducerMedia>({
  id: "",
  client: "",
  producerArticleRef: "",
  images: [],
  createdAt: Date.now(),
});

/**
 *
 */
export const getEmptyProductProducerArticleImageAsset = getEmptyEntity<SHProductProducerArticleImageAsset>({
  url: "",
  createdAt: Date.now(),
});

/**
 *
 */
export const getEmptyProductDisposal = getEmptyEntity<SHProductDisposal>({
  id: "",
  continuousId: -1,
  state: "draft",
  createdAt: Date.now(),
  client: "",
  productCoreRefs: [],
  assigneeRef: "",
  documents: [],
  notes: "",
});
