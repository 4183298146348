//
// reducer.appBar.js
// stockhouse
//
// Created by Thomas Schönmann on 20.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// AppBar's reducer.
//

import {
    AppBarStore,
    AppBarStoreAction,
    UPDATE,
    UPDATE_NAV_DRAWER,
    UPDATE_SHOPPING_CARD_ICON_VISIBILITY
} from "../types/type.appBar";

/*
 *
 * Attributes.
 *
 */

const store: AppBarStore = {
  isNavDrawerOpen: false,
  title: "",
  isShadowDisabled: false,
  isStickyEnforced: false,
  isShoppingCardIconVisible: false
};

/*
 *
 * Functions.
 *
 */

export default (s = store, a: AppBarStoreAction): AppBarStore => {
  switch (a.type) {
    case UPDATE:
      return { ...s, ...a.payload };
    case UPDATE_NAV_DRAWER:
      return { ...s, isNavDrawerOpen: a.payload.isOpen };
    case UPDATE_SHOPPING_CARD_ICON_VISIBILITY:
      return { ...s, isShoppingCardIconVisible: a.payload.isVisible };

    default:
      return s;
  }
};
