//
// action.aboutApp.js
// stockhouse
//
// Created by Thomas Schönmann on 16.06.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import {
  BUMP_TO_LATEST_VERSION,
  BumpToLatestVersionAction,
  SET_LAST_LOCAL_VERSION,
  SetLastLocalVersionAction,
  SetLastLocalVersionPayload,
  UPDATE,
  UPDATE_APP_VISIBILITY,
  UpdateAppAction,
  UpdateAppPayload,
  UpdateAppVisibilityAction,
  UpdateAppVisibilityPayload,
} from "../types/type.app";
/*
 *
 * Actions.
 *
 */

export const updateAppAction = (payload: UpdateAppPayload): UpdateAppAction => ({
  type: UPDATE,
  payload,
});

export const setLastLocalVersion = (payload: SetLastLocalVersionPayload): SetLastLocalVersionAction => ({
  type: SET_LAST_LOCAL_VERSION,
  payload,
});

export const bumpToLatestVersion = (): BumpToLatestVersionAction => ({
  type: BUMP_TO_LATEST_VERSION,
});

export const updateAppVisibilityAction = (payload: UpdateAppVisibilityPayload): UpdateAppVisibilityAction => ({
  type: UPDATE_APP_VISIBILITY,
  payload,
});
