//
// type.db.tsx
//
// Created by Thomas on 14.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Attributes.
 *
 */

import { SHSearchFilterLensConfig } from "../../models/types/type.search";
import {
  SHProductCore,
  SHProductProducerArticle,
  SHProductProducerArticleImageAsset,
  SHProductProducerCore,
  SHProductProducerMedia,
} from "../../../../shared/src/models/types/type.product";
import { SHEntityRef } from "../../../../shared/src/models/types/type.db";
import { ChangeEvent } from "react";
import { SHSchemaEntityInputSchema, SHSchemaEntityValues } from "../../../../shared/src/models/types/type.schema";
import { SHProductInputSchemaId, SHProductPropertiesGroupVariant } from "../../../../shared/src/models/types/type.product.schema";
import { CountableDraftEntity } from "../util/util.mix.draft";

export const UPDATE = "warehouse/update";
export const UPDATE_PRODUCER = "warehouse/update/producer";
export const UPDATE_INPUT = "warehouse/update/input";
export const UPDATE_PRODUCT_CORE_VALUES = "warehouse/update/product-core-values";
export const UPDATE_CACHE = "warehouse/update/cache";
export const UPDATE_PRODUCT_CORE_DRAFT = "warehouse/update/product-core-draft";
export const UPDATE_PRODUCT_CORE_DRAFT_VALUES = "warehouse/update/product-core-draft-values";
export const UPDATE_PRODUCER_ARTICLE_IMAGES = "warehouse/update/producer-article-images";

export const RESET = "warehouse/reset";
export const PRODUCT_UPSERT_REQ = "warehouse/product/upsert/req";
export const PRODUCT_UPSERT_SUCCEEDED = "warehouse/product/upsert/suc";
export const PRODUCT_UPSERT_FAILED = "warehouse/product/upsert/fail";
export const CHANGE_STATE = "warehouse/change-state";
export const INIT_PRODUCT_DIALOG = "warehouse/product/init/dialog";

export const CREATE_PRODUCER_REQ = "producer/create/req";
export const CREATE_PRODUCER_SUC = "producer/create/suc";
export const CREATE_PRODUCER_ERR = "producer/create/err";

export const SELECT_PRODUCT = "warehouse/product/select";
export const UNSELECT_PRODUCT = "warehouse/product/unselect";
export const INIT_NEW_PRODUCT = "warehouse/product/start-new";

export const SELECT_PRODUCER_CORE = "warehouse/producer/select-core";
export const SELECT_PRODUCER_ARTICLE = "warehouse/producer/select-article";
export const SELECT_PRODUCT_FROM_OTHER_CONTEXT = "warehouse/product/select-from-other-context";

export const DELETE_PRODUCT_CORE = "warehouse/product/delete-core";

export const CLONE_DRAFT = "warehouse/draft/clone";
export const SELECT_DRAFT = "warehouse/draft/select";
export const REMOVE_DRAFT = "warehouse/draft/remove";

export const UPLOAD_IMAGES_FROM_INPUT = "warehouse/upload/images-from-input";
export const REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA = "warehouse/remove/image-from-producer-article-media";

/*
 *
 * State.
 *
 */

export interface WarehouseStore {
  /** The current state the warehouse is in */
  state: WarehouseStoreState;
  /** The complete input schema, based on the currently selected client. */
  productInputSchema?: SHSchemaEntityInputSchema<SHProductPropertiesGroupVariant, SHProductInputSchemaId>;
  searchFilterLensConfig?: SHSearchFilterLensConfig;

  producerCore?: SHProductProducerCore;
  producerArticle?: SHProductProducerArticle;
  producerMedia?: SHProductProducerMedia;

  /** List of all "real" products in warehouse for this producer + article. */
  products?: SHProductCore[];

  /** If a single real product gets selected, its data gets stored here. */
  selectedProductCore?: SHProductCore;

  /** Keep input values for usage in redux and delete if not needed any more. */
  inputProducer: {
    producerName: string;
    producerArticle: string;
    producerArticleNr: string;
    producerArea: string;
    producerPosition: string;
    countNewProducts: number;
  };

  /** Cache for input suggestions. */
  cache: WarehouseStoreCache;

  drafts: Array<WarehouseStoreProductCoreDraft>;
  selectedDraft?: WarehouseStoreProductCoreDraft;

  isProductUpsertDialogOpen?: boolean;
  isProducerFormCollapsed?: boolean;
}

export type WarehouseStoreState =
  | "init"
  | "loading"
  | "selectProducer"
  | "createProducerData"
  | "productsList"
  | "productDetail"
  | "createProductCore"
  | "mediaData"
  | "shopData";

export interface WarehouseStoreCache {
  producerCores: SHProductProducerCore[];
  producerArticles: SHProductProducerArticle[];
}

export type WarehouseStoreProductCoreDraft = CountableDraftEntity<SHProductCore>;

/*
 *
 * Actions.
 *
 */

export interface UpdateWarehouseAction {
  type: typeof UPDATE;
  payload: UpdateWarehousePayload;
}

export type UpdateWarehousePayload = Partial<WarehouseStore>;

export interface UpdateProductCoreValuesAction {
  type: typeof UPDATE_PRODUCT_CORE_VALUES;
  payload: UpdateProductCoreValuesPayload;
}

export type UpdateProductCoreValuesPayload = SHSchemaEntityValues<SHProductInputSchemaId>;

export interface ResetAction {
  type: typeof RESET;
  payload: ResetPayload;
}

export interface ResetPayload {
  variant: "all" | "product-upsert";
}

export interface ProductUpsertAction {
  type: typeof PRODUCT_UPSERT_REQ;
  payload: ProductUpsertPayload;
}
export type ProductUpsertPayload = {
  product?: Partial<SHProductCore>;
};

export interface ProductUpsertSucceededAction {
  type: typeof PRODUCT_UPSERT_SUCCEEDED;
  payload: ProductUpsertSucceededPayload;
}

export interface ProductUpsertSucceededPayload {
  productCores?: SHProductCore[];
}

export interface ProductUpsertFailedAction {
  type: typeof PRODUCT_UPSERT_FAILED;
  payload: ProductUpsertFailedPayload;
}

export interface ProductUpsertFailedPayload {
  error: Error;
}

export interface ChangeStateAction {
  type: typeof CHANGE_STATE;
  payload: ChangeStatePayload;
}

export interface ChangeStatePayload {
  state: WarehouseStoreState;
}


export interface InitProductDialogAction {
  type: typeof INIT_PRODUCT_DIALOG;
  payload: InitProductDialogPayload;
}

export type InitProductDialogPayload = Partial<
  Pick<
    WarehouseStore,
    | "isProductUpsertDialogOpen"
    | "state"
    | "producerCore"
    | "producerArticle"
    | "producerMedia"
    | "products"
    | "selectedProductCore"
  >
>;

export interface UpdateProducerAction {
  type: typeof UPDATE_PRODUCER;
  payload: UpdateProducerPayload;
}

export type UpdateProducerPayload = Partial<Pick<WarehouseStore, "producerCore" | "producerArticle" | "producerMedia">>;

export interface UpdateWarehouseInputAction {
  type: typeof UPDATE_INPUT;
  payload: UpdateWarehouseInputPayload;
}

export type UpdateWarehouseInputPayload = Partial<WarehouseStore["inputProducer"]>;

export interface UpdateProductCoreDraftAction {
  type: typeof UPDATE_PRODUCT_CORE_DRAFT;
  payload: UpdateProductCoreDraftPayload;
}

export type UpdateProductCoreDraftPayload = Partial<WarehouseStoreProductCoreDraft>;

export interface UpdateProducerArticleImagesAction {
  type: typeof UPDATE_PRODUCER_ARTICLE_IMAGES;
  payload: UpdateProducerArticleImagesPayload;
}

export interface UpdateProducerArticleImagesPayload {
  variant: "remove" | "append";
  images: SHProductProducerArticleImageAsset[];
}

export interface CreateProducerReqAction {
  type: typeof CREATE_PRODUCER_REQ;
  payload: CreateProducerReqPayload;
}

export interface CreateProducerReqPayload {
  name: string;
  article: string;
}

export interface CreateProducerSucAction {
  type: typeof CREATE_PRODUCER_SUC;
  payload: CreateProducerSucPayload;
}

export type CreateProducerSucPayload = Pick<WarehouseStore, "producerCore" | "producerArticle">;

export interface CreateProducerErrAction {
  type: typeof CREATE_PRODUCER_ERR;
  payload: CreateProducerErrPayload;
}

export interface CreateProducerErrPayload {
  error: Error;
}

export interface SelectProductAction {
  type: typeof SELECT_PRODUCT;
  payload: SelectProductPayload;
}

export interface SelectProductPayload {
  product: SHProductCore;
}

export interface UnselectProductAction {
  type: typeof UNSELECT_PRODUCT;
}

export interface InitNewProductAction {
  type: typeof INIT_NEW_PRODUCT;
}

export interface SelectProducerCoreAction {
  type: typeof SELECT_PRODUCER_CORE;
  payload: SelectProducerPayload;
}

export interface SelectProducerPayload {
  core: SHProductProducerCore;
}

export interface UpdateCacheAction {
  type: typeof UPDATE_CACHE;
  payload: UpdateCachePayload;
}

export type UpdateCachePayload = Partial<WarehouseStoreCache>;

export interface DeleteProductCoreAction {
  type: typeof DELETE_PRODUCT_CORE;
  payload: DeleteProductCorePayload;
}

export interface DeleteProductCorePayload {
  coreId: SHEntityRef;
}

export interface CloneDraftAction {
  type: typeof CLONE_DRAFT;
  payload: CloneDraftPayload;
}

export interface CloneDraftPayload {
  index: number;
}

export interface SelectDraftAction {
  type: typeof SELECT_DRAFT;
  payload: SelectDraftPayload;
}

export interface SelectDraftPayload {
  index: number;
}

export interface RemoveDraftAction {
  type: typeof REMOVE_DRAFT;
}

export interface UpdateProductCoreDraftValuesAction {
  type: typeof UPDATE_PRODUCT_CORE_DRAFT_VALUES;
  payload: UpdateProductCoreDraftValuesPayload;
}

export type UpdateProductCoreDraftValuesPayload = Partial<SHSchemaEntityValues<SHProductInputSchemaId>>;

export interface UploadImagesFromInputAction {
  type: typeof UPLOAD_IMAGES_FROM_INPUT;
  payload: UploadImagesFromInputPayload;
}

export interface UploadImagesFromInputPayload {
  event: ChangeEvent<HTMLInputElement>;
}

export interface RemoveImageFromProducerArticleMediaAction {
  type: typeof REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA;
  payload: RemoveImageFromProducerArticleMediaPayload;
}

export interface RemoveImageFromProducerArticleMediaPayload {
  image: SHProductProducerArticleImageAsset;
}

export interface SelectProductFromOtherContextAction {
  type: typeof SELECT_PRODUCT_FROM_OTHER_CONTEXT;
  payload: SelectProductFromOtherContextPayload;
}

export interface SelectProductFromOtherContextPayload {
  core?: SHProductCore;
  coreId?: SHEntityRef;
}

/*
 *
 * Union Action.
 *
 */

export type WarehouseStoreAction =
  | UpdateWarehouseAction
  | UpdateProductCoreValuesAction
  | UpdateProductCoreDraftValuesAction
  | UpdateProductCoreDraftAction
  | UpdateProducerArticleImagesAction
  | ResetAction
  | ProductUpsertAction
  | ProductUpsertSucceededAction
  | ProductUpsertFailedAction
  | ChangeStateAction
  | InitProductDialogAction
  | UpdateProducerAction
  | UpdateWarehouseInputAction
  | CreateProducerReqAction
  | CreateProducerSucAction
  | CreateProducerErrAction
  | SelectProductAction
  | UnselectProductAction
  | InitNewProductAction
  | SelectProducerCoreAction
  | UpdateCacheAction
  | DeleteProductCoreAction
  | CloneDraftAction
  | SelectDraftAction
  | RemoveDraftAction
  | UploadImagesFromInputAction
  | RemoveImageFromProducerArticleMediaAction
  | SelectProductFromOtherContextAction;

export type WarehouseStorePayload =
  | UpdateWarehousePayload
  | UpdateProductCoreValuesPayload
  | UpdateProductCoreDraftValuesPayload
  | UpdateProductCoreDraftPayload
  | UpdateProducerArticleImagesPayload
  | ProductUpsertPayload
  | ProductUpsertSucceededPayload
  | ProductUpsertFailedPayload
  | ChangeStatePayload
  | InitProductDialogPayload
  | UpdateProducerPayload
  | UpdateWarehouseInputPayload
  | CreateProducerReqPayload
  | CreateProducerSucPayload
  | CreateProducerErrPayload
  | SelectProductPayload
  | SelectProducerPayload
  | UpdateCachePayload
  | DeleteProductCorePayload
  | SelectDraftPayload
  | CloneDraftPayload
  | ResetPayload
  | UploadImagesFromInputPayload
  | RemoveImageFromProducerArticleMediaPayload
  | SelectProductFromOtherContextPayload;
