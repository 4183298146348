//
// saga.transaction.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * MARK: Watcher.
 *
 */

export default function* () {
  yield console.log("no sale-saga");
}
