//
// action.userTasks.tsx
//
// Created by Thomas on 16.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {UPDATE, UpdateUserTasksAction, UpdateUserTasksPayload} from "../types/type.userTasks";

/*
 *
 * Functions.
 *
 */

export const updateUserTasksAction = (payload: UpdateUserTasksPayload): UpdateUserTasksAction => ({
  type: UPDATE,
  payload
});
