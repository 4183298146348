//
// saga.editor.js
// stockhouse
//
// Created by Thomas Schönmann on 16.06.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import { all } from "redux-saga/effects";

import user from "./saga.user";
import about from "./saga.app";
import shop from "./saga.shop";
import integrations from "./saga.integrations";
import console from "./saga.console";
import warehouse from "./saga.warehouse";
import userTasks from "./saga.userTasks";
import manageUsers from "./saga.manageUsers";
import trader from "./saga.trader";
import sale from "./saga.sale";
import presale from "./saga.presale";
import disposal from "./saga.disposal";
import newProducts from "./saga.newProduct";

/*
 *
 * Exports.
 *
 */

export default function* root() {
  yield all([
    user(),
    about(),
    shop(),
    integrations(),
    console(),
    warehouse(),
    userTasks(),
    manageUsers(),
    trader(),
    sale(),
    presale(),
    disposal(),
    newProducts(),
  ]);
}
