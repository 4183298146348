//
// saga.disposal.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  DisposalStore,
  OPEN_DISPOSAL_DIALOG,
  OpenDisposalDialogAction,
  REMOVE_DISPOSAL,
  RemoveDisposalAction,
  UPDATE_DIALOG_DISPOSAL_DRAFT,
  UpdateDisposalDialogDraftAction,
  UPSERT_DISPOSAL_DRAFT,
  UpsertDisposalDraftAction,
} from "../types/type.disposal";
import { tryCatchSaga } from "../util/util.saga";
import { closeDisposalDialogAction, updateDisposalStoreAction } from "../actions/action.disposal";
import { getEmptyProductDisposal } from "../../../../shared/src/models/model.product";
import { UserStore } from "../types/type.user";
import { SHStore } from "../reducers";
import { revertFromDisposalResolution, transformToDisposalResolution } from "../../models/model.disposal";
import { SHProductCore, SHProductDisposal, SHProductDisposalResolution } from "../../../../shared/src/models/types/type.product";
import { SHTraderCompany } from "../../../../shared/src/models/types/type.trader";
import { getClientEntities, getEntity, updateEntity, upsertEntityWithContinuousId } from "../../models/model.db";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import { updateTraderCacheActions } from "../actions/action.trader";
import { AppError } from "../../models/types/type.app";
import { getEmptyEntityReservation } from "../../../../shared/src/models/model.reservation";
import { firestore } from "firebase";
import { filterAwayUndefined } from "../../../../shared/src/utils/util.data";

/*
 *
 * Watcher.
 *
 */

export default function* () {
  yield takeLatest(OPEN_DISPOSAL_DIALOG, tryCatchSaga(openDisposalDialogSaga, { withProgress: true }));
  yield takeEvery(REMOVE_DISPOSAL, tryCatchSaga(removeDisposalSaga, { withProgress: true, withStore: "disposal" }));
  yield takeEvery(
    UPDATE_DIALOG_DISPOSAL_DRAFT,
    tryCatchSaga(updateDialogDisposalDraftSaga, { withProgress: true, withStore: "disposal" })
  );
  yield takeLatest(UPSERT_DISPOSAL_DRAFT, tryCatchSaga(upsertDisposalSaga, { withProgress: true, withStore: "disposal" }));
}

/*
 *
 * Sagas.
 *
 */

/**
 *
 * @param a
 */
function* openDisposalDialogSaga(a: OpenDisposalDialogAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  const source =
    a.payload.disposal ||
    getEmptyProductDisposal({
      client: user!.client,
      assigneeRef: user!.id,
    });

  const disposal: SHProductDisposalResolution = yield call(() => transformToDisposalResolution(source));

  yield put(
    updateDisposalStoreAction({
      isDisposalDialogOpen: true,
      dialog: {
        draft: disposal,
        isTraderViewVisible: Boolean(disposal.traderCompany),
      },
    })
  );

  // TODO: Debug. Using shared cache from trader, has to be ad-hoc loading. -Tom
  const companies: SHTraderCompany[] = yield call(() =>
    getClientEntities({ domain: SHDomain.TraderCompanies, client: user!.client })
  );
  yield put(updateTraderCacheActions({ companies }));
}

/**
 *
 * @param a
 * @param s
 */
function* removeDisposalSaga(a: RemoveDisposalAction, s?: DisposalStore) {
  const { disposal } = a.payload;
  const store = s!;

  yield call(() => {
    throw Error("no-impl");
  });
}

/**
 *
 * @param a
 * @param s
 */
function* updateDialogDisposalDraftSaga(a: UpdateDisposalDialogDraftAction, s?: DisposalStore) {}

/**
 *
 * @param a
 * @param s
 */
function* upsertDisposalSaga(a: UpsertDisposalDraftAction, s?: DisposalStore) {
  const draft = s!.dialog.draft!;
  const { user }: UserStore = yield select((s: SHStore) => s.user);

  // Just to make sure, validate refs.
  draft.productCoreRefs = draft.productCores.map((c) => c.id);
  draft.traderCompanyRef = draft.traderCompany?.id;
  draft.traderCoreRef = draft.traderCore?.id;

  if (draft.productCores.some((core) => core.reservation?.target !== "disposal" || core.reservation.target !== undefined)) {
    throw new AppError({ tTitle: "product-reserved" });
  }

  if (!draft.id) {
    const upsert: SHProductDisposal = yield call(() =>
      upsertEntityWithContinuousId({
        domain: SHDomain.ProductDisposals,
        client: user!.client,
        data: filterAwayUndefined(revertFromDisposalResolution(draft)),
      })
    );
    yield call(() =>
      Promise.all(
        draft.productCores.map((core) =>
          updateEntity<SHProductCore>({
            domain: SHDomain.ProductCores,
            id: core.id,
            data: {
              reservation: getEmptyEntityReservation({ target: "disposal", ref: upsert.id }),
            },
          })
        )
      )
    );
  } else {
    const old: SHProductDisposal = yield call(() =>
      getEntity<SHProductDisposal>({ domain: SHDomain.ProductDisposals, id: draft.id })
    );
    yield call(() =>
      updateEntity<SHProductDisposal>({
        id: draft.id,
        domain: SHDomain.ProductDisposals,
        data: filterAwayUndefined(revertFromDisposalResolution(draft)),
      })
    );

    // Check for product ref-updates.
    const newProductCores = draft.productCores.filter((c) => !old.productCoreRefs.includes(c.id));
    yield call(() =>
      Promise.all(
        newProductCores.map((c) =>
          updateEntity<SHProductCore>({
            domain: SHDomain.ProductCores,
            id: c.id,
            data: { reservation: { target: "disposal", ref: draft.id } },
          })
        )
      )
    );

    const removedProductCores = old.productCoreRefs.filter((c) => !draft.productCores.some((core) => core.id === c));
    yield call(() =>
      Promise.all(
        removedProductCores.map((id) =>
          updateEntity<SHProductCore>({
            domain: SHDomain.ProductCores,
            id,
            data: { reservation: firestore.FieldValue.delete() },
          })
        )
      )
    );
  }

  // Finish.
  yield put(closeDisposalDialogAction());
}
