//
// model.transaction.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { getEmptyEntity } from "./model.db";
import { SHPresale, SHPresaleProductCore, SHPresaleProductCoreResolution } from "./types/type.presale";
import { getEmptyProductCore } from "./model.product";

/*
 *
 * Functions.
 *
 */

export const getEmptyPresale = getEmptyEntity<SHPresale>({
  id: "",
  client: "",
  continuousId: -1,
  createdAt: Date.now(),
  productCores: [],
  assigneeRef: "",
  state: { variant: "in-edit" },
  notes: "",
  documentRefs: [],
});

export const getEmptyPresaleProductCore = getEmptyEntity<SHPresaleProductCore>({
  productCoreRef: "",
  taxRate: 0,
  priceNet: 0,
  priceGross: 0,
  priceOrigin: 0,
  proceeds: 0,
  currency: "EUR",
});

export const getEmptyPresaleProductCoreResolution = getEmptyEntity<SHPresaleProductCoreResolution>({
  productCore: getEmptyProductCore({}),
  taxRate: 0,
  priceNet: 0,
  priceGross: 0,
  priceOrigin: 0,
  proceeds: 0,
  currency: "EUR",
});
