//
// PrivateRoute.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { memo, Suspense, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";

import { useConnectedUserStore } from "../../hooks/hook.user";
import { getUserRoleAsMetric, userRoleAsMetric } from "../../models/model.user";
import { LazyRouteComponent, SHRoute, SHUrlPath } from "../../models/types/type.route";

/*
 *
 * Interfaces.
 *
 */

interface PrivateRoute {
  route: SHRoute<LazyRouteComponent>;
  path: SHUrlPath;
  component: any;
}

/*
 *
 * Components.
 *
 */

export default memo<PrivateRoute>(props => {
  const { route } = props;

  const userStore = useConnectedUserStore();
  console.log(`user in ${route.path}: ${userStore?.user?.client}`);

  const isAllowed = useMemo(() => {
    // @ts-ignore
    return getUserRoleAsMetric(userStore?.user?.role) >= userRoleAsMetric[route.requiredRole || SHUserRole.STAFF];
  }, [userStore, route.requiredRole]);

  return (
    <Route
      render={props => {
        if (userStore.state === "loggedIn" && isAllowed) {
          return (
            <Suspense fallback="">
              <route.Component {...props} />
            </Suspense>
          );
        } else if (userStore.state === "loggedOut") {
          return (
            <Redirect
              to={{
                pathname: SHUrlPath.Profile,
                state: { from: props.location }
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: SHUrlPath.InitApp,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
});
