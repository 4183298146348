//
// hook.progress.tsx
//
// Created by Thomas on 12.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {getConnectedActionHook, getConnectedStore} from "../store/util/util.hook";
import {ProgressStoreAction, ProgressStorePayload} from "../store/types/type.progress";

/*
 *
 * Functions.
 *
 */

export const useConnectedProgressStore = getConnectedStore("progress");

export const useConnectedProgressAction = getConnectedActionHook<ProgressStoreAction, ProgressStorePayload>();
