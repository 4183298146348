import {
  CREATE_NEW_PRODUCT,
  CreateNewProductAction,
  CreateNewProductPayload,
  INIT_NEW_PRODUCT_DIALOG,
  InitNewProductDialogAction,
  InitNewProductDialogPayload,
  RESET_NEW_PRODUCT,
  ResetNewProductAction,
  ResetNewProductPayload,
  SELECT_NEW_PRODUCT_ARTICLE,
  SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT,
  SELECT_NEW_PRODUCT_PRODUCER,
  SELECT_NEW_PRODUCT_TYPE,
  SelectedNewProductProducerAction,
  SelectedNewProductProducerPayload,
  SelectNewProductArticleAction,
  SelectNewProductArticlePayload,
  SelectNewProductFromOtherContextAction,
  SelectNewProductFromOtherContextPayload,
  SelectNewProductTypeAction,
  SelectNewProductTypePayload,
  UPDATE_CACHE,
  UpdateCacheAction,
  UpdateCachePayload,
} from "../types/type.newProduct";

/**
 * Actions for init new product dialog
 * **/
export const initNewProductDialogAction = (payload: InitNewProductDialogPayload): InitNewProductDialogAction => ({
  type: INIT_NEW_PRODUCT_DIALOG,
  payload,
});

/**
 * Actions for reset new product dialog
 * **/
export const resetNewProductAction = (payload: ResetNewProductPayload): ResetNewProductAction => ({
  type: RESET_NEW_PRODUCT,
  payload,
});

/**
 * Action to Select a SHNewProductType
 */
export const selectNewProductType = (payload: SelectNewProductTypePayload): SelectNewProductTypeAction => ({
  type: SELECT_NEW_PRODUCT_TYPE,
  payload,
});

/**
 * Action to Select a SHNewProductArticle
 */
export const selectNewProductArticle = (payload: SelectNewProductArticlePayload): SelectNewProductArticleAction => ({
  type: SELECT_NEW_PRODUCT_ARTICLE,
  payload,
});

/**
 *
 * @param payload
 */
export const selectNewProductProducer = (payload: SelectedNewProductProducerPayload): SelectedNewProductProducerAction => ({
  type: SELECT_NEW_PRODUCT_PRODUCER,
  payload,
});

/**
 * Action to update the cache
 */
export const updateCache = (payload: UpdateCachePayload): UpdateCacheAction => ({
  type: UPDATE_CACHE,
  payload,
});

/**
 * Only relevant for saga
 * creates a new product in firebase
 */
export const createNewProduct = (payload: CreateNewProductPayload): CreateNewProductAction => ({
  type: CREATE_NEW_PRODUCT,
  payload,
});

/**
 * Selects a new product with all necessary information
 * @param payload
 */
export const selectNewProductFromOtherContext = (
  payload: SelectNewProductFromOtherContextPayload
): SelectNewProductFromOtherContextAction => ({
  type: SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT,
  payload,
});
