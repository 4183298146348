//
// type.userTasks.tsx
//
// Created by Thomas on 16.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

export const UPDATE = "USER_TASKS_UPDATE";

/*
 *
 * Store.
 *
 */

export interface UserTasksStore {
  isDrawerOpen?: boolean;
}

/*
 *
 * Actions.
 *
 */

export interface UpdateUserTasksAction {
  type: typeof UPDATE;
  payload: UpdateUserTasksPayload;
}

export type UpdateUserTasksPayload = Partial<UserTasksStore>;

/*
 *
 * Union action type.
 *
 */

export type UserTasksStoreAction = UpdateUserTasksAction;

export type UserTasksStorePayload = UpdateUserTasksPayload;
