import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  CREATE_NEW_PRODUCT,
  CreateNewProductAction,
  NewProductCache,
  NewProductStore,
  SELECT_NEW_PRODUCT_ARTICLE,
  SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT,
  SELECT_NEW_PRODUCT_PRODUCER,
  SELECT_NEW_PRODUCT_TYPE,
  SelectedNewProductProducerAction,
  SelectNewProductArticleAction,
  SelectNewProductFromOtherContextAction,
  SelectNewProductTypeAction,
  UPDATE_CACHE,
  UpdateCacheAction,
} from "../types/type.newProduct";
import {
  SHNewProduct,
  SHNewProductArticle,
  SHNEwProductProducer,
  SHNewProductTypeCore,
} from "../../../../shared/src/models/types/type.newProduct";
import { getReferencedEntities, upsertEntity } from "../../models/model.db";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import {
  initNewProductDialogAction,
  selectNewProductArticle,
  selectNewProductProducer,
  selectNewProductType,
  updateCache,
} from "../actions/action.newProduct";
import { SHStore } from "../reducers";
import _ from "lodash";
import { finishProgressThreadAction, updateProgressAction } from "../actions/action.progress";
import { v4 as uuidv4 } from "uuid";

/**
 *
 * Watcher
 *
 */

export default function* () {
  yield takeEvery(SELECT_NEW_PRODUCT_TYPE, getNewProducers);
  yield takeEvery(SELECT_NEW_PRODUCT_PRODUCER, getNewProductArticles);
  yield takeEvery(SELECT_NEW_PRODUCT_ARTICLE, addToArticles);
  yield takeEvery(CREATE_NEW_PRODUCT, createNewProduct);
  yield takeEvery(UPDATE_CACHE, checkForDuplicateEntries);
  yield takeEvery(SELECT_NEW_PRODUCT_FROM_OTHER_CONTEXT, initSelectNewProductFromOtherContext);
}

/**
 *
 * Sagas
 *
 */

function* initSelectNewProductFromOtherContext({ payload }: SelectNewProductFromOtherContextAction) {
  console.warn("here in saga");
  yield put(updateProgressAction({ isLoading: true }));

  const coreType: SHNewProductTypeCore[] = yield call(() =>
    getReferencedEntities<SHNewProductTypeCore>({
      domain: SHDomain.NewProductTypeCore,
      refId: payload.newProductTypeCoreRef,
      refKey: "id",
    })
  );
  yield put(selectNewProductType(coreType[0]));

  const producer: SHNEwProductProducer[] = yield call(() =>
    getReferencedEntities<SHNEwProductProducer>({
      domain: SHDomain.NewProductProducer,
      refId: payload.newProductProducerRef,
      refKey: "id",
    })
  );
  yield put(selectNewProductProducer(producer[0]));

  const article: SHNewProductArticle[] = yield call(() =>
    getReferencedEntities<SHNewProductArticle>({
      domain: SHDomain.NewProductArticles,
      refId: payload.newProductTypeArticleRef,
      refKey: "id",
    })
  );
  yield put(selectNewProductArticle(article[0]));
  yield put(initNewProductDialogAction({ isNewProductUpsertDialogOpen: true }));
}

/**
 *
 * @param newProductTypeCore
 */
function* getNewProductArticles({ payload }: SelectedNewProductProducerAction) {
  if (!payload) {
    yield put(updateCache({ articles: [] }));
    return;
  }

  const newProductArticles: SHNewProductArticle[] = yield call(() =>
    getReferencedEntities<SHNewProductArticle>({
      domain: SHDomain.NewProductArticles,
      refId: payload.id,
      refKey: "newProductProducerRef",
    })
  );

  const store: NewProductStore = yield select((s: SHStore) => s.newProduct);
  if (store.cache.producers.some((core) => core.id === payload.id)) {
    yield put(updateCache({ articles: newProductArticles }));
    return;
  }

  yield put(updateCache({ articles: newProductArticles, producers: [...store.cache.producers, payload] }));
}

/**
 *
 * @param payload
 */
function* addToArticles({ payload }: SelectNewProductArticleAction) {
  if (!payload) return;
  const store: NewProductStore = yield select((s: SHStore) => s.newProduct);
  if (!store.cache.articles.some((article) => article.id === payload.id)) {
    yield put(updateCache({ articles: [...store.cache.articles, payload] }));
  }
}

/**
 *
 * @param payload
 */
function* createNewProduct({ payload }: CreateNewProductAction) {
  const threadId = uuidv4();
  yield put(updateProgressAction({ isLoading: true, threadId }));
  yield call(() =>
    upsertEntity<SHNewProduct>({
      domain: SHDomain.NewProduct,
      client: payload.client,
      data: {
        ...payload,
      },
    })
  );
  yield put(finishProgressThreadAction({ threadId }));
}

/**
 *
 * @param payload
 */
function* checkForDuplicateEntries({ payload }: UpdateCacheAction) {
  const store: NewProductStore = yield select((s: SHStore) => s.newProduct);
  const uniqueCache: NewProductCache = { producers: [], articles: [], cores: [] };
  Object.keys(store.cache).map((key) => {
    const uniqueValues = _.uniqBy(
      store.cache[key as keyof NewProductCache] as (SHNewProductArticle | SHNewProductTypeCore | SHNEwProductProducer)[],
      (a) => a.id
    );
    // @ts-ignore
    uniqueCache[key as keyof NewProductCache] = uniqueValues;
  });

  let shouldUpdate = false;
  Object.keys(store.cache).map((key) => {
    if (store.cache[key as keyof NewProductCache].length !== uniqueCache[key as keyof NewProductCache].length) {
      shouldUpdate = true;
    }
  });

  if (shouldUpdate) {
    yield put(updateCache(uniqueCache));
  }
}

/**
 *
 * @param payload
 */
function* getNewProducers({ payload }: SelectNewProductTypeAction) {
  if (!payload) {
    yield put(updateCache({ articles: [], producers: [] }));
    return;
  }

  console.warn("get new producers: ", payload);
  const producers: SHNEwProductProducer[] = yield call(() =>
    getReferencedEntities<SHNEwProductProducer>({
      domain: SHDomain.NewProductProducer,
      refId: payload.id,
      refKey: "newProductTypeCoreRefs",
      multipleRef: true,
    })
  );

  const store: NewProductStore = yield select((s: SHStore) => s.newProduct);
  if (store.cache.cores.some((core) => core.id === payload.id)) {
    yield put(updateCache({ producers }));
    return;
  }
  console.warn("finsish procuder");
  yield put(updateCache({ producers, cores: [...store.cache.cores, payload] }));
}
