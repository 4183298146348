//
// index.tsx
//
// Created by Thomas on 17.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {AlgoliaSearchConfig, ConfigVariant, FirebaseConfig} from "../../../shared/src/models/types/type.config";
import firebaseConfig from "./config.secret.firebase.json";
import algoliaConfig from "./config.secret.algolia.json";

/*
 *
 * MARK: Attributes.
 *
 */

export const configs: { firebase?: FirebaseConfig; algolia?: AlgoliaSearchConfig } = {
  firebase: firebaseConfig,
  algolia: algoliaConfig,
};

/*
 *
 * Functions.
 *
 */

/**
 * Directly access a config from the cache. If hit in cache is empty, load the config first.
 *
 * Note:
 * All configs get initialized at app start, before everything else except React. For simple
 * access in synchronous functions/variables, you can access the cache directly with a
 * forced unwrap.
 *
 * @param variant
 */
export async function getCachedConfig(variant: ConfigVariant) {
  switch (variant) {
    case "firebase":
      if (!configs.firebase) configs.firebase = firebaseConfig;
      return configs.firebase;

    case "algolia":
      if (!configs.algolia) configs.algolia = algoliaConfig;
      return configs.algolia;
  }
}

/**
 * Init all configs. Firebase always has be initialized first,
 * as other config-inits may depend on it (functions, db).
 */
export async function initConfigCache() {
  const parallelLoadableVariants: ConfigVariant[] = ["algolia"];

  await getCachedConfig("firebase");
  await Promise.all(parallelLoadableVariants.map(getCachedConfig));
}

/**
 * Read the related env-param, parse it to json and return it
 * correctly typed.
 *
 * Note: this function assumes that we're in the correct context
 * and throws if no config could be parsed.
 *
 * @param variant
 */
/*
function parseConfigFromEnv<T extends AlgoliaSearchConfig | FirebaseConfig>(variant: ConfigEnvParamVariant) {
  console.log("process.env", process.env);
  const raw = process.env[variant];
  if (!raw) throw Error("invalid-env-for-config");

  return JSON.parse(raw) as T;
}
 */
