//
// model.db.ts
//
// Created by Thomas Schönmann on 19.08.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHEntity } from "./types/type.db";

/**
 * Little helper to get a callback for overriding a default SHEntity instance.
 *
 * @param complete
 */
export const getEmptyEntity = <T extends SHEntity>(complete: T) => (custom?: Partial<T>): T => ({
  ...complete,
  ...(custom || {}),
});
