//
// action.disposal.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  APPLY_COMPANY_SELECTION,
  ApplyDisposalCompanySelectionAction,
  ApplyDisposalCompanySelectionPayload,
  CLOSE_DISPOSAL_DIALOG,
  CloseDisposalDialogAction,
  OPEN_DISPOSAL_DIALOG,
  OpenDisposalDialogAction,
  OpenDisposalDialogPayload,
  REMOVE_DISPOSAL,
  RemoveDisposalAction,
  RemoveDisposalPayload,
  UPDATE,
  UPDATE_DIALOG,
  UPDATE_DIALOG_DISPOSAL_DRAFT,
  UpdateDisposalDialogAction,
  UpdateDisposalDialogDraftAction,
  UpdateDisposalDialogDraftPayload,
  UpdateDisposalDialogPayload,
  UpdateDisposalStoreAction,
  UpdateDisposalStorePayload,
  UPSERT_DISPOSAL_DRAFT,
  UpsertDisposalDraftAction,
} from "../types/type.disposal";

/*
 *
 * MARK: Functions.
 *
 */

export const updateDisposalStoreAction = (payload: UpdateDisposalStorePayload): UpdateDisposalStoreAction => ({
  type: UPDATE,
  payload,
});

export const updateDisposalDialogAction = (payload: UpdateDisposalDialogPayload): UpdateDisposalDialogAction => ({
  type: UPDATE_DIALOG,
  payload,
});

export const openDisposalDialogAction = (payload: OpenDisposalDialogPayload): OpenDisposalDialogAction => ({
  type: OPEN_DISPOSAL_DIALOG,
  payload,
});

export const closeDisposalDialogAction = (): CloseDisposalDialogAction => ({
  type: CLOSE_DISPOSAL_DIALOG,
});

export const removeDisposalAction = (payload: RemoveDisposalPayload): RemoveDisposalAction => ({
  type: REMOVE_DISPOSAL,
  payload,
});

export const updateDisposalDialogDraftAction = (payload: UpdateDisposalDialogDraftPayload): UpdateDisposalDialogDraftAction => ({
  type: UPDATE_DIALOG_DISPOSAL_DRAFT,
  payload,
});

export const applyDisposalTraderSelectionAction = (
  payload: ApplyDisposalCompanySelectionPayload
): ApplyDisposalCompanySelectionAction => ({
  type: APPLY_COMPANY_SELECTION,
  payload,
});

export const upsertDisposalDraftAction = (): UpsertDisposalDraftAction => ({
  type: UPSERT_DISPOSAL_DRAFT,
});
