//
// model.auth.tsx
//
// Created by Thomas on 14.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Functions.
 *
 */

/**
 * https://www.w3resource.com/javascript/form/email-validation.php
 *
 * @param mail
 */
export function getIsEmailValid(mail: string) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

/**
 * Make the 'gapi'-package available to use in code without
 * a global script-import.
 */
export async function initWebGoogleApis() {
  return new Promise((res, rej) => {
    try {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js?onload=onGapiLoad";
      // @ts-ignore
      window.onGapiLoad = () => gapi.load("auth2", { callback: res });
      document.body.appendChild(script);
    } catch (e) {
      rej(e);
    }
  });
}
