//
// type.user.ts
// stockhouse
//
// Created by Thomas Schönmann on 16.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { User as FBUser } from "firebase";
import { SHUser, SHUserSettings } from "../../../../shared/src/models/types/type.user";
import { SHPermissionGroups } from "../../../../shared/src/models/types/type.permission";
import { SHClientSettings } from "../../../../shared/src/models/types/type.client";

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "user/update";
export const UPDATE_USER_SETTINGS = "user/update/settings";
export const UPDATE_USER_STORE_VIEW = "user/update/view";

export const LOGIN_USER_REQ = "user/login/req";
export const LOGIN_USER_SUCCEEDED = "user/login/suc";
export const LOGIN_USER_FAILED = "user/login/err";

export const LOGOUT_USER = "user/logout";

/*
 *
 * Interfaces.
 *
 */

export type UserStoreState = "init" | "loggedIn" | "loggedOut";

export enum UserStoreProfileTab {
  core,
  settings,
}

export interface UserStore {
  auth?: FBUser;
  user?: SHUser;
  clientSettings?: SHClientSettings;
  errors: {};
  state: UserStoreState;
  userSettings?: SHUserSettings;
  permissions?: SHPermissionGroups;
  view: {
    selectedUserProfileTab: UserStoreProfileTab;
  };
}

/*
 *
 * Actions.
 *
 */

export interface UpdateUserStoreAction {
  type: typeof UPDATE;
  payload: UpdateUserStorePayload;
}

export type UpdateUserStorePayload = Partial<UserStore>;

export interface LoginUserAction {
  type: typeof LOGIN_USER_REQ;
  payload: LoginUserPayload;
}

export interface LoginUserPayload {
  auth: FBUser;
}

export interface LoginUserSucceededAction {
  type: typeof LOGIN_USER_SUCCEEDED;
  payload: LoginUserSucceededPayload;
}

export type LoginUserSucceededPayload = Required<Pick<UserStore, "auth" | "user" | "clientSettings">>;

export interface LoginUserFailedAction {
  type: typeof LOGIN_USER_FAILED;
  payload: LoginUserFailedPayload;
}

export interface LoginUserFailedPayload {
  error: any;
}

export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
}

export interface UpdateUserSettingsAction {
  type: typeof UPDATE_USER_SETTINGS;
  payload: UpdateUserSettingsPayload;
}

export type UpdateUserSettingsPayload = Partial<Omit<SHUserSettings, "id" | "createdAt" | "client">>;

export interface UpdateUserStoreViewAction {
  type: typeof UPDATE_USER_STORE_VIEW;
  payload: UpdateUserStoreViewPayload;
}

export type UpdateUserStoreViewPayload = Partial<UserStore["view"]>;

/*
 *
 * Union Action.
 *
 */

export type UserStoreAction =
  | UpdateUserStoreAction
  | UpdateUserStoreViewAction
  | LoginUserAction
  | LoginUserSucceededAction
  | LoginUserFailedAction
  | LogoutUserAction
  | UpdateUserSettingsAction;

export type UserStorePayload =
  | UpdateUserStorePayload
  | UpdateUserStoreViewPayload
  | LoginUserPayload
  | LoginUserSucceededPayload
  | LoginUserFailedPayload
  | UpdateUserSettingsPayload;
