//
// effect.appBar.js
// stockhouse
//
// Created by Thomas Schönmann on 22.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// AppBar's react effects.
//

import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {
    update as updateAction,
    updateShoppingCardIconVisibility as updateShoppingCardIconVisibilityAction
} from "../store/actions/action.appBar";
import {getConnectedActionHook, getConnectedEmptyActionHook, getConnectedStore} from "../store/util/util.hook";
import {AppBarStoreAction, AppBarStorePayload} from "../store/types/type.appBar";

/*
 *
 * Functions.
 *
 */

export const useConnectedAppBarStore = getConnectedStore("appBar");
export const useConnectedAppBarAction = getConnectedActionHook<AppBarStoreAction, AppBarStorePayload>();
export const useConnectedEmptyAppBarAction = getConnectedEmptyActionHook<AppBarStoreAction>();

/**
 *
 * @param title
 */
export function useConnectedAppBarTitle({ title }: { title: string }) {
  const dispatch = useDispatch();
  const updateAppBar = useCallback(({ title }) => dispatch(updateAction({ title })), [dispatch]);

  useEffect(() => {
    updateAppBar({ title });
    return () => {
      updateAppBar({ title: "" });
    };
  }, [title, updateAppBar]);
}

/**
 *
 */
export function useConnectedShoppingCardVisibility() {
  const updateIconVisibility = useConnectedAppBarAction(updateShoppingCardIconVisibilityAction);

  useEffect(() => {
    updateIconVisibility({ isVisible: true });
    return () => {
      updateIconVisibility({ isVisible: false });
    };
  }, [updateIconVisibility]);
}
