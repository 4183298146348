//
// SystemConnectedProgress.tsx
//
// Created by Thomas on 12.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { useMemo } from "react";
import { Fade, LinearProgress, LinearProgressProps, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useConnectedProgressStore } from "../../hooks/hook.progress";

/*
 *
 * Interfaces.
 *
 */

interface SystemProgressProps {
  isLoading?: boolean;
  value?: number;
  buffer?: number;
}

/*
 *
 * Components.
 *
 */

export function SystemProgress(props: SystemProgressProps) {
  const classes = useStyles();
  const { isLoading, value, buffer } = props;
  const variant = useProgressVariant({ value, buffer });

  return (
    <Fade in={isLoading} mountOnEnter unmountOnExit>
      <LinearProgress className={classes.progress} color="secondary" variant={variant} value={value} valueBuffer={buffer} />
    </Fade>
  );
}

export function SystemConnectedProgress() {
  const classes = useStyles();
  const { isLoading, value, buffer } = useConnectedProgressStore();
  const variant = useProgressVariant({ value, buffer });

  return (
    <Fade in={isLoading} mountOnEnter unmountOnExit>
      <LinearProgress className={classes.progress} color="secondary" variant={variant} value={value} valueBuffer={buffer} />
    </Fade>
  );
}

/*
 *
 * Functions.
 *
 */

function useProgressVariant(props: { value?: number; buffer?: number }) {
  const { value, buffer } = props;

  return useMemo<LinearProgressProps["variant"]>(() => {
    if (value !== undefined && buffer !== undefined) return "buffer";
    else if (value !== undefined) return "determinate";
    else return undefined;
  }, [value, buffer]);
}

/*
 *
 * MARK: Styles.
 *
 */

const useStyles = makeStyles(({ zIndex, spacing, palette }: Theme) => ({
  progress: {
    zIndex: zIndex.modal + 1
  },
  label: {
    fontSize: "0.8em",
    fontWeight: "bold",
    color: palette.text.secondary,
    borderRadius: spacing(1),
    padding: spacing(1),
    margin: spacing(1),
    backgroundColor: palette.secondary.main
  }
}));
