//
// type.notification.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {ProviderContext as NotiSnackProviderContext, VariantType} from "notistack";

/*
 *
 * Attributes.
 *
 */

export const INIT = "notification/init";
export const UPDATE = "notification/update";
export const ENQUEUE = "notification/enqueue";

/*
 *
 * State.
 *
 */

export interface NotificationStore {
  provider?: NotiSnackProviderContext;
}

export interface SHNotification {
  tLabel: string;
  variant: VariantType;
  onClose?: Function;
  isPersistent?: boolean;
}

/*
 *
 * Actions.
 *
 */

export interface InitAction {
  type: typeof INIT;
  payload: InitPayload;
}

export interface InitPayload {
  provider: NotiSnackProviderContext;
}

export interface UpdateAction {
  type: typeof UPDATE;
  payload: UpdatePayload;
}

export type UpdatePayload = Partial<NotificationStore>;

export interface EnqueueAction {
  type: typeof ENQUEUE;
  payload: EnqueuePayload;
}

export type EnqueuePayload = SHNotification;

/*
 *
 * Union Action.
 *
 */

export type NotificationStoreAction = InitAction | UpdateAction;

export type NotificationStorePayload = InitPayload | UpdatePayload;
