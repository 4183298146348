//
// InAppNotificationProvider.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, {PropsWithChildren, useState} from "react";
import {SnackbarProvider} from "notistack";
import {useConnectedNotificationInit} from "../../hooks/hook.notification";

/*
 *
 * Components.
 *
 */

export default (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [maxSnack] = useState(4);

  return (
    <SnackbarProvider maxSnack={maxSnack}>
      <NotificationCenter />
      {children}
    </SnackbarProvider>
  );
};

/**
 * Hook-only component, as 'useConnectedNotificationInit' can only be called
 * within the SnackbarProvider as child.
 *
 * @constructor
 */
const NotificationCenter = () => {
  useConnectedNotificationInit();
  return null;
};
