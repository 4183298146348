//
// index.tsx
//
// Created by Thomas on 10.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import { isDev } from "../utils/util.dev";

/*
 *
 * Attributes.
 *
 */

const sagaMiddleware = createSagaMiddleware();

const shell = {
  store: initStore(),
};

sagaMiddleware.run(sagas);

/*
 *
 * Functions.
 *
 */

function initStore() {
  const middleWares = [sagaMiddleware];

  if (isDev) {
    const { logger } = require(`redux-logger`);
    middleWares.push(logger);
  }

  return compose(applyMiddleware(...middleWares))(createStore)(reducers, {});
}

/*
 *
 * Export.
 *
 */

export { shell as default, shell };
