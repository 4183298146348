//
// Provider.theme.jsx
// stockhouse
//
// Created by Thomas Schönmann on 21.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// Theme-provider to enable switching.
//

import React, { PropsWithChildren } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useConnectedThemeByPreferredColorSchemeFromHTML } from "../../hooks/hook.theme";

/*
 *
 * Components.
 *
 */

export default function ThemeProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const { theme } = useConnectedThemeByPreferredColorSchemeFromHTML();

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
