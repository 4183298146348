//
// AppInit.tsx
//
// Created by Thomas on 17.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { Suspense } from "react";
import FullscreenLoader from "./FullscreenLoader";
import ThemeProvider from "../Provider/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import AppContent from "./AppContent";
import RootProvider from "../Provider/RootProvider";

/*
 *
 * Components.
 *
 */

/**
 * First component that inits the configs, then the app itself can be loaded.
 */
export default () => {
  /*
   * Only re-enable when using async loading of init-files.
   * Not required for now, if never required delete,
   * - Tom, 23-08-2020
  const [configState, setConfigState] = useState<"loadingConfigs" | "configsLoaded">("loadingConfigs");

  useEffect(() => {
    initConfigCache().then(() => setConfigState("configsLoaded"));
  }, []);

  if (configState === "loadingConfigs") {
    return <Loader />;
  }

   return (
    <Suspense fallback={<Loader />}>
      <AppContent />
    </Suspense>
  );
     */

  return (
    <RootProvider>
      <ThemeProvider>
        <CssBaseline />
        <Suspense fallback={<FullscreenLoader label="stockhou.se" />}>
          <AppContent />
        </Suspense>
      </ThemeProvider>
    </RootProvider>
  );
};

/**
 *
 * @constructor
 */
/*
const Loader = () => (
  <ThemeProvider>
    <CssBaseline />
    <FullscreenLoader label="stockhou.se" />
  </ThemeProvider>
);
 */
