//
// transactionDraft.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHTraderCompany, SHTraderCompanyLocation, SHTraderCore } from "../../../../shared/src/models/types/type.trader";
import {
  SHPresale,
  SHPresaleProductCore,
  SHPresaleProductCoreResolution,
  SHPresaleReferenceResolution,
} from "../../../../shared/src/models/types/type.presale";
import { SHUser } from "../../../../shared/src/models/types/type.user";
import { SHEntityRef } from "../../../../shared/src/models/types/type.db";
import { SHProductCore, SHProductProducerArticle, SHProductProducerCore } from "../../../../shared/src/models/types/type.product";

export const UPDATE_PRESALE_STORE = "presale/update";
export const UPDATE_SELECTED_PRESALE = "presale/update/selected-presale";
export const UPDATE_DRAFT_TRADER_COMPANY = "presale/update/draft/trader-company";
export const UPDATE_DRAFT_BILLING_ADDRESS = "presale/update/draft/billing-address";
export const UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES = "presale/update/active-presales-from-raw-presales";
export const UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE = "presale/update/select-product-for-presale-store";
export const UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE_PRICE = "presale/update/select-product-for-presale-store-price";

export const INIT_PRESALE_TRADER_DRAFT_DRAWER = "presale/init/draft/trader-billing-drawer";
export const INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER = "presale/init/select-presale-for-product-drawer";
export const CANCEL_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER = "presale/cancel/select-presale-for-product-drawer";
export const FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER = "presale/finish/select-presale-for-product-drawer";
export const REMOVE_PRODUCT_FROM_PRESALE = "presale/remove/product-from-presale";

export const INIT_PRESALE_UPSERT_DIALOG = "presale/init/upsert-dialog";
export const CLOSE_PRESALE_UPSERT_DIALOG = "presale/close/upsert-dialog";

export const APPLY_TRADER_BILLING_DRAFT = "presale/apply/trader-billing-draft";

export const UPSERT_PRESALE = "presale/upsert";
export const REMOVE_PRESALE = "presale/remove";
export const SELECT_PRESALE = "presale/select";
export const SELECT_PRESALE_BY_ID = "presale/select-by-id";

export const UPDATE_PRESALE_PRODUCT_SELECTION = "presale/update/product-selection";

export const RESET_PRESALE_STORE = "presale/reset";

/*
 *
 * MARK: Store.
 *
 */

export interface PresaleStore {
  state: PresaleStoreState;

  /** Main document in DB that holds all references. */
  selectedPresale?: SHPresale;
  assignee?: SHUser;

  /** Entities that are referenced with 'selectedPresale'. */
  traderCore?: SHTraderCore;
  traderCompany?: SHTraderCompany;
  traderBillingAddress?: SHTraderCompanyLocation;
  //products: SHPresaleProductCore[];

  /** Draft-only vars for editing. */
  draftTraderCompany?: SHTraderCompany;
  draftCompanyBillingLocation?: SHTraderCompanyLocation;

  isPresaleUpsertDialogOpen?: boolean;
  isPresaleUpsertDialogTraderVisible?: boolean;
  isAddTraderDrawerOpen?: boolean;
  isSelectPresaleForProductDrawerOpen?: boolean;

  /** Entities visible in overview, task board, etc. */
  activePresales: SHPresaleReferenceResolution[];
  selectProductForPresaleStore: {
    selectedPresale?: SHPresaleReferenceResolution;
    groupedProductsByProducerArticle: PresaleStoreGroupedProductsByProducerArticlesTuple[];
    groupedProductsPriceNetInputs: Record<SHEntityRef, number | undefined>;
    productGroupPriceNetInputs: Record<string, number | undefined>;
    placeholderProducerArticleGroupInput?: string;
  };
}

export type PresaleStoreState = "all-overview";

/**
 * Special field that groups product cores by producer + article.
 * Relevant for UI (Lists of Lists), but if not done initially but
 * computed in the view, we would introduce a (potentially) large
 * computational overhead.
 * */
export interface PresaleStoreGroupedProductsByProducerArticlesTuple {
  /** Arbitrary ID for simpler identification during price-updates */
  groupId: string;
  producer: SHProductProducerCore;
  article: SHProductProducerArticle;
  productCores: SHPresaleProductCoreResolution[];
}

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdatePresaleAction {
  type: typeof UPDATE_PRESALE_STORE;
  payload: UpdatePresalePayload;
}

export type UpdatePresalePayload = Partial<PresaleStore>;

export interface InitPresaleUpsertDialogAction {
  type: typeof INIT_PRESALE_UPSERT_DIALOG;
  payload: InitPresaleUpsertDialogPayload;
}

export interface InitPresaleUpsertDialogPayload {}

export interface ClosePresaleUpsertDialogAction {
  type: typeof CLOSE_PRESALE_UPSERT_DIALOG;
}

export interface UpdateSelectedPresaleAction {
  type: typeof UPDATE_SELECTED_PRESALE;
  payload: UpdateSelectedPresalePayload;
}

export type UpdateSelectedPresalePayload = Partial<SHPresale>;

export interface UpdatePresaleDraftTraderCompanyAction {
  type: typeof UPDATE_DRAFT_TRADER_COMPANY;
  payload: UpdatePresaleDraftTraderCompanyPayload;
}

export type UpdatePresaleDraftTraderCompanyPayload = Partial<PresaleStore["draftTraderCompany"]>;

export interface UpdatePresaleDraftBillingAddressAction {
  type: typeof UPDATE_DRAFT_BILLING_ADDRESS;
  payload: UpdatePresaleDraftBillingAddressPayload;
}

export type UpdatePresaleDraftBillingAddressPayload = Partial<PresaleStore["draftCompanyBillingLocation"]>;

export interface ApplyPresaleTraderBillingDraftAction {
  type: typeof APPLY_TRADER_BILLING_DRAFT;
}

export interface InitPresaleDraftTraderDrawerAction {
  type: typeof INIT_PRESALE_TRADER_DRAFT_DRAWER;
}

export interface UpsertPresaleAction {
  type: typeof UPSERT_PRESALE;
}

export interface ResetPresaleStoreAction {
  type: typeof RESET_PRESALE_STORE;
  payload: ResetPresaleStorePayload;
}

export interface ResetPresaleStorePayload {
  variant: "all" | "upsert-dialog";
}

export interface UpdateActivePresalesFromRawPresalesAction {
  type: typeof UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES;
  payload: UpdateActivePresalesFromRawPresalesPayload;
}

export interface UpdateActivePresalesFromRawPresalesPayload {
  presales: SHPresale[];
}

export interface RemovePresaleAction {
  type: typeof REMOVE_PRESALE;
}

export interface SelectPresaleAction {
  type: typeof SELECT_PRESALE;
  payload: SelectPresalePayload;
}

export interface SelectPresalePayload {
  presale: SHPresale;
  traderCompany?: SHTraderCompany;
  traderCore?: SHTraderCore;
  assignee?: SHUser;
}

export interface SelectPresaleByIdAction {
  type: typeof SELECT_PRESALE_BY_ID;
  payload: SelectPresaleByIdPayload;
}

export type SelectPresaleByIdPayload = Omit<SelectPresalePayload, "presale"> & Pick<SHPresale, "id">;

export interface InitSelectPresaleForProductAction {
  type: typeof INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER;
  payload: InitSelectPresaleForProductPayload;
}

export interface InitSelectPresaleForProductPayload {
  productCores: SHProductCore[];
}

export interface FinishSelectPresaleForProductAction {
  type: typeof FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER;
  payload: FinishSelectPresaleForProductPayload;
}

export interface FinishSelectPresaleForProductPayload {
  intent: "show-draft" | "close";
  onDone?: () => any;
}

export interface CancelSelectPresaleForProductDrawerAction {
  type: typeof CANCEL_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER;
}

export interface UpdateSelectProductForPresaleStoreAction {
  type: typeof UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE;
  payload: UpdateSelectProductForPresaleStorePayload;
}

export type UpdateSelectProductForPresaleStorePayload = Partial<PresaleStore["selectProductForPresaleStore"]>;

export interface RemoveProductFromPresaleAction {
  type: typeof REMOVE_PRODUCT_FROM_PRESALE;
  payload: RemoveProductFromPresalePayload;
}

export interface RemoveProductFromPresalePayload {
  presaleId: SHEntityRef;
  productCoreIds: SHEntityRef[];
  sellables?: SHPresaleProductCore[];
}

export interface UpdateSelectProductForPresaleStorePriceAction {
  type: typeof UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE_PRICE;
  payload: UpdateSelectProductForPresaleStorePricePayload;
}

export interface UpdateSelectProductForPresaleStorePricePayload {
  /** 'price' can be undefined if user removes all chars in input. */
  price?: number;
  groupId: string;
  /** Used to determine if a single product updates its price of the group gets updated. */
  productId?: SHEntityRef;
}

/*
 *
 * MARK: Union types.
 *
 */

export type PresaleStoreAction =
  | UpdatePresaleAction
  | InitPresaleUpsertDialogAction
  | ClosePresaleUpsertDialogAction
  | UpdateSelectedPresaleAction
  | UpdatePresaleDraftTraderCompanyAction
  | UpdatePresaleDraftBillingAddressAction
  | ApplyPresaleTraderBillingDraftAction
  | InitPresaleDraftTraderDrawerAction
  | UpsertPresaleAction
  | ResetPresaleStoreAction
  | UpdateActivePresalesFromRawPresalesAction
  | RemovePresaleAction
  | SelectPresaleAction
  | SelectPresaleByIdAction
  | InitSelectPresaleForProductAction
  | FinishSelectPresaleForProductAction
  | CancelSelectPresaleForProductDrawerAction
  | UpdateSelectProductForPresaleStoreAction
  | RemoveProductFromPresaleAction
  | UpdateSelectProductForPresaleStorePriceAction;

export type PresaleStorePayload =
  | UpdatePresalePayload
  | InitPresaleUpsertDialogPayload
  | UpdateSelectedPresalePayload
  | UpdatePresaleDraftTraderCompanyPayload
  | UpdatePresaleDraftBillingAddressPayload
  | UpdateActivePresalesFromRawPresalesPayload
  | ResetPresaleStorePayload
  | SelectPresalePayload
  | SelectPresaleByIdPayload
  | InitSelectPresaleForProductPayload
  | FinishSelectPresaleForProductPayload
  | UpdateSelectProductForPresaleStorePayload
  | RemoveProductFromPresalePayload
  | UpdateSelectProductForPresaleStorePricePayload;
