//
// action.saleDraft.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  APPLY_TRADER_BILLING_DRAFT,
  ApplyPresaleTraderBillingDraftAction,
  CANCEL_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  CancelSelectPresaleForProductDrawerAction,
  CLOSE_PRESALE_UPSERT_DIALOG,
  ClosePresaleUpsertDialogAction,
  FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  FinishSelectPresaleForProductAction,
  FinishSelectPresaleForProductPayload,
  INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  INIT_PRESALE_TRADER_DRAFT_DRAWER,
  INIT_PRESALE_UPSERT_DIALOG,
  InitPresaleDraftTraderDrawerAction,
  InitPresaleUpsertDialogAction,
  InitPresaleUpsertDialogPayload,
  InitSelectPresaleForProductAction,
  InitSelectPresaleForProductPayload,
  REMOVE_PRESALE,
  REMOVE_PRODUCT_FROM_PRESALE,
  RemovePresaleAction,
  RemoveProductFromPresaleAction,
  RemoveProductFromPresalePayload,
  RESET_PRESALE_STORE,
  ResetPresaleStoreAction,
  ResetPresaleStorePayload,
  SELECT_PRESALE,
  SELECT_PRESALE_BY_ID,
  SelectPresaleAction,
  SelectPresaleByIdAction,
  SelectPresaleByIdPayload,
  SelectPresalePayload,
  UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES,
  UPDATE_DRAFT_BILLING_ADDRESS,
  UPDATE_DRAFT_TRADER_COMPANY,
  UPDATE_PRESALE_STORE,
  UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE,
  UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE_PRICE,
  UPDATE_SELECTED_PRESALE,
  UpdateActivePresalesFromRawPresalesAction,
  UpdateActivePresalesFromRawPresalesPayload,
  UpdatePresaleAction,
  UpdatePresaleDraftBillingAddressAction,
  UpdatePresaleDraftBillingAddressPayload,
  UpdatePresaleDraftTraderCompanyAction,
  UpdatePresaleDraftTraderCompanyPayload,
  UpdatePresalePayload,
  UpdateSelectedPresaleAction,
  UpdateSelectedPresalePayload,
  UpdateSelectProductForPresaleStoreAction,
  UpdateSelectProductForPresaleStorePayload,
  UpdateSelectProductForPresaleStorePriceAction,
  UpdateSelectProductForPresaleStorePricePayload,
  UPSERT_PRESALE,
  UpsertPresaleAction,
} from "../types/type.presale";

/*
 *
 * Functions.
 *
 */

export const updatePresaleStoreAction = (payload: UpdatePresalePayload): UpdatePresaleAction => ({
  type: UPDATE_PRESALE_STORE,
  payload,
});

export const initPresaleUpsertDialogAction = (payload: InitPresaleUpsertDialogPayload): InitPresaleUpsertDialogAction => ({
  type: INIT_PRESALE_UPSERT_DIALOG,
  payload,
});

export const closePresaleUpsertDialogAction = (): ClosePresaleUpsertDialogAction => ({
  type: CLOSE_PRESALE_UPSERT_DIALOG,
});

export const updateSelectedPresaleAction = (payload: UpdateSelectedPresalePayload): UpdateSelectedPresaleAction => ({
  type: UPDATE_SELECTED_PRESALE,
  payload,
});

export const initPresaleDraftCompanyBillingAction = (): InitPresaleDraftTraderDrawerAction => ({
  type: INIT_PRESALE_TRADER_DRAFT_DRAWER,
});

export const updatePresaleDraftTraderCompany = (
  payload: UpdatePresaleDraftTraderCompanyPayload
): UpdatePresaleDraftTraderCompanyAction => ({
  type: UPDATE_DRAFT_TRADER_COMPANY,
  payload,
});

export const updatePresaleDraftBillingAddress = (
  payload: UpdatePresaleDraftBillingAddressPayload
): UpdatePresaleDraftBillingAddressAction => ({
  type: UPDATE_DRAFT_BILLING_ADDRESS,
  payload,
});

export const applyPresaleTraderBillingDraftAction = (): ApplyPresaleTraderBillingDraftAction => ({
  type: APPLY_TRADER_BILLING_DRAFT,
});

export const upsertPresaleAction = (): UpsertPresaleAction => ({
  type: UPSERT_PRESALE,
});

export const resetPresaleStoreAction = (payload: ResetPresaleStorePayload): ResetPresaleStoreAction => ({
  type: RESET_PRESALE_STORE,
  payload,
});

export const updateActivePresalesFromRawPresalesAction = (
  payload: UpdateActivePresalesFromRawPresalesPayload
): UpdateActivePresalesFromRawPresalesAction => ({
  type: UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES,
  payload,
});

export const removePresaleAction = (): RemovePresaleAction => ({
  type: REMOVE_PRESALE,
});

export const selectPresaleAction = (payload: SelectPresalePayload): SelectPresaleAction => ({
  type: SELECT_PRESALE,
  payload,
});

export const selectPresaleByIdAction = (payload: SelectPresaleByIdPayload): SelectPresaleByIdAction => ({
  type: SELECT_PRESALE_BY_ID,
  payload,
});

export const initSelectPresaleForProductAction = (
  payload: InitSelectPresaleForProductPayload
): InitSelectPresaleForProductAction => ({
  type: INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  payload,
});

export const finishSelectPresaleForProductAction = (
  payload: FinishSelectPresaleForProductPayload
): FinishSelectPresaleForProductAction => ({
  type: FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  payload,
});

export const cancelSelectPresaleForProductAction = (): CancelSelectPresaleForProductDrawerAction => ({
  type: CANCEL_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
});

export const updateSelectProductForPresaleStoreAction = (
  payload: UpdateSelectProductForPresaleStorePayload
): UpdateSelectProductForPresaleStoreAction => ({
  type: UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE,
  payload,
});

export const removeProductFromPresaleAction = (payload: RemoveProductFromPresalePayload): RemoveProductFromPresaleAction => ({
  type: REMOVE_PRODUCT_FROM_PRESALE,
  payload,
});

export const updateSelectProductForPresaleStorePriceAction = (
  payload: UpdateSelectProductForPresaleStorePricePayload
): UpdateSelectProductForPresaleStorePriceAction => ({
  type: UPDATE_SELECT_PRODUCT_FOR_PRESALE_STORE_PRICE,
  payload,
});
