//
// hook.userTasks.tsx
//
// Created by Thomas on 16.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {shallowEqual, useSelector} from "react-redux";
import {UserTasksStore, UserTasksStoreAction, UserTasksStorePayload} from "../store/types/type.userTasks";
import {SHStore} from "../store/reducers";
import {getConnectedActionHook} from "../store/util/util.hook";

/*
 *
 * Functions.
 *
 */

/**
 *
 */
export function useConnectedUserTasksStore(): UserTasksStore {
  return useSelector((s: SHStore) => s.userTasks, shallowEqual);
}

export const useConnectedUserTasksAction = getConnectedActionHook<UserTasksStoreAction, UserTasksStorePayload>();
