//
// AppBar.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { FC, lazy, memo, Suspense, useCallback, useEffect, useState } from "react";
import { AppBar as MaterialAppBar, IconButton, Slide, Toolbar } from "@material-ui/core";
import {
  FormatListText as FormatListTextIcon,
  TextBoxOutline as TextBoxOutlineIcon,
  WifiStrengthOffOutline as WifiStrengthOffOutlineIcon,
} from "mdi-material-ui";
import { useAppBarStyles, useCenterSectionStyles, useLeftSectionStyles, useRightSectionStyles } from "./styles";
import { useConnectedUserStore } from "../../hooks/hook.user";
import { useNetworkDetection } from "../../hooks/hook.app";
import { useConnectedAppBarAction, useConnectedAppBarStore } from "../../hooks/hook.appBar";
import RouteAssistant from "./RouteAssistant";
import { useConnectedUserTasksAction } from "../../hooks/hook.userTasks";
import AppBarOffset from "../../layout/Space/AppBarOffsetSpace";
import { updateUserTasksAction } from "../../store/actions/action.userTasks";
import { updateNavDrawer } from "../../store/actions/action.appBar";
import ConsoleInputField from "../Console/ConsoleInputField";
import { SystemConnectedProgress } from "../SystemProgress/SystemProgress";
import { UserStore } from "../../store/types/type.user";
import clsx from "clsx";

const AppNavigationDrawer = lazy(() => import("../AppNavigationDrawer/AppNavigationDrawer"));
const UserTasksDrawer = lazy(() => import("../TaskBoard/ConnectedTaskBoardDrawer"));
const NewLatestInfoVersionButton = lazy(() => import("./AppBarNewLatestInfoVersionButton"));

/*
 *
 * Components.
 *
 */

export default memo(() => {
  const { isNavDrawerOpen, isShoppingCardIconVisible } = useConnectedAppBarStore();
  const classes = useAppBarStyles();
  const userStore = useConnectedUserStore();
  const { isOnline } = useNetworkDetection();

  if (!userStore.auth) {
    return null;
  }

  return (
    <Slide in direction="down">
      <div className={classes.root}>
        <Suspense fallback="">
          <AppNavigationDrawer auth={userStore} open={isNavDrawerOpen} />
        </Suspense>
        <MaterialAppBar
          className={clsx(classes.bar, {
            [classes.barError]: !isOnline,
          })}
          position="fixed">
          <Toolbar>
            <LeftSection />
            <CenterSection />
            <RightSection user={userStore} isShoppingCardIconVisible={isShoppingCardIconVisible} />
          </Toolbar>
          <SystemConnectedProgress />
        </MaterialAppBar>
        {userStore.user && <AppBarOffset />}
        <Suspense fallback="">
          <UserTasksDrawer />
        </Suspense>
      </div>
    </Slide>
  );
});

/**
 *
 */
const LeftSection = memo(() => {
  const classes = useLeftSectionStyles();
  const updateDrawer = useConnectedAppBarAction(updateNavDrawer);

  const onOpenNav = useCallback(() => updateDrawer({ isOpen: true }), [updateDrawer]);

  return (
    <div className={classes.container}>
      <IconButton aria-label="Open navigation drawer" color="inherit" onClick={onOpenNav}>
        <FormatListTextIcon />
      </IconButton>
      <RouteAssistant />
    </div>
  );
});

/**
 *
 */
const CenterSection: FC = () => {
  const centerClasses = useCenterSectionStyles();
  return (
    <div className={centerClasses.container}>
      <ConsoleInputField />
    </div>
  );
};

/**
 *
 */
const RightSection: FC<{ user: UserStore; isShoppingCardIconVisible?: boolean }> = (props) => {
  const { user } = props;
  const classes = useRightSectionStyles();

  const { isSupported: isNetDetectSupported, isOnline } = useNetworkDetection();
  const [isNetDialogOpen, setIsNetDialogOpen] = useState(false);

  const onDialogClose = useCallback(() => setIsNetDialogOpen(false), []);
  const onOpenNetDialog = useCallback(() => setIsNetDialogOpen(true), []);

  const updateUserTasks = useConnectedUserTasksAction(updateUserTasksAction);

  return (
    <div className={classes.container}>
      <Slide in={isNetDetectSupported && !isOnline} direction="down" mountOnEnter unmountOnExit>
        <IconButton className={classes.offlineAvatar} color="inherit" onClick={onOpenNetDialog}>
          <WifiStrengthOffOutlineIcon />
        </IconButton>
      </Slide>
      <Suspense fallback="">
        <NewLatestInfoVersionButton />
      </Suspense>
      <IconButton color="inherit" onClick={() => updateUserTasks({ isDrawerOpen: true })}>
        <TextBoxOutlineIcon />
      </IconButton>
      {/*<OfflineInfoDialog isOpen={isNetDialogOpen} isAuth={isAuth} onClose={onDialogClose} />*/}
    </div>
  );
};
