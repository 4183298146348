//
// type.error.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {ProductUpsertFailedAction, ProductUpsertFailedPayload} from "./type.warehouse";
import {SHTranslationKeyAlias} from "../../../../shared/src/models/types/type.db";

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "ERROR_UPDATE";
export const RAISE_ERROR = "ERROR_RAISE_ERROR";

/*
 *
 * State.
 *
 */

export interface ErrorStore {
  errors: SHError[];
}

export interface SHError {
  origin: SHErrorOrigin;
  raw: Error;
  title?: SHTranslationKeyAlias;
  subTitle?: SHTranslationKeyAlias;
  desc?: SHTranslationKeyAlias;
}

export type SHErrorOrigin =
  | "aboutApp"
  | "appBar"
  | "console"
  | "error"
  | "integrations"
  | "shop"
  | "user"
  | "userTasks"
  | "warehouse";

/*
 *
 * Actions.
 *
 */

export interface UpdateErrorAction {
  type: typeof UPDATE;
  payload: UpdateErrorPayload;
}

export type UpdateErrorPayload = Partial<ErrorStore>;

/*
 *
 * Union Action.
 *
 */

export type ErrorStoreAction = UpdateErrorAction | ProductUpsertFailedAction;

export type ErrorStorePayload = UpdateErrorPayload | ProductUpsertFailedPayload;
