//
// FullscreenLoader.tsx
//
// Created by Thomas on 17.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { PropsWithChildren } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";

/*
 *
 * Components.
 *
 */

export default (props: PropsWithChildren<{ label?: string }>) => {
  const { children, label } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <>{children}</>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <LinearProgress className={classes.progress} />
    </div>
  );
};

/*
 *
 * Styles.
 *
 */

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    label: {
      fontWeight: "bold",
      fontSize: 18
    },
    progress: {
      margin: spacing(2),
      width: 200
    }
  })
);
