//
// type.root.ts
// stockhouse
//
// Created by Thomas Schönmann on 06.03.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

export const RESET = "ROOT_RESET";

/*
 *
 * Actions.
 *
 */

export interface ResetAction {
  type: typeof RESET;
}

/*
 *
 * Union action.
 *
 */

export type RootAction = ResetAction;
