//
// model.reservation.tsx
//
// Created by Thomas on 07.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { getEmptyEntity } from "./model.db";
import { EntityReservation } from "./types/type.reservation";

/*
 *
 * Functions.
 *
 */

export const getEmptyEntityReservation = getEmptyEntity<EntityReservation>({
  ref: "",
  target: "presale",
});
