//
// hook.db.tsx
// stockhouse
//
// Created by Thomas on 06.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { chainFrom } from "transducist";
import firebase from "firebase";
import {
  getClientDocumentsQueryRef,
  getClientSingleDocumentData,
  getCollectionRef,
  getSingleDocumentDataFromSnap,
} from "../models/model.db";
import { SHStore } from "../store/reducers";
import { SHDomain, SHEntity } from "../../../shared/src/models/types/type.db";
import { SHUserOrderBy } from "../../../shared/src/models/types/type.user";

/*
 *
 * Interfaces.
 *
 */

export interface QueryOrderProp<T> {
  field: keyof T;
  dir: "desc" | "asc";
}

/*
 *
 * Functions.
 *
 */

/**
 * Simple selector for full client in store.
 *
 * @export
 * @returns
 */
export function useConnectedClient() {
  return useSelector((store: SHStore) => store.user.user?.client, shallowEqual);
}

/**
 * Just a little helper to avoid null-checking.
 *
 * @export
 * @returns
 */
export function useConnectedClientId() {
  return useSelector((store: SHStore) => store.user.user?.client, shallowEqual);
}

/**
 * Get the correct collection-reference by domain for the current client.
 *
 * @export
 * @param {SHDomain} domain
 * @returns
 */
export function useCollectionRef(domain: SHDomain) {
  return useMemo(() => getCollectionRef(domain), [domain]);
}

/**
 * Get a firestore-query-reference to use, with the correct
 * client already defined by a first where-clause.
 *
 * @export
 * @param {SHDomain} domain
 * @returns
 */
export function useConnectedClientCollectionQuery(domain: SHDomain, orderBy?: any, order?: "desc" | "asc") {
  const client = useConnectedClientId();
  const [query, setQuery] = useState<firebase.firestore.Query | undefined>();

  useEffect(() => {
    if (client)
      setQuery(
        orderBy ? getCollectionRef(domain).orderBy(orderBy, order) : getCollectionRef(domain).where("client", "==", client)
      );
  }, [client, domain, orderBy, order]);

  return query;
}

/**
 * Fetch all documents from one client-collection.
 *
 * @export
 * @param {{ domain: SHDomain }} { domain }
 * @returns
 */
export function useConnectedClientNamedCollection<T extends SHEntity>({
  domain,
  orderField,
}: {
  domain: SHDomain;
  orderField?: QueryOrderProp<T>;
}) {
  const query = useConnectedClientCollectionQuery(domain);
  const [docs, setDocs] = useState<T[]>([]);

  useEffect(() => {
    if (!query) return;

    const update = (snap: firebase.firestore.QuerySnapshot) =>
      setDocs(snap.docs.filter((d) => d.exists).map((d) => d.data()) as T[]);

    orderField
      ? query
          .orderBy(orderField.field as string, orderField.dir)
          .get()
          .then(update)
      : query.get().then(update);
  }, [query, orderField]);

  return docs;
}

/**
 *
 *
 * @export
 * @template T
 * @param {{ domain: SHDomain }} { domain }
 * @returns
 */
export function useClientNamedCollectionListener<T extends SHEntity>({
  domain,
  orderBy,
  order,
}: {
  domain: SHDomain;
  orderBy?: SHUserOrderBy;
  order?: "asc" | "desc" | undefined;
}) {
  const ref = useConnectedClientCollectionQuery(domain, orderBy, order);
  const [docs, setDocs] = useState<T[]>([]);

  useEffect(() => {
    if (!ref) return;

    return ref.onSnapshot((snap) => {
      setDocs(snap.docs.filter((d) => d.exists).map((d) => d.data()) as T[]);
    });
  }, [ref, orderBy, order]);

  return docs;
}

/**
 * Provide a callback that accepts all docs' data from a query-listener.
 * @param domain
 * @param orderBy
 * @param order
 * @param cb
 */
export function useClientNamedCollectionListenerCallback<T extends SHEntity>({
  domain,
  orderBy,
  order,
  cb,
}: {
  domain: SHDomain;
  orderBy?: keyof T;
  order?: "asc" | "desc" | undefined;
  cb: (entities: T[]) => any;
}) {
  const ref = useConnectedClientCollectionQuery(domain, orderBy, order);

  useEffect(() => {
    if (!ref) return;
    return ref.onSnapshot((snap) => {
      cb(snap.docs.filter((d) => d.exists).map((d) => d.data()) as T[]);
    });

    // eslint-disable-next-line
  }, [ref, orderBy, order]);
}

/**
 * Listen for a single document for in a given client's space.
 *
 * @param domain
 * @param docId
 */
export function useClientNamedCollectionDocumentListener<T extends SHEntity>({
  domain,
  docId,
}: {
  domain: SHDomain;
  docId: string;
}) {
  const ref = useConnectedClientCollectionQuery(domain);
  const [doc, setDoc] = useState<T | undefined>();

  useEffect(() => {
    if (!ref) return;

    return ref.where(firebase.firestore.FieldPath.documentId(), "==", docId).onSnapshot((snap) => {
      if (snap.empty || snap.docs.length === 0) setDoc(undefined);
      else setDoc(snap.docs[0].data() as T | undefined);
    });
  }, [ref]);

  return doc;
}

/**
 * DANGEROUSLY!
 * Can lead to firestore-errors in case two range comparators are used
 * and each is referencing a different field - range ois only on one field allowed!
 *
 * Listen to a subset of documents for a given domain by providing a field name + value
 * to filter by.
 *
 * @param props
 */
export function useClientNamedCollectionDocumentsListenerByReferenceId<T extends SHEntity>(props: {
  domain: SHDomain;
  refName: keyof T;
  refValue: string;
  order?: QueryOrderProp<T>;
  mutate?: (t: T) => T;
  comparator?: "==" | "<" | ">" | ">=" | "<=" | "array-contains";
}) {
  // We can directly cache the props as they're not supposed to change during
  // the component's life.
  const [{ domain, refName, refValue, order, comparator = "==", mutate }] = useState(props);

  const ref = useConnectedClientCollectionQuery(domain);
  const [docs, setDocs] = useState<T[]>([]);

  useEffect(() => {
    if (!ref || refValue === "") {
      console.warn("[db][useClientNamedCollectionDocumentsListenerByReferenceId] got empty refValue");
      return;
    }

    return (order ? ref.orderBy(order.field as string, order.dir) : ref)
      .where(refName.toString(), comparator, refValue)
      .onSnapshot((snap) => {
        setDocs(
          chainFrom(snap.docs)
            .filter((d) => d.exists)
            .map((d) => {
              const data = d.data() as T;
              return mutate ? mutate(data) : data;
            })
            .toArray()
        );
      });
  }, [comparator, mutate, order, ref, refName, refValue]);

  return docs;
}

/**
 * Hook to fetch data from a single-document-collection by client.
 *
 * @export
 * @template T
 * @param {{ client: string; domain: SHDomain }} props
 * @returns
 */
export function useClientNamedSingleDocument<T extends SHEntity>(props: { client?: string; domain: SHDomain }) {
  const { client, domain } = props;
  const [data, setData] = useState<T>();

  useEffect(() => {
    if (client)
      getClientSingleDocumentData<T>({ client, domain })
        .then(setData)
        .catch((error) => console.error("[db][useClientNamedSingleDocument]", error));
  }, [client, domain]);

  return data;
}

/**
 * Listener for a single document in a single-document-collection.
 *
 * @export
 * @template T
 * @param {{
 *   client?: string;
 *   domain: SHDomain;
 * }} props
 * @returns
 */
export function useClientNamedSingleDocumentListener<T extends SHEntity>(props: { client?: string; domain: SHDomain }) {
  const { client, domain } = props;
  const [data, setData] = useState<T>();

  useEffect(() => {
    if (client)
      getClientDocumentsQueryRef({ domain, client }).onSnapshot((snap) => {
        setData(
          getSingleDocumentDataFromSnap<T>({ snap, domain, client })
        );
      });
  }, [client, domain]);

  return data;
}
