//
// reducer.integrations.ts
// stockhouse
//
// Created by Thomas Schönmann on 17.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {INIT_SUCCEEDED, IntegrationsStore, IntegrationsStoreAction, UPDATE} from "../types/type.integrations";

/*
 *
 * Attributes.
 *
 */

const store: IntegrationsStore = {
  integrations: []
};

/*
 *
 * Functions.
 *
 */

export default function(s = store, action: IntegrationsStoreAction): IntegrationsStore {
  switch (action.type) {
    case UPDATE:
      return { ...s, ...action.payload };

    case INIT_SUCCEEDED:
      return { ...s, integrations: action.payload.integrations };

    default:
      return s;
  }
}
