//
// model.routes.js
// stockhouse
//
// Created by Thomas Schönmann on 22.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Simple model to help for using routes.
//

import React, { lazy, Suspense } from "react";
import {
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  Security as SecurityIcon,
  SettingsApplications as SettingsApplicationIcon,
  ViewModule as ViewModuleIcon,
} from "@material-ui/icons";
import {
  AccountMultiple as AccountMultipleIcon,
  BookAccount as BookAccountIcon,
  FileDocumentEdit as FileDocumentEditIcon,
  FileDocumentEditOutline as FileDocumentEditOutlineIcon,
  Finance as FinanceIcon,
  FormTextarea as FormTextAreaIcon,
  InformationVariant as InformationVariantIcon,
  MapMarkerPath as MapMarkerPathIcon,
  Pail as PailIcon,
  PlaylistEdit as PlaylistEditIcon,
  ToyBrickOutline as ToyBrickOutlineIcon,
  TransitConnectionVariant as TransitConnectionVariantIcon,
  Warehouse as WarehouseIcon,
  SortVariantRemove as SortVariantRemoveIcon,
  PlusBox as PlusBoxIcon,
} from "mdi-material-ui";
import InitLoading from "../views/App/LoginUserLoader";
import { Route } from "react-router-dom";
import PrivateRoute from "../views/Route/PrivateRoute";
import { LazyRouteComponent, NonLazyRouteComponent, SHRoute, SHUrlPath } from "./types/type.route";
import { SHUserRole } from "../../../shared/src/models/types/type.user";

const SignIn = lazy(() => import("../views/SignIn/SignIn"));
const SignUp = lazy(() => import("../views/SignUp/SignUp"));
const Dashboard = lazy(() => import("../views/Dashboard/Dashboard"));
const Integration = lazy(() => import("../views/Integration/Integration"));
const UserProfile = lazy(() => import("../views/UserProfile/FullUserProfile"));
const ProductInputSuggestionsManagement = lazy(() =>
  import("../views/ProductInputSuggestionsManagement/ProductInputSuggestionsManagement")
);
const Imprint = lazy(() => import("../views/Imprint/Imprint"));
const DataProtectionPolicy = lazy(() => import("../views/Policy/PrivacyPolicy"));
const UserManagement = lazy(() => import("../views/UserManagement/UserManagement"));
const AboutApp = lazy(() => import("../views/AboutApp/AboutApp"));
const DocumentTemplateManagement = lazy(() => import("../views/DocumentTemplateManagement/DocumentTemplateManagement"));
const ShopSectionMapper = lazy(() => import("../views/ShopSectionMapper/ShopSectionMapper"));
const Warehouse = lazy(() => import("../views/Warehouse/Warehouse"));
const NewProduct = lazy(() => import("../views/NewProduct/NewProduct"));
const Sale = lazy(() => import("../views/Sale/SaleSearchList"));
const PreSale = lazy(() => import("../views/PreSale/PreSaleSearchList"));
const PostSale = lazy(() => import("../views/PostSale/PostSaleGlobe"));
const FullPageError = lazy(() => import("../views/Error/FullPageError"));
const Register = lazy(() => import("../views/RegisterUser/RegisterUser"));
const AdminSchemas = lazy(() => import("../views/AdminSchemas/AdminSchemas"));
const AdminClientSettings = lazy(() => import("../views/AdminClientSettings/AdminClientSettings"));
const CustomerCoreSearchList = lazy(() => import("../views/Trader/ConnectedTraderSearchList"));
const DisposalDraftSearchList = lazy(() => import("../views/Disposal/ConnectedDisposalSearchList"));
const DisposalProductCoresSearchList = lazy(() =>
  import("../views/DisposalProductCores/ConnectedDisposalProductCoresSearchList")
);

/*
 *
 * Attributes.
 *
 */

export const SHLazyRouteDict: Record<SHUrlPath, SHRoute<LazyRouteComponent>> = {
  //
  // TODO: "initAction app" is only a mock for now, implement better structure.
  //
  [SHUrlPath.InitApp]: {
    path: SHUrlPath.Dashboard,
    requiredRole: SHUserRole.STAFF,
    Component: Dashboard,
    group: "dashboard",
    visual: {
      label: "Dashboard",
      Icon: ViewModuleIcon,
    },
  },
  [SHUrlPath.Warehouse]: {
    path: SHUrlPath.Warehouse,
    requiredRole: SHUserRole.STAFF,
    Component: Warehouse,
    group: "product",
    visual: {
      label: "Warehouse",
      Icon: WarehouseIcon,
    },
  },
  [SHUrlPath.NewProduct]: {
    path: SHUrlPath.NewProduct,
    requiredRole: SHUserRole.STAFF,
    Component: NewProduct,
    group: "product",
    visual: {
      label: "New Product",
      Icon: PlusBoxIcon,
    },
  },
  [SHUrlPath.SignIn]: {
    path: SHUrlPath.SignIn,
    Component: SignIn,
    visual: {
      label: "Sign in",
      Icon: AccountCircleIcon,
    },
  },
  [SHUrlPath.SignUp]: {
    path: SHUrlPath.SignUp,
    Component: SignUp,
    visual: {
      label: "Sign up",
      Icon: AccountCircleIcon,
    },
  },
  [SHUrlPath.Integrations]: {
    path: SHUrlPath.Integrations,
    requiredRole: SHUserRole.ADMIN,
    Component: Integration,
    group: "admin",
    visual: {
      label: "Integrations",
      Icon: ToyBrickOutlineIcon,
    },
  },
  [SHUrlPath.Dashboard]: {
    path: SHUrlPath.Dashboard,
    requiredRole: SHUserRole.STAFF,
    Component: Dashboard,
    group: "dashboard",
    visual: {
      label: "Dashboard",
      Icon: ViewModuleIcon,
    },
  },
  [SHUrlPath.Profile]: {
    path: SHUrlPath.Profile,
    visual: {
      label: "Account",
      Icon: AccountCircleIcon,
    },
    Component: UserProfile,
    group: "user",
  },
  [SHUrlPath.Imprint]: {
    path: SHUrlPath.Imprint,
    Component: Imprint,
    group: "about",
    visual: {
      label: "Imprint",
      Icon: BusinessIcon,
    },
  },
  [SHUrlPath.PrivacyPolicy]: {
    path: SHUrlPath.PrivacyPolicy,
    Component: DataProtectionPolicy,
    group: "about",
    visual: {
      label: "Privacy Policy",
      Icon: SecurityIcon,
    },
  },
  [SHUrlPath.Presale]: {
    path: SHUrlPath.Presale,
    requiredRole: SHUserRole.STAFF,
    Component: PreSale,
    group: "sale",
    visual: {
      label: "Sale drafts",
      Icon: FileDocumentEditIcon,
    },
  },
  [SHUrlPath.Sale]: {
    path: SHUrlPath.Sale,
    requiredRole: SHUserRole.STAFF,
    Component: Sale,
    group: "sale",
    visual: {
      label: "Finished sales",
      Icon: FinanceIcon,
    },
  },
  [SHUrlPath.Postsale]: {
    path: SHUrlPath.Postsale,
    Component: PostSale,
    group: "sale",
    visual: {
      label: "Deliveries",
      Icon: MapMarkerPathIcon,
    },
  },
  [SHUrlPath.InputSuggestions]: {
    path: SHUrlPath.InputSuggestions,
    requiredRole: SHUserRole.STAFF,
    Component: ProductInputSuggestionsManagement,
    group: "product",
    visual: {
      label: "Input Suggestions",
      Icon: PlaylistEditIcon,
    },
  },
  [SHUrlPath.ListUsers]: {
    path: SHUrlPath.ListUsers,
    requiredRole: SHUserRole.ADMIN,
    Component: UserManagement,
    group: "admin",
    visual: {
      label: "User Management",
      Icon: AccountMultipleIcon,
    },
  },
  [SHUrlPath.AdminSchemas]: {
    path: SHUrlPath.AdminSchemas,
    Component: AdminSchemas,
    group: "admin",
    visual: {
      label: "Schema Management",
      Icon: FormTextAreaIcon,
    },
  },
  [SHUrlPath.ClientSettings]: {
    path: SHUrlPath.ClientSettings,
    Component: AdminClientSettings,
    group: "admin",
    visual: {
      label: "Client Settings",
      Icon: SettingsApplicationIcon,
    },
  },
  [SHUrlPath.AboutApp]: {
    path: SHUrlPath.AboutApp,
    requiredRole: SHUserRole.STAFF,
    Component: AboutApp,
    group: "about",
    visual: {
      label: "About",
      Icon: InformationVariantIcon,
    },
  },
  [SHUrlPath.DocumentTemplateManagement]: {
    path: SHUrlPath.DocumentTemplateManagement,
    requiredRole: SHUserRole.ADMIN,
    visual: {
      label: "Document Templates",
      Icon: FileDocumentEditOutlineIcon,
    },
    Component: DocumentTemplateManagement,
    group: "sale",
  },
  [SHUrlPath.CatalogSectionMappings]: {
    path: SHUrlPath.CatalogSectionMappings,
    requiredRole: SHUserRole.STAFF,
    Component: ShopSectionMapper,
    group: "shop",
    visual: {
      label: "Sections Mapping",
      Icon: TransitConnectionVariantIcon,
    },
  },
  [SHUrlPath.Error]: {
    path: SHUrlPath.Error,
    Component: FullPageError,
  },
  [SHUrlPath.Register]: {
    path: SHUrlPath.Register,
    Component: Register,
  },
  [SHUrlPath.TraderCoresSearchList]: {
    path: SHUrlPath.TraderCoresSearchList,
    Component: CustomerCoreSearchList,
    group: "sale",
    visual: {
      label: "Contacts",
      Icon: BookAccountIcon,
    },
  },
  [SHUrlPath.ProductDisposal]: {
    path: SHUrlPath.ProductDisposal,
    Component: DisposalDraftSearchList,
    group: "product",
    visual: {
      label: "Disposal",
      Icon: PailIcon,
    },
  },
  [SHUrlPath.DisposalProductCores]: {
    path: SHUrlPath.DisposalProductCores,
    Component: DisposalProductCoresSearchList,
    group: "product",
    visual: {
      label: "Disposed products",
      Icon: SortVariantRemoveIcon,
    },
  },
};

export const nonLazyPublicRoutes: Array<SHRoute<NonLazyRouteComponent>> = [
  {
    path: SHUrlPath.InitApp,
    Component: InitLoading,
  },
];

export const lazyRoutes: Array<SHRoute<LazyRouteComponent>> = [
  SHLazyRouteDict["/warehouse"],
  SHLazyRouteDict["/new-product"],
  SHLazyRouteDict["/pre-sale"],
  SHLazyRouteDict["/sale"],
  SHLazyRouteDict["/post-sale"],
  SHLazyRouteDict["/about-app"],
  SHLazyRouteDict["/dashboard"],
  SHLazyRouteDict["/input-suggestions"],
  SHLazyRouteDict["/imprint"],
  SHLazyRouteDict["/catalog-section-mappings"],
  SHLazyRouteDict["/sign-in"],
  SHLazyRouteDict["/sign-up"],
  SHLazyRouteDict["/integrations"],
  SHLazyRouteDict["/privacy-policy"],
  SHLazyRouteDict["/profile"],
  SHLazyRouteDict["/edit-templates"],
  SHLazyRouteDict["/list-users"],
  SHLazyRouteDict["/register/:token"],
  SHLazyRouteDict["/admin-schemas"],
  SHLazyRouteDict["/client-settings"],
  SHLazyRouteDict["/traders"],
  SHLazyRouteDict["/disposal"],
  SHLazyRouteDict["/disposal-product-cores"],
];

export const lazyRouteStockRecommendationDict: Record<SHUrlPath, Array<SHRoute<LazyRouteComponent>>> = {
  [SHUrlPath.Sale]: [SHLazyRouteDict["/pre-sale"], SHLazyRouteDict["/traders"]],
  [SHUrlPath.Presale]: [SHLazyRouteDict["/sale"], SHLazyRouteDict["/traders"], SHLazyRouteDict["/warehouse"]],
  [SHUrlPath.Postsale]: [SHLazyRouteDict["/pre-sale"], SHLazyRouteDict["/sale"], SHLazyRouteDict["/traders"]],
  [SHUrlPath.SignIn]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.SignUp]: [SHLazyRouteDict["/sign-up"]],
  [SHUrlPath.ListUsers]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.DocumentTemplateManagement]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.Warehouse]: [SHLazyRouteDict["/dashboard"], SHLazyRouteDict["/sale"], SHLazyRouteDict["/pre-sale"]],
  [SHUrlPath.NewProduct]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.Profile]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.PrivacyPolicy]: [SHLazyRouteDict["/imprint"]],
  [SHUrlPath.Integrations]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.CatalogSectionMappings]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.Imprint]: [SHLazyRouteDict["/about-app"]],
  [SHUrlPath.InputSuggestions]: [SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.AboutApp]: [SHLazyRouteDict["/profile"]],
  [SHUrlPath.Dashboard]: [SHLazyRouteDict["/warehouse"], SHLazyRouteDict["/sale"], SHLazyRouteDict["/pre-sale"]],
  [SHUrlPath.InitApp]: [],
  [SHUrlPath.Error]: [],
  [SHUrlPath.Register]: [],
  [SHUrlPath.AdminSchemas]: [SHLazyRouteDict["/dashboard"], SHLazyRouteDict["/sale"], SHLazyRouteDict["/warehouse"]],
  [SHUrlPath.ClientSettings]: [SHLazyRouteDict["/admin-schemas"], SHLazyRouteDict["/about-app"], SHLazyRouteDict["/profile"]],
  [SHUrlPath.TraderCoresSearchList]: [SHLazyRouteDict["/sale"], SHLazyRouteDict["/pre-sale"], SHLazyRouteDict["/warehouse"]],
  [SHUrlPath.ProductDisposal]: [SHLazyRouteDict["/warehouse"], SHLazyRouteDict["/pre-sale"], SHLazyRouteDict["/dashboard"]],
  [SHUrlPath.DisposalProductCores]: [SHLazyRouteDict["/disposal"], SHLazyRouteDict["/warehouse"], SHLazyRouteDict["/pre-sale"]],
};

/*
 *
 * Functions.
 *
 */

/**
 * Return a list of recommended routes to go next for a given path. A recommendation can be
 * a list of default entries or enhanced by usage analytics etc. Recommendation can also be
 * an empty list.
 *
 * @param path    SHUrlPath to provide.
 */
export function getNextLazyRouteRecommendations(path: SHUrlPath): Array<SHRoute<LazyRouteComponent>> {
  return lazyRouteStockRecommendationDict[path];
}

/**
 *
 */
export function getPublicRoutesWithoutSuspense() {
  return nonLazyPublicRoutes.map((route, i) => (
    <Route key={`non-lazy-public-${i}`} path={route.path} render={(props) => <route.Component {...props} />} />
  ));
}

/**
 *
 */
export function getRoutesInSuspense() {
  return lazyRoutes.map((route) =>
    route.requiredRole ? (
      <PrivateRoute key={`lazy-${route.path}`} route={route} path={route.path} component={route.Component} />
    ) : (
      <Route
        key={route.path}
        path={route.path}
        render={(props) => (
          <div>
            <Suspense fallback="">
              <route.Component {...props} />
            </Suspense>
          </div>
        )}
      />
    )
  );
}
