//
// reducer.console.tsx
//
// Created by Thomas on 10.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { ConsoleStore, ConsoleStoreAction } from "../types/type.console";

/*
 *
 * Attributes.
 *
 */

const store: ConsoleStore = {
  input: "",
  isVisible: false,
};

/*
 *
 * Functions.
 *
 */

export default function (s = store, a: ConsoleStoreAction): ConsoleStore {
  switch (a.type) {
    case "console/update":
      return { ...s, ...a.payload };

    case "console/clear/input":
      return { ...s, input: "" };

    default:
      return s;
  }
}
