import { call, put, takeEvery } from "redux-saga/effects";

import { ChangeUserAction, USER_MANAGEMENT_CHANGE_USER_REQ } from "../types/type.manageUsers";
import { getCollectionRef } from "../../models/model.db";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import { changeUserSucceeded } from "../actions/action.manageUsers";
import { SHUser } from "../../../../shared/src/models/types/type.user";

export default function* () {
  yield takeEvery(USER_MANAGEMENT_CHANGE_USER_REQ, changeCurrentUserSaga);
}

function* changeCurrentUserSaga(action: ChangeUserAction) {
  const uid: string = action.payload.uid;
  const user: SHUser = yield call(loadUser, SHDomain.Users, uid);

  yield put(changeUserSucceeded({ isLoading: false, user }));
}

async function loadUser(domain: SHDomain, uid: string) {
  return (await getCollectionRef(domain)
    .doc(uid)
    .get()).data() as SHUser;
}
