//
// StoreProvider.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, {PropsWithChildren} from "react";
import {Provider} from "react-redux";
import {shell} from "../../store";

/*
 *
 * Components.
 *
 */

export default function ({ children }: PropsWithChildren<{}>) {
  return <Provider store={shell.store}>{children}</Provider>;
}
