//
// reducer.media.tsx
//
// Created by Thomas on 22.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { MediaStore, MediaStoreAction, RESET_MEDIA_STORE, UPDATE_MEDIA_STORE } from "../types/type.media";

const store = getDefaultStore();

/*
 *
 * MARK: Reducer.
 *
 */

export default function(s = store, a: MediaStoreAction): MediaStore {
  switch (a.type) {
    case UPDATE_MEDIA_STORE:
      return { ...s, ...a.payload };

    case RESET_MEDIA_STORE:
      return getDefaultStore();

    default:
      return s;
  }
}

/*
 *
 * MARK: Functions.
 *
 */

function getDefaultStore(): MediaStore {
  return {};
}
