//
// App.jsx
// stockhouse
//
// Created by Thomas Schönmann on 11.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// Root component.
//

import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import AppBar from "../AppBar/AppBar";
import { useConnectedUserAuthListener } from "../../hooks/hook.user";
import { getPublicRoutesWithoutSuspense, getRoutesInSuspense } from "../../models/model.route";
import NoMatchRoute from "../Route/NoMatchRoute";
import { SHUrlPath } from "../../models/types/type.route";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useConnectedVisibilityDetection } from "../../hooks/hook.app";
import { useI18nLocale } from "../../hooks/hook.i18n";
import { useClientNamedCollectionListenerCallback } from "../../hooks/hook.db";
import { useConnectedPresaleStoreAction } from "../../hooks/hook.presale";
import { updateActivePresalesFromRawPresalesAction } from "../../store/actions/action.presale";
import { SHDomain } from "../../../../shared/src/models/types/type.db";
import { SHPresale } from "../../../../shared/src/models/types/type.presale";

const ConsoleResultBackdrop = lazy(() => import("../Console/ConsoleResultBackdrop"));
const ConnectedModals = lazy(() => import("./ConnectedModals"));

/*
 *
 * Components.
 *
 */

export default () => {
  return <Content />;
};

/**
 *
 * @constructor
 */
const Content = () => {
  useConnectedUserAuthListener();
  useConnectedVisibilityDetection();
  useConnectedActivePresalesListener();

  const locale = useI18nLocale();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <CssBaseline />
      <AppBar />
      <main>
        <Switch>
          <Redirect exact from="/" to={SHUrlPath.InitApp} />
          {getPublicRoutesWithoutSuspense()}
          {getRoutesInSuspense()}
          <NoMatchRoute />
        </Switch>
        <Suspense fallback="">
          <ConsoleResultBackdrop />
        </Suspense>
      </main>
      <SuspendedContentModals />
    </MuiPickersUtilsProvider>
  );
};

/**
 * Used for all views loaded via suspense
 * that are outside of main.
 *
 * @constructor
 */
const SuspendedContentModals = () => (
  <>
    <Suspense fallback="">
      {/*<NotificationCenter/>*/}
      <ConnectedModals />
    </Suspense>
  </>
);

/*
 *
 * Functions.
 *
 */

function useConnectedActivePresalesListener() {
  const updateActivePresales = useConnectedPresaleStoreAction(updateActivePresalesFromRawPresalesAction);
  useClientNamedCollectionListenerCallback<SHPresale>({
    domain: SHDomain.PreSales,
    orderBy: "continuousId",
    order: "desc",
    cb: (presales) => updateActivePresales({ presales }),
  });
}
