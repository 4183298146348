//
// type.disposal.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHProductDisposal, SHProductDisposalResolution } from "../../../../shared/src/models/types/type.product";

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "disposal/update";
export const UPDATE_DIALOG = "disposal/update-dialog";
export const UPDATE_DIALOG_DISPOSAL_DRAFT = "disposal/update-dialog-disposal-draft";

export const UPSERT_DISPOSAL_DRAFT = "disposal/upsert-draft";

export const APPLY_COMPANY_SELECTION = "disposal/apply-company-selection";

export const REMOVE_DISPOSAL = "disposal/remove";

export const OPEN_DISPOSAL_DIALOG = "disposal/open-dialog";
export const CLOSE_DISPOSAL_DIALOG = "disposal/close-dialog";

/*
 *
 * MARK: Store.
 *
 */

export interface DisposalStore {
  selectedDisposal?: SHProductDisposal;

  dialog: {
    draft?: SHProductDisposalResolution;
    isTraderViewVisible?: boolean;
  };

  isDisposalDialogOpen?: boolean;
  isDisposalDialogCompanyVisible?: boolean;
  isDisposalTraderSelectionDrawerOpen?: boolean;
}

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateDisposalStoreAction {
  type: typeof UPDATE;
  payload: UpdateDisposalStorePayload;
}

export type UpdateDisposalStorePayload = Partial<DisposalStore>;

export interface OpenDisposalDialogAction {
  type: typeof OPEN_DISPOSAL_DIALOG;
  payload: OpenDisposalDialogPayload;
}

export interface OpenDisposalDialogPayload {
  disposal?: SHProductDisposal;
}

export interface CloseDisposalDialogAction {
  type: typeof CLOSE_DISPOSAL_DIALOG;
}

export interface RemoveDisposalAction {
  type: typeof REMOVE_DISPOSAL;
  payload: RemoveDisposalPayload;
}

export interface RemoveDisposalPayload {
  disposal: SHProductDisposal;
}

export interface UpdateDisposalDialogAction {
  type: typeof UPDATE_DIALOG;
  payload: UpdateDisposalDialogPayload;
}

export type UpdateDisposalDialogPayload = Partial<DisposalStore["dialog"]>;

export interface UpdateDisposalDialogDraftAction {
  type: typeof UPDATE_DIALOG_DISPOSAL_DRAFT;
  payload: UpdateDisposalDialogDraftPayload;
}

export type UpdateDisposalDialogDraftPayload = Partial<DisposalStore["dialog"]["draft"]>;

export interface ApplyDisposalCompanySelectionAction {
  type: typeof APPLY_COMPANY_SELECTION;
  payload: ApplyDisposalCompanySelectionPayload;
}

export type ApplyDisposalCompanySelectionPayload = Pick<SHProductDisposalResolution, "traderCompany" | "traderLocation">;

export interface UpsertDisposalDraftAction {
  type: typeof UPSERT_DISPOSAL_DRAFT;
}

/*
 *
 * MARK: Unions.
 *
 */

export type DisposalAction =
  | UpdateDisposalStoreAction
  | OpenDisposalDialogAction
  | CloseDisposalDialogAction
  | RemoveDisposalAction
  | UpdateDisposalDialogAction
  | UpdateDisposalDialogDraftAction
  | ApplyDisposalCompanySelectionAction
  | UpsertDisposalDraftAction;

export type DisposalPayload =
  | UpdateDisposalStorePayload
  | OpenDisposalDialogPayload
  | RemoveDisposalPayload
  | UpdateDisposalDialogPayload
  | UpdateDisposalDialogDraftPayload
  | ApplyDisposalCompanySelectionPayload;
