//
// util.dev.tsx
//
// Created by Thomas on 14.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * MARK: Attributes.
 *
 */

export const isDev = process.env.NODE_ENV === "development";
export const isProduction = process.env.NODE_ENV === "production";
export const isCLI = process.env.REACT_APP_CLI_ENV === "true";
