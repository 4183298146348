//
// model.user.tsx
//
// Created by Thomas on 23.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {getEmptyEntity} from "./model.db";
import {SHUserSettings} from "./types/type.user";

/*
 *
 * Functions.
 *
 */

export const getEmptyUserSettings = getEmptyEntity<SHUserSettings>({
  id: "",
  client: "",
  createdAt: Date.now(),
});
