//
// saga.integrations.ts
// stockhouse
//
// Created by Thomas Schönmann on 17.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { call, put, takeEvery } from "redux-saga/effects";

import { INIT_REQ, InitAction } from "../types/type.integrations";
import { initFailed, initSucceeded } from "../actions/action.integrations";
import { getClientEntities } from "../../models/model.db";
import { SHDomain, SHIntegration } from "../../../../shared/src/models/types/type.db";

/*
 *
 * Functions.
 *
 */

export default function* () {
  yield takeEvery(INIT_REQ, initSaga);
}

function* initSaga(action: InitAction) {
  try {
    const { client } = action.payload;

    const fetch = () => getClientEntities<SHIntegration>({ domain: SHDomain.Integrations, client });
    const integrations: SHIntegration[] = yield call(fetch);

    console.log("fetched integrations", integrations);
    yield put(initSucceeded({ integrations }));
  } catch (error) {
    yield put(initFailed({ error }));
  }
}
