//
// AppBarOffsetSpace.tsx
//
// Created by Thomas on 21.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

/*
 *
 * Components.
 *
 */

export default () => {
  return <div className={useStyles().offset} />;
};

/*
 *
 * Functions.
 *
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar
  })
);
