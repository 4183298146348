//
// reducer.transactionDraft.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  PresaleStore,
  PresaleStoreAction,
  PresaleStorePayload,
  ResetPresaleStorePayload,
  UpdatePresaleDraftTraderCompanyPayload,
  UpdateSelectProductForPresaleStorePricePayload,
} from "../types/type.presale";
import { ReducerCaseFn } from "../util/util.type.reducer";

/*
 *
 * Interfaces.
 *
 */

type ReducerCase<P extends PresaleStorePayload> = ReducerCaseFn<"presale", P>;

/*
 *
 * MARK: Store.
 *
 */

const store = getDefaultStore();

/*
 *
 * MARK: Reducer.
 *
 */

export default function (s = store, a: PresaleStoreAction): PresaleStore {
  switch (a.type) {
    case "presale/update":
      return { ...s, ...a.payload };
    case "presale/update/selected-presale":
      return { ...s, selectedPresale: { ...s.selectedPresale!, ...a.payload } };
    case "presale/update/draft/trader-company":
      return updateTraderCompanyDraft(s, a.payload);
    case "presale/update/draft/billing-address":
      return { ...s, draftCompanyBillingLocation: { ...s.draftCompanyBillingLocation!, ...a.payload } };
    case "presale/update/select-product-for-presale-store":
      return { ...s, selectProductForPresaleStore: { ...s.selectProductForPresaleStore, ...a.payload } };

    case "presale/apply/trader-billing-draft":
      return applyTraderBillingDraft(s, {});
    case "presale/close/upsert-dialog":
      return getPartialDefaultStoreAfterUpsert(s);
    case "presale/reset":
      return resetPresaleStore(s, a.payload);

    case "presale/init/select-presale-for-product-drawer":
      return { ...s, isSelectPresaleForProductDrawerOpen: true };
    case "presale/cancel/select-presale-for-product-drawer":
      return {
        ...s,
        isSelectPresaleForProductDrawerOpen: undefined,
        selectProductForPresaleStore: getDefaultSelectProductForPresaleStore(),
      };
    case "presale/update/select-product-for-presale-store-price":
      return selectProductForPresaleStorePrice(s, a.payload);

    // Processed in saga.
    case "presale/upsert":
    case "presale/init/upsert-dialog":
    case "presale/init/draft/trader-billing-drawer":
    case "presale/update/active-presales-from-raw-presales":
    case "presale/remove":
    case "presale/select":
    case "presale/finish/select-presale-for-product-drawer":
    case "presale/select-by-id":
    case "presale/remove/product-from-presale":
    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

function getDefaultStore(): PresaleStore {
  return {
    state: "all-overview",
    activePresales: [],
    selectProductForPresaleStore: getDefaultSelectProductForPresaleStore(),
  };
}

/**
 *
 */
function getDefaultSelectProductForPresaleStore(): PresaleStore["selectProductForPresaleStore"] {
  return {
    groupedProductsByProducerArticle: [],
    groupedProductsPriceNetInputs: {},
    productGroupPriceNetInputs: {},
  };
}

/**
 *
 * @param s
 * @param p
 */
const resetPresaleStore: ReducerCase<ResetPresaleStorePayload> = (s, p) => {
  switch (p.variant) {
    case "all":
      return getDefaultStore();
    case "upsert-dialog":
      return getPartialDefaultStoreAfterUpsert(s);
  }
};

/**
 *
 * @param s
 */
function getPartialDefaultStoreAfterUpsert(s: PresaleStore): PresaleStore {
  return {
    ...s,
    state: "all-overview",
    draftCompanyBillingLocation: undefined,
    draftTraderCompany: undefined,
    isPresaleUpsertDialogOpen: false,
    isAddTraderDrawerOpen: false,
    isPresaleUpsertDialogTraderVisible: true,
    assignee: undefined,
    selectedPresale: undefined,
    traderBillingAddress: undefined,
    traderCompany: undefined,
    traderCore: undefined,
  };
}

/**
 *
 * @param s
 * @param p
 */
const updateTraderCompanyDraft: ReducerCase<UpdatePresaleDraftTraderCompanyPayload> = (s, p) => {
  return {
    ...s,
    isPresaleUpsertDialogTraderVisible: true,
    draftTraderCompany: { ...s.draftTraderCompany!, ...p },
    draftCompanyBillingLocation: p?.locations
      ? p.locations.find((l) => l.variant === "billing-address") || p.locations[0]
      : s.draftCompanyBillingLocation,
  };
};

/**
 *
 * @param s
 * @param p
 */
const applyTraderBillingDraft: ReducerCase<{}> = (s, p) => {
  return {
    ...s,
    traderCompany: s.draftTraderCompany,
    traderBillingAddress: s.draftCompanyBillingLocation,
    selectedPresale: {
      ...s.selectedPresale!,
      traderCompanyRef: s.draftTraderCompany!.id,
      billingLocationId: s.draftCompanyBillingLocation!.id,
    },
    draftCompanyBillingLocation: undefined,
    draftTraderCompany: undefined,
    isAddTraderDrawerOpen: false,
  };
};

/**
 *
 * @param s
 * @param p
 */
const selectProductForPresaleStorePrice: ReducerCase<UpdateSelectProductForPresaleStorePricePayload> = (s, p) => {
  const { productId, price, groupId } = p;
  const update = { ...s.selectProductForPresaleStore };

  // Input for group only, overwriting all existing prices for its sellables.
  if (!productId) {
    update.productGroupPriceNetInputs[groupId] = price;
    const group = update.groupedProductsByProducerArticle.find((g) => g.groupId === groupId);
    group?.productCores.forEach((core) => {
      update.groupedProductsPriceNetInputs[core.productCore.id] = price;
    });

    update.placeholderProducerArticleGroupInput = undefined;
  }
  // Update only single product.
  else {
    update.groupedProductsPriceNetInputs[productId] = price;
    update.productGroupPriceNetInputs[groupId] = undefined;
    // TODO: value intended to display range between min/max of products.
    update.placeholderProducerArticleGroupInput = "mixed";
  }

  return {
    ...s,
    selectProductForPresaleStore: {
      ...s.selectProductForPresaleStore,
      ...update,
    },
  };
};
