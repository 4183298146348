//
// reducer.userTasks.tsx
//
// Created by Thomas on 16.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {UserTasksStore, UserTasksStoreAction} from "../types/type.userTasks";

/*
 *
 * Attributes.
 *
 */

const store: UserTasksStore = {};

/*
 *
 * Functions.
 *
 */

export default function(s = store, a: UserTasksStoreAction) {
  switch (a.type) {
    case "USER_TASKS_UPDATE":
      return { ...s, ...a.payload };

    default:
      return s;
  }
}
