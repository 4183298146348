//
// reducer.aboutApp.js
// stockhouse
//
// Created by Thomas Schönmann on 16.06.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// About-app state.
//

import { AppStore, AppStoreAction, UPDATE, UPDATE_APP_VISIBILITY } from "../types/type.app";

/*
 *
 * Attributes.
 *
 */

const store: AppStore = {};

/*
 *
 * Functions.
 *
 */

export default function (s = store, a: AppStoreAction): AppStore {
  switch (a.type) {
    case UPDATE:
      return { ...s, ...a.payload };
    case UPDATE_APP_VISIBILITY:
      return { ...s, ...a.payload };

    default:
      return s;
  }
}
