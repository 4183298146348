//
// util.data.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Functions.
 *
 */

/**
 * Filter an objects values out that are undefined.
 *
 * @param source
 */
export function filterAwayUndefined(source: any) {
  const res: any = {};

  Object.keys(source).forEach((k) => {
    if (source[k] !== undefined) res[k] = source[k];
  });

  return res;
}
