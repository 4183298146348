//
// type.integrations.ts
// stockhouse
//
// Created by Thomas Schönmann on 17.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {SHIntegration} from "../../../../shared/src/models/types/type.db";

/*
 *
 * Attributes.
 *
 */

export const UPDATE = "INTEGRATIONS_UPDATE";

export const INIT_REQ = "INTEGRATIONS_INIT_REQ";
export const INIT_SUCCEEDED = "INTEGRATIONS_INIT_SUCCEEDED";
export const INIT_FAILED = "INTEGRATIONS_INIT_FAILED";

/*
 *
 * Interfaces.
 *
 */

export interface IntegrationsStore {
  integrations: Array<SHIntegration>;
}

/*
 *
 * Actions.
 *
 */

export interface UpdateIntegrationsStoreAction {
  type: typeof UPDATE;
  payload: UpdateIntegrationsStorePayload;
}

export type UpdateIntegrationsStorePayload = Partial<IntegrationsStore>;

export interface InitAction {
  type: typeof INIT_REQ;
  payload: InitPayload;
}

export interface InitPayload {
  client: string;
}

export interface InitSucceededAction {
  type: typeof INIT_SUCCEEDED;
  payload: InitSucceededPayload;
}

export interface InitSucceededPayload {
  integrations: Array<SHIntegration>;
}

export interface InitFailedAction {
  type: typeof INIT_FAILED;
  payload: InitFailedPayload;
}

export interface InitFailedPayload {
  error: Error;
}

/*
 *
 * Union Action.
 *
 */

export type IntegrationsStoreAction = UpdateIntegrationsStoreAction | InitAction | InitSucceededAction | InitFailedAction;

export type IntegrationsStorePayload = UpdateIntegrationsStorePayload | InitPayload | InitSucceededPayload | InitFailedPayload;
