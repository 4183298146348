//
// model.themes.js
// stockhouse
//
// Created by Thomas Schönmann on 21.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// Some theme!
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=212121&secondary.color=FFAB00
//

import {createMuiTheme, responsiveFontSizes, Theme as MUITheme} from "@material-ui/core/styles";
import "typeface-poppins";
import {SHAppTheme} from "../../../shared/src/models/types/type.theme";

/*
 *
 * Attributes.
 *
 */

export enum ThemeStorageKey {
  ThemeVariant = "theme-variant",
}

const lightTheme: SHAppTheme = {
  variant: "light",
  theme: createTheme({
    type: "light",
    primary: "#ededed",
    secondary: "#094c99",
  }),
};

const darkTheme: SHAppTheme = {
  variant: "dark",
  theme: createTheme({
    type: "dark",
    primary: "#273444",
    secondary: "#51A1EC",
    background: {
      default: "#161D28",
      paper: "#202733",
    },
  }),
};

/*
 *
 * Functions.
 *
 */

export function getThemesAsArray() {
  return [lightTheme, darkTheme];
}

function createTheme(props: {
  type: "light" | "dark";
  primary: string;
  secondary: string;
  font?: string;
  background?: { default: string; paper: string };
}): MUITheme {
  const { type, primary, secondary, font = "Poppins", background = {} } = props;

  return responsiveFontSizes(
    createMuiTheme({
      typography: {
        fontFamily: [font].join(","),
      },
      palette: {
        type,
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        background,
      },
      overrides: {
        MuiStepIcon: {
          root: {
            color: "white",
            "&$completed": { color: primary },
            "&$active": { color: secondary },
          },
          active: {},
          completed: {},
        },

        // @ts-ignore
        MUIDataTableViewCol: {
          checkboxRoot: {
            "&$checked": {
              color: primary,
            },
          },
        },
        MUIDataTableFilter: {
          reset: { color: primary },
          resetLink: { color: primary },
        },
      },
    })
  );
}

/*
 *
 * Exports.
 *
 */

export default { lightStandard: lightTheme, darkStandard: darkTheme };
