//
// index.tsx
//
// Created by Thomas on 14.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { functions } from "../models/model.firebase";

/*
 *
 * Functions.
 *
 */

/**
 * Factory to simply get a Firebase serverless function.
 *
 * @param name    Name of the function to call.
 */
export const createCallableFn = <T = any, R = any>(name: string): ((data?: T) => Promise<R>) => {
  const callable = functions.httpsCallable(name);
  return async (data?: T) => (await callable(data)).data;
};
