//
// type.transaction.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

export const UPDATE_SALE_STORE = "sale/update";

/*
 *
 * MARK: Store.
 *
 */

export interface SaleStore {}

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateSaleAction {
  type: typeof UPDATE_SALE_STORE;
  payload: UpdateSalePayload;
}

export type UpdateSalePayload = Partial<SaleStore>;

/*
 *
 * MARK: Union types.
 *
 */

export type SaleStoreAction = UpdateSaleAction;

export type SaleStorePayload = UpdateSalePayload;
