//
// effect.layout.js
// stockhouse
//
// Created by Thomas Schönmann on 23.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Hooks to use for layout effects.
//

import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import React, {useCallback} from "react";
import {PopperPlacementType} from "@material-ui/core";

/*
 *
 * Functions.
 *
 */

/**
 *
 */
export function useBreakpoint() {
  const theme = useTheme();

  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));

  let metric = 0;
  if (isUpSm) metric += 1;
  if (isUpMd) metric += 1;
  if (isUpLg) metric += 1;
  if (isUpXl) metric += 1;

  return metric;
}

/**
 *
 */
export function useAdaptableHeaderHeight() {
  const theme = useTheme();

  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));

  if (isDownSm) return 80;
  if (isUpMd && !isUpLg && !isUpXl) return 100;
  if (isUpLg && !isUpXl) return 120;
  if (isUpXl) return 180;
}

/**
 *
 */
export function usePopper() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopperOpen(prev => placement !== "bottom-start" || !prev);
    setPlacement("bottom-start");
  };

  const close = useCallback(() => {
    setIsPopperOpen(false);
  }, [setIsPopperOpen]);

  return { anchorEl, isPopperOpen, placement, handleClick, close };
}
