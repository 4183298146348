//
// hook.route.tsx
// stockhouse
//
// Created by Thomas on 06.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import {lazyRoutes} from "../models/model.route";

/*
 *
 * Functions.
 *
 */

export function useCurrentRouteMetaData() {
  const location = useLocation();
  return useMemo(() => {
    const match = lazyRoutes.find((r) => location.pathname.includes(r.path));

    if (match) {
      return { route: match, primary: match.visual?.label, secondary: match.group };
    }
  }, [location]);
}
