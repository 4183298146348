//
// action.integrations.ts
// stockhouse
//
// Created by Thomas Schönmann on 17.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
    INIT_FAILED,
    INIT_REQ,
    INIT_SUCCEEDED,
    InitAction,
    InitFailedAction,
    InitFailedPayload,
    InitPayload,
    InitSucceededAction,
    InitSucceededPayload,
    UPDATE,
    UpdateIntegrationsStoreAction,
    UpdateIntegrationsStorePayload
} from "../types/type.integrations";

/*
 *
 * Functions.
 *
 */

export const update = (payload: UpdateIntegrationsStorePayload): UpdateIntegrationsStoreAction => ({
  type: UPDATE,
  payload
});

export const init = (payload: InitPayload): InitAction => ({
  type: INIT_REQ,
  payload
});

export const initSucceeded = (payload: InitSucceededPayload): InitSucceededAction => ({
  type: INIT_SUCCEEDED,
  payload
});

export const initFailed = (payload: InitFailedPayload): InitFailedAction => ({
  type: INIT_FAILED,
  payload
});
