//
// reducer.trader.tsx
//
// Created by Thomas on 24.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  SelectTraderCompanyPayload,
  SelectTraderCorePayload,
  TraderStore,
  TraderStoreAction,
  TraderStorePayload,
  UpdateTraderInputPayload,
  UpsertSelectedTraderCoreResPayload
} from "../types/type.trader";
import { ReducerCaseFn } from "../util/util.type.reducer";
import { getEmptyTraderLocation } from "../../../../shared/src/models/model.trader";

/*
 *
 * Interfaces.
 *
 */

type ReducerCase<P extends TraderStorePayload> = ReducerCaseFn<"trader", P>;

/*
 *
 * MARK: Reducer.
 *
 */

const store = getDefaultStore();

export default function (s = store, a: TraderStoreAction): TraderStore {
  switch (a.type) {
    case "trader/update":
      return { ...s, ...a.payload };
    case "trader/update/cache":
      return { ...s, cache: { ...s.cache, ...a.payload } };
    case "trader/update/input":
      return updateInput(s, a.payload);
    case "trader/update/draft":
      return { ...s, draft: { ...s.draft, ...a.payload } };
    case "trader/update/draft/company-location":
      return { ...s, draft: { ...s.draft, companyLocation: { ...s.draft.companyLocation!, ...a.payload } } };

    case "trader/upsert-dialog/init":
      return { ...s, ...a.payload, isTraderUpsertDialogOpen: true };
    case "trader/upsert-dialog/close":
      return { ...getDefaultStore(), traderCoreInputSchema: s.traderCoreInputSchema };

    case "trader/company/switch-open":
      return { ...s, isTraderCompanyViewOpen: !s.isTraderCompanyViewOpen };

    case "trader/select/company":
      return selectTraderCompany(s, a.payload);
    case "trader/init/add-company-location-from-draft":
      return {
        ...s,
        isTraderCompanyAddLocationDrawerOpen: true,
        draft: { ...s.draft, companyLocation: getEmptyTraderLocation({}) },
      };

    case "trader/select/trader-core":
      return selectTraderCore(s, a.payload);
    case "trader/unselect/trader-core":
      return {
        ...s,
        selectedTraderCore: undefined,
        state: "trader-cores-overview",
        selectedTraderCoreDraft: undefined,
        traderCoreDrafts: [],
      };
    case "trader/update/selected-trader-core":
      return { ...s, selectedTraderCore: { ...s.selectedTraderCore!, ...a.payload } };
    case "trader/update/selected-trader-core-draft-values":
      return {
        ...s,
        selectedTraderCoreDraft: {
          ...s.selectedTraderCoreDraft!,
          entity: { ...s.selectedTraderCoreDraft!.entity, values: { ...s.selectedTraderCoreDraft!.entity.values, ...a.payload } },
        },
      };
    case "trader/update/selected-trader-core-values":
      return {
        ...s,
        selectedTraderCore: { ...s.selectedTraderCore!, values: { ...s.selectedTraderCore!.values, ...a.payload } },
      };

    case "trader/upsert/selected-trader-core/res":
      return upsertSelectedTraderCoreRes(s, a.payload);
    case "trader/upsert/selected-trader-core/rej":
      return {
        ...s,
        state: "trader-cores-overview",
        selectedTraderCore: undefined,
        selectedTraderCoreDraft: undefined,
        traderCoreDrafts: [],
      };

    // Processed in saga.
    case "trader/upsert/selected-trader-core/req":
    case "trader/init/add-trader-core":
    case "trader/add/company-location-from-draft":
    case "trader/create/company":
    case "trader/init/cache/trader-company":
    case "trader/remove/selected-trader-core":
    case "trader/open/dialog":
    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param s
 * @param p
 */
const updateInput: ReducerCase<UpdateTraderInputPayload> = (s, p) => {
  let next = { ...s, input: { ...s.input, ...p } };

  if (next.input.companyName && next.selectedTraderCompany) {
    next = {
      ...next,
      selectedTraderCompany: undefined,
      selectedTraderCore: undefined,
      state: "select-company",
      cache: { ...next.cache, traders: [] },
    };
  }

  return next;
};

/**
 *
 * @param s
 * @param p
 */
const selectTraderCompany: ReducerCase<SelectTraderCompanyPayload> = (s, p) => {
  return {
    ...s,
    state: "trader-cores-overview",
    selectedTraderCompany: p.company,
    input: {
      ...s.input,
      companyUid: p.company.uid || "",
      companyName: p.company.name,
    },
  };
};

/**
 *
 * @param s
 * @param p
 */
const selectTraderCore: ReducerCase<SelectTraderCorePayload> = (s, p) => {
  return {
    ...s,
    state: "trader-core-detail",
    selectedTraderCore: p.core,
    isTraderUpsertDialogOpen: p.isTraderUpsertDialogOpen !== undefined ? p.isTraderUpsertDialogOpen : s.isTraderUpsertDialogOpen,
  };
};

/**
 *
 * @param s
 * @param p
 */
const upsertSelectedTraderCoreRes: ReducerCase<UpsertSelectedTraderCoreResPayload> = (s, p) => {
  let traders = [...s.cache.traders];
  const { cores } = p;

  cores.forEach((core) => {
    if (!traders.some((p) => p.id === core.id)) {
      traders = [core, ...traders];
    } else {
      const i = traders.findIndex((p) => p.id === core.id);
      traders[i] = core;
    }
  });

  return {
    ...s,
    state: "trader-cores-overview",
    cache: { ...s.cache, traders },
    selectedTraderCore: undefined,
    selectedTraderCoreDraft: undefined,
    traderCoreDrafts: [],
  };
};

/**
 *
 */
function getDefaultStore(): TraderStore {
  return {
    state: "select-company",
    input: {
      companyLanguage: "",
      companyName: "",
      companyNote: "",
      companyUid: "",
      companyLocations: [],
    },
    cache: {
      companies: [],
      traders: [],
    },
    draft: {},
    traderCoreDrafts: [],
    isTraderUpsertDialogOpen: false,
    isTraderCompanyViewOpen: true,
    traderCompanySubviewIndex: 0,
    selectedTabIndex: 0,
  };
}
