//
// reducer.transaction.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SaleStore, SaleStoreAction, UPDATE_SALE_STORE } from "../types/type.sale";

/*
 *
 * MARK: Store.
 *
 */

const store = getDefaultStore();

/*
 *
 * MARK: Reducer.
 *
 */

export default function (s = store, a: SaleStoreAction): SaleStore {
  switch (a.payload) {
    case UPDATE_SALE_STORE:
      return { ...s, ...a.payload };

    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

function getDefaultStore(): SaleStore {
  return {};
}
