import {
  INIT_NEW_PRODUCT_DIALOG,
  NewProductStore,
  NewProductStoreAction,
  RESET_NEW_PRODUCT,
  SELECT_NEW_PRODUCT_ARTICLE,
  SELECT_NEW_PRODUCT_PRODUCER,
  SELECT_NEW_PRODUCT_TYPE,
  UPDATE_CACHE,
} from "../types/type.newProduct";
import { format } from "date-fns";

const store = getDefaultNewProductStore();

export default function (s: NewProductStore = store, a: NewProductStoreAction) {
  switch (a.type) {
    case INIT_NEW_PRODUCT_DIALOG:
      return { ...s, isNewProductUpsertDialogOpen: a.payload.isNewProductUpsertDialogOpen };
    case SELECT_NEW_PRODUCT_TYPE:
      return { ...s, selectedNewProductTypeCore: a.payload };
    case SELECT_NEW_PRODUCT_ARTICLE:
      return { ...s, selectedNewProductArticle: a.payload };
    case SELECT_NEW_PRODUCT_PRODUCER:
      return { ...s, selectedNewProductProducer: a.payload };
    case UPDATE_CACHE:
      return { ...s, cache: { ...s.cache, ...a.payload } };
    case RESET_NEW_PRODUCT:
      return getDefaultNewProductStore();
    default:
      return s;
  }
}

/**
 * Initial store state
 * **/
function getDefaultNewProductStore(): NewProductStore {
  return {
    cache: {
      articles: [],
      cores: [],
      producers: [],
    },
    product: {
      name: "",
      type: "",
      serialNumber: "",
      productionDate: format(new Date(), "yyyy-MM-dd"),
      configuration: "",
      model: "",
      status: "",
      customer: "",
      receipt: "",
      orderNumber: "",
      orderCost: 0,
      deliveryCost: 0,
      invoiceNumber: "",
      ekNet: 0,
      proportionalDeliveryCost: 0,
      address: "",
    },
    isNewProductUpsertDialogOpen: false,
  };
}
