//
// saga.transactionDraft.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  FinishSelectPresaleForProductAction,
  INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
  INIT_PRESALE_TRADER_DRAFT_DRAWER,
  INIT_PRESALE_UPSERT_DIALOG,
  InitPresaleDraftTraderDrawerAction,
  InitPresaleUpsertDialogAction,
  InitSelectPresaleForProductAction,
  PresaleStore,
  PresaleStoreGroupedProductsByProducerArticlesTuple,
  REMOVE_PRESALE,
  REMOVE_PRODUCT_FROM_PRESALE,
  RemovePresaleAction,
  RemoveProductFromPresaleAction,
  SELECT_PRESALE,
  SELECT_PRESALE_BY_ID,
  SelectPresaleAction,
  SelectPresaleByIdAction,
  UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES,
  UPDATE_DRAFT_TRADER_COMPANY,
  UpdateActivePresalesFromRawPresalesAction,
  UpdatePresaleDraftTraderCompanyAction,
  UPSERT_PRESALE,
  UpsertPresaleAction,
} from "../types/type.presale";
import { UserStore } from "../types/type.user";
import { SHStore } from "../reducers";
import {
  cancelSelectPresaleForProductAction,
  resetPresaleStoreAction,
  selectPresaleAction,
  updatePresaleStoreAction,
  updateSelectProductForPresaleStoreAction,
} from "../actions/action.presale";
import {
  getEmptyPresale,
  getEmptyPresaleProductCore,
  getEmptyPresaleProductCoreResolution,
} from "../../../../shared/src/models/model.presale";
import { tryCatchSaga } from "../util/util.saga";
import { getEmptyTraderCompany, getEmptyTraderLocation } from "../../../../shared/src/models/model.trader";
import { initTraderCompanyCacheAction } from "../actions/action.trader";
import { getEntity, removeEntity, updateEntity, upsertEntityWithContinuousId } from "../../models/model.db";
import { SHDomain, SHEntityRef } from "../../../../shared/src/models/types/type.db";
import {
  SHPresale,
  SHPresaleProductCore,
  SHPresaleProductCoreResolution,
  SHPresaleReferenceResolution,
} from "../../../../shared/src/models/types/type.presale";
import { SHTraderCompany } from "../../../../shared/src/models/types/type.trader";
import { SHProductCore, SHProductProducerArticle, SHProductProducerCore } from "../../../../shared/src/models/types/type.product";
import { v4 as uuidV4 } from "uuid";
import { firestore } from "firebase";
import { WarehouseStore } from "../types/type.warehouse";
import { updateWarehouseAction } from "../actions/action.warehouse";
import { getEmptyEntityReservation } from "../../../../shared/src/models/model.reservation";

/*
 *
 * MARK: Watcher.
 *
 */

export default function* () {
  yield takeEvery(INIT_PRESALE_UPSERT_DIALOG, initPresaleUpsertDialogAction);
  yield takeEvery(
    INIT_PRESALE_TRADER_DRAFT_DRAWER,
    tryCatchSaga(initPresaleDraftTraderDrawer, { withProgress: true, withStore: "presale" })
  );
  yield takeEvery(UPDATE_DRAFT_TRADER_COMPANY, tryCatchSaga(updateDraftTraderCompanySaga, { withProgress: true }));
  yield takeEvery(UPSERT_PRESALE, tryCatchSaga(upsertPresaleSaga, { withProgress: true, withStore: "presale" }));
  yield takeEvery(UPDATE_ACTIVE_PRESALES_FROM_RAW_PRESALES, tryCatchSaga(updateActivePresalesFromRawPresalesSaga));
  yield takeEvery(REMOVE_PRESALE, tryCatchSaga(removePresaleSaga, { withProgress: true, withStore: "presale" }));
  yield takeEvery(SELECT_PRESALE, tryCatchSaga(selectPresaleSaga, { withProgress: true }));
  yield takeEvery(SELECT_PRESALE_BY_ID, tryCatchSaga(selectPresaleByIdSaga, { withProgress: true }));
  yield takeEvery(
    INIT_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
    tryCatchSaga(initSelectPresaleForProductDrawerSaga, { withProgress: true })
  );
  yield takeEvery(
    FINISH_PRESALE_SELECT_PRESALE_FOR_PRODUCT_DRAWER,
    tryCatchSaga(finishSelectPresaleForProductDrawerSaga, { withProgress: true, withStore: "presale" })
  );
  yield takeEvery(
    REMOVE_PRODUCT_FROM_PRESALE,
    tryCatchSaga(removeProductFromPresaleSaga, { withProgress: true, withStore: "presale" })
  );
}

/*
 *
 * MARK: Sagas.
 *
 */

/**
 *
 * @param a
 */
function* initPresaleUpsertDialogAction(a: InitPresaleUpsertDialogAction) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);
  yield put(
    updatePresaleStoreAction({
      isPresaleUpsertDialogTraderVisible: true,
      isPresaleUpsertDialogOpen: true,
      assignee: user!,
      selectedPresale: getEmptyPresale({
        client: user!.client!,
        assigneeRef: user!.id,
      }),
    })
  );
}

/**
 *
 */
function* initPresaleDraftTraderDrawer(a: InitPresaleDraftTraderDrawerAction, s?: PresaleStore) {
  const { user }: UserStore = yield select((s: SHStore) => s.user);

  const company =
    s!.traderCompany ||
    getEmptyTraderCompany({
      client: user!.client,
    });
  const location = s!.traderBillingAddress || getEmptyTraderLocation({});

  yield put(initTraderCompanyCacheAction());
  yield put(
    updatePresaleStoreAction({ draftTraderCompany: company, draftCompanyBillingLocation: location, isAddTraderDrawerOpen: true })
  );
}

/**
 *
 * @param a
 */
function* updateDraftTraderCompanySaga(a: UpdatePresaleDraftTraderCompanyAction) {
  // DEBUG: Nothing to do here for now.
}

/**
 *
 * @param a
 * @param s
 */
function* upsertPresaleSaga(a: UpsertPresaleAction, s?: PresaleStore) {
  const { selectedPresale } = s!;
  const { user }: UserStore = yield select((s: SHStore) => s.user);

  // FIXME: Discuss: Force-deleting all undefined values. Might be required, but maybe can be avoided.
  const data = selectedPresale!;
  Object.keys(data!).forEach((key) => data![key as keyof SHPresale] === undefined && delete data![key as keyof SHPresale]);

  yield call(() => upsertEntityWithContinuousId({ domain: SHDomain.PreSales, client: user!.client, data }));
  yield put(resetPresaleStoreAction({ variant: "upsert-dialog" }));
}

/**
 *
 * @param a
 */
function* updateActivePresalesFromRawPresalesSaga(a: UpdateActivePresalesFromRawPresalesAction) {
  const activePresales: SHPresaleReferenceResolution[] = yield call(() =>
    Promise.all(
      a.payload.presales.map(async (pre) => {
        const traderCompany = pre.traderCompanyRef
          ? await getEntity<SHTraderCompany>({ domain: SHDomain.TraderCompanies, id: pre.traderCompanyRef })
          : undefined;
        const billingLocation = traderCompany?.locations.find((loc) => loc.id === pre.billingLocationId);

        return {
          ...pre,
          traderCompany,
          billingLocation,
        } as SHPresaleReferenceResolution;
      })
    )
  );

  yield put(updatePresaleStoreAction({ activePresales }));
}

/**
 *
 * @param a
 * @param s
 */
function* removePresaleSaga(a: RemovePresaleAction, s?: PresaleStore) {
  const selectedPresale = s!.selectedPresale!;

  yield call(() =>
    Promise.all(
      selectedPresale.productCores.map((core) =>
        updateEntity<SHProductCore>({
          domain: SHDomain.ProductCores,
          id: core.productCoreRef,
          data: {
            reservation: firestore.FieldValue.delete(),
          },
        })
      )
    )
  );

  yield call(() => removeEntity<SHPresale>({ domain: SHDomain.PreSales, id: selectedPresale.id }));
  yield put(resetPresaleStoreAction({ variant: "upsert-dialog" }));
}

/**
 * Updates the presale store with a selected presale entity and opens the connected upsert dialog.
 *
 * @param a
 */
function* selectPresaleSaga(a: SelectPresaleAction) {
  const { presale, traderCompany, traderCore, assignee } = a.payload;
  const next: Partial<PresaleStore> = {
    selectedPresale: presale,
    isPresaleUpsertDialogOpen: true,
    isPresaleUpsertDialogTraderVisible: true,
    traderCompany,
    traderCore,
    assignee,
  };

  if (!traderCompany && presale.traderCompanyRef) {
    next.traderCompany = yield call(() =>
      getEntity<SHTraderCompany>({ domain: SHDomain.TraderCompanies, id: presale.traderCompanyRef! })
    );
  }

  if (presale.billingLocationId && next.traderCompany) {
    next.traderBillingAddress = next.traderCompany.locations.find((loc) => loc.id === presale.billingLocationId);
  }

  if (!traderCore && presale.traderCoreRef) {
    next.traderCore = yield call(() => getEntity<SHTraderCompany>({ domain: SHDomain.TraderCores, id: presale.traderCoreRef! }));
  }

  if (!assignee) {
    next.assignee = yield call(() => getEntity<SHTraderCompany>({ domain: SHDomain.Users, id: presale.assigneeRef! }));
  }

  //
  // TODO: Resolve products.
  //

  yield put(updatePresaleStoreAction(next));
}

/**
 * Fetches a presale for a given ID and calls the 'selectPresaleSaga'-saga.
 *
 * @param a
 */
function* selectPresaleByIdSaga(a: SelectPresaleByIdAction) {
  const { id, ...payload } = a.payload;
  const presale: SHPresale = yield call(() => getEntity<SHPresale>({ id, domain: SHDomain.PreSales }));

  yield call(() => selectPresaleSaga({ type: SELECT_PRESALE, payload: { ...payload, presale } }));
}

/**
 *
 * @param a
 */
function* initSelectPresaleForProductDrawerSaga(a: InitSelectPresaleForProductAction) {
  const { productCores } = a.payload;

  // Grouped as: 'Producer' -> 'Article' -> products[]
  const group: Record<SHEntityRef, Record<SHEntityRef, SHPresaleProductCoreResolution[]>> = {};
  const producers: Record<SHEntityRef, SHProductProducerCore> = {};
  const articles: Record<SHEntityRef, SHProductProducerArticle> = {};

  for (const core of productCores) {
    if (!group[core.producerCoreRef]) group[core.producerCoreRef] = {};
    if (!group[core.producerCoreRef][core.producerArticleRef]) group[core.producerCoreRef][core.producerArticleRef] = [];

    if (!producers[core.producerCoreRef])
      producers[core.producerCoreRef] = yield call(() =>
        getEntity<SHProductProducerCore>({ domain: SHDomain.ProductProducerCores, id: core.producerCoreRef })
      );
    if (!articles[core.producerArticleRef])
      articles[core.producerArticleRef] = yield call(() =>
        getEntity<SHProductProducerCore>({ domain: SHDomain.ProductProducerArticles, id: core.producerArticleRef })
      );

    group[core.producerCoreRef][core.producerArticleRef].push(getEmptyPresaleProductCoreResolution({ productCore: core }));
  }

  // Transform fetches to store-compatible variable and update store.
  const groupedProductsByProducerArticle: PresaleStoreGroupedProductsByProducerArticlesTuple[] = [];

  for (const producerCoreKey in group) {
    if (!group.hasOwnProperty(producerCoreKey)) continue;

    for (const producerArticleKey in group[producerCoreKey]) {
      if (!group[producerCoreKey].hasOwnProperty(producerArticleKey)) continue;

      groupedProductsByProducerArticle.push({
        groupId: uuidV4(),
        producer: producers[producerCoreKey],
        article: articles[producerArticleKey],
        productCores: group[producerCoreKey][producerArticleKey],
      });
    }
  }

  yield put(
    updateSelectProductForPresaleStoreAction({
      groupedProductsByProducerArticle,
    })
  );
}

/**
 *
 * @param a
 * @param s
 */
function* finishSelectPresaleForProductDrawerSaga(a: FinishSelectPresaleForProductAction, s?: PresaleStore) {
  const { intent, onDone } = a.payload;
  const store = s!;
  const { groupedProductsByProducerArticle, groupedProductsPriceNetInputs } = store.selectProductForPresaleStore;
  const selectedPresale = store.selectProductForPresaleStore.selectedPresale!;

  const sellablesFromOtherPresales: SHPresaleProductCoreResolution[] = [];

  // TODO: Most finance data not stored yet, implement. -Tom
  const presaleProductCores: SHPresaleProductCore[] = [];
  groupedProductsByProducerArticle.forEach((group) => {
    group.productCores.forEach((product) => {
      if (Boolean(product.productCore.reservation?.ref) && product.productCore.reservation!.ref !== selectedPresale.id) {
        sellablesFromOtherPresales.push(product);
      }

      presaleProductCores.push(
        getEmptyPresaleProductCore({
          // TODO: Discuss if spread is correct or migration of old data can lead to errors. -Tom
          ...product,
          productCoreRef: product.productCore.id,
          priceNet: groupedProductsPriceNetInputs[product.productCore.id],
        })
      );
    });
  });

  // Data to append to cores in presale or to update inline as they already exists.
  const sellablesToUpdateInPresale: SHPresaleProductCore[] = [];
  const sellablesToUnion: SHPresaleProductCore[] = [];
  presaleProductCores.forEach((p) => {
    if (selectedPresale.productCores.every((core) => core.productCoreRef !== p.productCoreRef)) {
      sellablesToUnion.push(p);
    } else {
      sellablesToUpdateInPresale.push(p);
    }
  });

  // First update the inline elements.
  sellablesToUpdateInPresale.forEach((s) => {
    const i = selectedPresale.productCores.findIndex((c) => c.productCoreRef === s.productCoreRef);
    if (i >= 0) selectedPresale.productCores[i] = s;
  });

  // Second append the new ones.
  selectedPresale.productCores = [...selectedPresale.productCores, ...sellablesToUnion];

  /*
  console.warn("groupedProductsPriceNetInputs", groupedProductsPriceNetInputs);
  console.warn("sellablesFromOtherPresales", sellablesFromOtherPresales);
  console.warn("sellablesToUnion", sellablesToUnion);
  console.warn("sellablesToUpdateInPresale", sellablesToUpdateInPresale);
  console.warn("selectedPresale.productCores", selectedPresale.productCores);
   */

  // Update DB.
  yield call(() =>
    updateEntity<SHPresale>({
      domain: SHDomain.PreSales,
      id: selectedPresale.id,
      data: {
        productCores: selectedPresale.productCores,
      },
    })
  );

  yield call(() =>
    Promise.all(
      selectedPresale.productCores.map((c) =>
        updateEntity<SHProductCore>({
          id: c.productCoreRef,
          domain: SHDomain.ProductCores,
          data: {
            reservation: selectedPresale.id,
          },
        })
      )
    )
  );

  const groupedSellablesFromOtherPresales = sellablesFromOtherPresales.reduce((entryMap, e) => {
    return entryMap.set(e.productCore.reservation!.ref, [...(entryMap.get(e.productCore.reservation!.ref) || []), e]);
  }, new Map<string, SHPresaleProductCoreResolution[]>());

  yield call(() =>
    Promise.all(
      Object.keys(groupedSellablesFromOtherPresales).map(async (key) => {
        const presale = await getEntity<SHPresale>({ domain: SHDomain.PreSales, id: key });
        const coresToRemove = groupedSellablesFromOtherPresales.get(key);

        if (!presale || !coresToRemove) {
          console.error("update groupedSellablesFromOtherPresales, no presale for", key, "or no cores to remove");
          return;
        }

        const newPresaleProductCores = presale.productCores.filter((c) =>
          coresToRemove.some((cr) => cr.productCore.id === c.productCoreRef)
        );
        await updateEntity<SHPresale>({
          domain: SHDomain.PreSales,
          id: key,
          data: {
            productCores: newPresaleProductCores,
          },
        });
      })
    )
  );

  if (onDone) yield call(() => onDone());
  else console.warn("no onDone");
  yield put(cancelSelectPresaleForProductAction());

  // TODO: Update selected presale, if one exists, to update the rendered view.

  switch (intent) {
    case "close":
      const warehouse: WarehouseStore = yield select((s: SHStore) => s.warehouse);
      if (
        warehouse.selectedProductCore &&
        presaleProductCores.some((c) => c.productCoreRef === warehouse.selectedProductCore?.id)
      ) {
        yield put(
          updateWarehouseAction({
            selectedProductCore: {
              ...warehouse.selectedProductCore,
              reservation: getEmptyEntityReservation({
                ref: selectedPresale.id,
                target: "disposal",
              }),
            },
          })
        );
      }
      break;
    case "show-draft":
      yield put(selectPresaleAction({ presale: selectedPresale, traderCompany: selectedPresale.traderCompany }));
      break;
  }
}

/**
 *
 * @param a
 */
function* removeProductFromPresaleSaga(a: RemoveProductFromPresaleAction, s?: PresaleStore) {
  const store = s!;
  const warehouse: WarehouseStore = yield select((s: SHStore) => s.warehouse);
  const { productCoreIds, presaleId } = a.payload;

  const presale: SHPresale = yield call(() => getEntity<SHPresale>({ domain: SHDomain.PreSales, id: presaleId }));
  yield call(() =>
    updateEntity<SHPresale>({
      domain: SHDomain.PreSales,
      id: presaleId,
      data: {
        productCores: presale.productCores.filter((p) => productCoreIds.every((id) => id !== p.productCoreRef)),
      },
    })
  );

  yield call(() =>
    Promise.all(
      productCoreIds.map((id) =>
        updateEntity<SHProductCore>({
          domain: SHDomain.ProductCores,
          id,
          data: {
            reservation: firestore.FieldValue.delete(),
          },
        })
      )
    )
  );

  if (store.selectedPresale) {
    yield put(
      updatePresaleStoreAction({
        selectedPresale: {
          ...store.selectedPresale!,
          productCores: store.selectedPresale.productCores.filter((c) => !productCoreIds.includes(c.productCoreRef)),
        },
      })
    );
  }

  if (warehouse.selectedProductCore && productCoreIds.includes(warehouse.selectedProductCore.id)) {
    const update = { ...warehouse.selectedProductCore };
    delete update.reservation;
    yield put(updateWarehouseAction({ selectedProductCore: update }));
  }
}
