//
// action.warehouse.tsx
//
// Created by Thomas on 14.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  CHANGE_STATE,
  ChangeStateAction,
  ChangeStatePayload,
  CLONE_DRAFT,
  CloneDraftAction,
  CloneDraftPayload,
  CREATE_PRODUCER_ERR,
  CREATE_PRODUCER_REQ,
  CREATE_PRODUCER_SUC,
  CreateProducerErrAction,
  CreateProducerErrPayload,
  CreateProducerReqAction,
  CreateProducerReqPayload,
  CreateProducerSucAction,
  CreateProducerSucPayload,
  DELETE_PRODUCT_CORE,
  DeleteProductCoreAction,
  DeleteProductCorePayload,
  INIT_NEW_PRODUCT,
  INIT_PRODUCT_DIALOG,
  InitNewProductAction,
  InitProductDialogAction,
  InitProductDialogPayload,
  PRODUCT_UPSERT_FAILED,
  PRODUCT_UPSERT_REQ,
  PRODUCT_UPSERT_SUCCEEDED,
  ProductUpsertAction,
  ProductUpsertFailedAction,
  ProductUpsertFailedPayload,
  ProductUpsertPayload,
  ProductUpsertSucceededAction,
  ProductUpsertSucceededPayload,
  REMOVE_DRAFT,
  REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA,
  RemoveDraftAction,
  RemoveImageFromProducerArticleMediaAction,
  RemoveImageFromProducerArticleMediaPayload,
  RESET,
  ResetAction,
  ResetPayload,
  SELECT_DRAFT,
  SELECT_PRODUCER_CORE,
  SELECT_PRODUCT,
  SELECT_PRODUCT_FROM_OTHER_CONTEXT,
  SelectDraftAction,
  SelectDraftPayload,
  SelectProducerCoreAction,
  SelectProducerPayload,
  SelectProductAction,
  SelectProductFromOtherContextAction,
  SelectProductFromOtherContextPayload,
  SelectProductPayload,
  UNSELECT_PRODUCT,
  UnselectProductAction,
  UPDATE,
  UPDATE_CACHE,
  UPDATE_INPUT,
  UPDATE_PRODUCER,
  UPDATE_PRODUCER_ARTICLE_IMAGES,
  UPDATE_PRODUCT_CORE_DRAFT,
  UPDATE_PRODUCT_CORE_DRAFT_VALUES,
  UPDATE_PRODUCT_CORE_VALUES,
  UpdateCacheAction,
  UpdateCachePayload,
  UpdateProducerAction,
  UpdateProducerArticleImagesAction,
  UpdateProducerArticleImagesPayload,
  UpdateProducerPayload,
  UpdateProductCoreDraftAction,
  UpdateProductCoreDraftPayload,
  UpdateProductCoreDraftValuesAction,
  UpdateProductCoreDraftValuesPayload,
  UpdateProductCoreValuesAction,
  UpdateProductCoreValuesPayload,
  UpdateWarehouseAction,
  UpdateWarehouseInputAction,
  UpdateWarehouseInputPayload,
  UpdateWarehousePayload,
  UPLOAD_IMAGES_FROM_INPUT,
  UploadImagesFromInputAction,
  UploadImagesFromInputPayload
} from "../types/type.warehouse";

/*
 *
 * Functions.
 *
 */

export const updateWarehouseAction = (payload: UpdateWarehousePayload): UpdateWarehouseAction => ({
  type: UPDATE,
  payload,
});

export const updateProductCoreValuesAction = (payload: UpdateProductCoreValuesPayload): UpdateProductCoreValuesAction => ({
  type: UPDATE_PRODUCT_CORE_VALUES,
  payload,
});

export const updateProductCoreDraftValuesAction = (
  payload: UpdateProductCoreDraftValuesPayload
): UpdateProductCoreDraftValuesAction => ({
  type: UPDATE_PRODUCT_CORE_DRAFT_VALUES,
  payload,
});

export const updateProductCoreDraftAction = (payload: UpdateProductCoreDraftPayload): UpdateProductCoreDraftAction => ({
  type: UPDATE_PRODUCT_CORE_DRAFT,
  payload,
});

export const resetWarehouseAction = (payload: ResetPayload): ResetAction => ({
  type: RESET,
  payload,
});

export const upsertProductAction = (payload: ProductUpsertPayload): ProductUpsertAction => ({
  type: PRODUCT_UPSERT_REQ,
  payload,
});

export const productUpsertSucceededAction = (payload: ProductUpsertSucceededPayload): ProductUpsertSucceededAction => ({
  type: PRODUCT_UPSERT_SUCCEEDED,
  payload,
});

export const productUpsertFailedAction = (payload: ProductUpsertFailedPayload): ProductUpsertFailedAction => ({
  type: PRODUCT_UPSERT_FAILED,
  payload,
});

export const changeStateWarehouseAction = (payload: ChangeStatePayload): ChangeStateAction => ({
  type: CHANGE_STATE,
  payload,
});

export const initProductDialogWarehouseAction = (payload: InitProductDialogPayload): InitProductDialogAction => ({
  type: INIT_PRODUCT_DIALOG,
  payload,
});

export const updateProducerWarehouseAction = (payload: UpdateProducerPayload): UpdateProducerAction => ({
  type: UPDATE_PRODUCER,
  payload,
});

export const updateInputWarehouseAction = (payload: UpdateWarehouseInputPayload): UpdateWarehouseInputAction => ({
  type: UPDATE_INPUT,
  payload,
});

export const createProducerReqAction = (payload: CreateProducerReqPayload): CreateProducerReqAction => ({
  type: CREATE_PRODUCER_REQ,
  payload,
});

export const createProducerSucAction = (payload: CreateProducerSucPayload): CreateProducerSucAction => ({
  type: CREATE_PRODUCER_SUC,
  payload,
});

export const createProducerErrAction = (payload: CreateProducerErrPayload): CreateProducerErrAction => ({
  type: CREATE_PRODUCER_ERR,
  payload,
});

export const selectProductWarehouseAction = (payload: SelectProductPayload): SelectProductAction => ({
  type: SELECT_PRODUCT,
  payload,
});

export const unselectProductWarehouseAction = (): UnselectProductAction => ({
  type: UNSELECT_PRODUCT,
});

export const initNewProductAction = (): InitNewProductAction => ({
  type: INIT_NEW_PRODUCT,
});

export const selectProducerCoreAction = (payload: SelectProducerPayload): SelectProducerCoreAction => ({
  type: SELECT_PRODUCER_CORE,
  payload,
});

export const updateProductCacheAction = (payload: UpdateCachePayload): UpdateCacheAction => ({
  type: UPDATE_CACHE,
  payload,
});

export const updateProducerArticleImagesAction = (
  payload: UpdateProducerArticleImagesPayload
): UpdateProducerArticleImagesAction => ({
  type: UPDATE_PRODUCER_ARTICLE_IMAGES,
  payload,
});

export const deleteProductCoreAction = (payload: DeleteProductCorePayload): DeleteProductCoreAction => ({
  type: DELETE_PRODUCT_CORE,
  payload,
});

export const cloneDraftAction = (payload: CloneDraftPayload): CloneDraftAction => ({
  type: CLONE_DRAFT,
  payload,
});

export const selectDraftAction = (payload: SelectDraftPayload): SelectDraftAction => ({
  type: SELECT_DRAFT,
  payload,
});

export const removeDraftAction = (): RemoveDraftAction => ({
  type: REMOVE_DRAFT,
});

export const uploadImagesFromInputAction = (payload: UploadImagesFromInputPayload): UploadImagesFromInputAction => ({
  type: UPLOAD_IMAGES_FROM_INPUT,
  payload,
});

export const removeImageFromProducerArticleMediaAction = (
  payload: RemoveImageFromProducerArticleMediaPayload
): RemoveImageFromProducerArticleMediaAction => ({
  type: REMOVE_IMAGE_FROM_PRODUCER_ARTICLE_MEDIA,
  payload,
});

export const selectProductFromOtherContextAction = (
  payload: SelectProductFromOtherContextPayload
): SelectProductFromOtherContextAction => ({
  type: SELECT_PRODUCT_FROM_OTHER_CONTEXT,
  payload,
});
