//
// NavigationProvider.tsx
// stockhouse
//
// Created by Thomas Schönmann on 14.01.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

/*
 *
 * Components.
 *
 */

export default (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return <BrowserRouter>{children}</BrowserRouter>;
};
