//
// util.saga.tsx
//
// Created by Thomas on 28.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//
import { put, select } from "redux-saga/effects";
import { finishProgressThreadAction, updateProgressAction } from "../actions/action.progress";
import { SHStore } from "../reducers";
import { v4 as uuidv4 } from "uuid";

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param saga
 * @param options
 */
export function tryCatchSaga<A, K extends keyof SHStore>(
  saga: (a: A, s?: SHStore[K]) => void,
  options?: { withProgress?: boolean; withStore?: K }
) {
  return function*(a: A) {
    let threadId: string | undefined = undefined;

    try {
      if (options?.withProgress) {
        threadId = uuidv4();
        yield put(updateProgressAction({ isLoading: true, threadId }));
      }
      // @ts-ignore
      const store = options?.withStore ? yield select((s: SHStore) => s[options!.withStore!]) : undefined;
      yield saga(a, store);
    } catch (error) {
      console.error(error);
    } finally {
      if (options?.withProgress) {
        yield put(finishProgressThreadAction({ threadId }));
      }
    }
  };
}
