//
// model.user.js
// stockhouse
//
// Created by Thomas Schönmann on 11.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// Model defining a user in this app's context.
//

import { SHUserRole } from "../../../shared/src/models/types/type.user";

/*
 *
 * Attributes.
 *
 */

/**
 * @deprecated Replace with individual permissions.
 */
export const userRoleAsMetric: Readonly<Record<SHUserRole, number>> = {
  NONE: 0,
  CUSTOMER: 100,
  STAFF: 200,
  ADMIN: 300,
  DEVELOPER: 400,
  CEO: 500
};

/*
 *
 * Functions.
 *
 */

/**
 * Compare a given user to a minimally required role.
 *
 * @param role
 * @param required
 */
export function getIsAuthorized(role?: SHUserRole, required?: SHUserRole) {
  if (!role) return false;
  else return getUserRoleAsMetric(role) >= getUserRoleAsMetric(required);
}

/**
 * Just a little helper to make transformations between metric <-> role easier.
 * @param role
 */
export function getUserRoleAsMetric(role?: SHUserRole): number {
  // Fallback in case the role isn't valid data.
  if (role === undefined || role === null) return 0;
  else return userRoleAsMetric[role] || 0;
}
