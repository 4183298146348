//
// reducer.notification.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {INIT, NotificationStore, NotificationStoreAction, UPDATE} from "../types/type.notification";

/*
 *
 * Attributes.
 *
 */

const store = getDefaultStore();

/*
 *
 * Functions.
 *
 */

export default function (s = store, a: NotificationStoreAction): NotificationStore {
  switch (a.type) {
    case INIT:
      return { ...s, provider: a.payload.provider };
    case UPDATE:
      return { ...s, ...a.payload };

    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

function getDefaultStore(): NotificationStore {
  return {};
}
