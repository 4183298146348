//
// action.notification.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
    ENQUEUE,
    EnqueueAction,
    EnqueuePayload,
    INIT,
    InitAction,
    InitPayload,
    UPDATE,
    UpdateAction,
    UpdatePayload,
} from "../types/type.notification";

/*
 *
 * Functions.
 *
 */

export const initNotificationAction = (payload: InitPayload): InitAction => ({
  type: INIT,
  payload,
});

export const updateNotificationAction = (payload: UpdatePayload): UpdateAction => ({
  type: UPDATE,
  payload,
});

export const enqueueNotificationAction = (payload: EnqueuePayload): EnqueueAction => ({
  type: ENQUEUE,
  payload,
});
