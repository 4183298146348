//
// action.root.tsx
//
// Created by Thomas on 06.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {RESET, ResetAction} from "../types/type.root";

/*
 *
 * Functions.
 *
 */

export const reset = (): ResetAction => ({
  type: RESET
});
