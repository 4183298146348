//
// Space.tsx
// stockhouse
//
// Created by Thomas Schönmann on 05.02.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { useMemo } from "react";
import { Theme, useTheme } from "@material-ui/core";

/*
 *
 * Interfaces.
 *
 */

type SpaceSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";

interface SpaceProps {
  vertical?: boolean;
  if?: boolean;
  of?: number;
  grow?: boolean;
  size?: SpaceSize;
}

/*
 *
 * Components.
 *
 */

export default (props: SpaceProps) => {
  const { size, of: scalar, vertical, grow } = props;
  const dimension = useDimension(size, scalar);

  if (grow) return <div style={{ flexGrow: dimension }} />;
  if (props.if !== undefined && !props.if) return null;

  return <div style={{ [vertical ? "height" : "width"]: dimension }} />;
};

/*
 *
 * Functions.
 *
 */

function useDimension(size?: SpaceSize, scalar?: number) {
  const theme = useTheme();
  return useMemo(() => getDimension(theme, size, scalar), [theme, size, scalar]);
}

function getDimension({ spacing }: Theme, size?: SpaceSize, scalar?: number) {
  switch (size) {
    case "xxs":
      return spacing(1);
    case "xs":
      return spacing(2);
    case "sm":
      return spacing(6);
    case "md":
      return spacing(10);
    case "lg":
      return spacing(16);
    case "xl":
      return spacing(32);

    default:
      return scalar || 0;
  }
}
