//
// model.image.tsx
//
// Created by Thomas on 06.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { firebase } from "./model.firebase";
// @ts-ignore
import { readAndCompressImage } from "browser-image-resizer";
import { storage } from "./model.firebase";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { StoragePath } from "./model.storage";
import imageLoader from "blueimp-load-image";
import { SHProductProducerArticleImageAsset } from "../../../shared/src/models/types/type.product";

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param props
 */
export async function uploadImagesFromInputEffect(props: {
  event: ChangeEvent<HTMLInputElement>;
  onProgress?: (n: number) => void;
}): Promise<SHProductProducerArticleImageAsset[]> {
  const { event, onProgress } = props;

  if (!event.target.files) {
    return [];
  }

  const input = event.target;
  const ref = storage.ref();
  const tuples: Array<SHProductProducerArticleImageAsset> = [];

  const totalProgress: number[] = [];
  let fileIndex = 0;

  // No '.map' on input.files
  Array.from(input.files!).forEach(() => {
    totalProgress.push(0);
  });

  await Promise.all(
    Array.from(input.files!).map(async (file) => {
      const cloudName = uuidv4();
      const storagePath = `${StoragePath.ProductPhotos}${cloudName}`;
      const previewPath = `${StoragePath.ProductPreviews}${cloudName}`;

      const url = await uploadImageEffect({
        fileRef: ref.child(storagePath),
        file: await optimizeImageFile({ file, quality: 0.7, maxHeight: 800, maxWidth: 800 }),
        onProgress: (progress) => {
          if (onProgress) {
            if (progress) totalProgress[fileIndex] = progress;
            const progressTotal = totalProgress.reduce((acc, v) => acc + v, 0);
            if (progressTotal) onProgress(Math.trunc(progressTotal / input.files!.length));
          }
        },
      });

      const previewUrl = await uploadImageEffect({
        fileRef: ref.child(previewPath),
        file: await optimizeImageFile({ file, quality: 0.4, maxWidth: 200, maxHeight: 200 }),
        onProgress: () => {},
      });

      tuples.push({
        url,
        previewUrl,
        createdAt: Date.now(),
      });

      fileIndex += 1;
    })
  );

  return tuples;
}

/**
 *
 * @param file
 * @param maxHeight
 * @param maxWidth
 * @param quality
 */
async function optimizeImageFile({
  file,
  maxHeight,
  maxWidth,
  quality = 1,
}: {
  file: File;
  maxHeight: number;
  maxWidth: number;
  quality: number;
}): Promise<File> {
  return new Promise((res, rej) => {
    imageLoader(
      file,
      (img, data) => {
        console.warn("file", file);
        console.warn("img", img);
        console.warn("data", data);

        const image = img as any;

        try {
          console.warn("img.src", image.src);
        } catch (e) {
          console.error("no img.src");
        }

        if (image.toBlob) {
          image.toBlob((blob: any) => {
            console.warn("blob", blob);
            res(blob);
          });
        } else {
          res(readAndCompressImage(file, { quality, maxHeight, maxWidth }));
        }
      },
      {
        orientation: true,
        maxHeight,
        maxWidth,
        canvas: true,
        contain: true,
        downsamplingRatio: quality,
      }
    );
  });
}

/**
 *
 * @param props
 */
export async function uploadImageEffect(props: {
  fileRef: firebase.storage.Reference;
  file: File;
  onProgress: (n: number) => void;
}): Promise<string> {
  const { fileRef, file, onProgress } = props;

  return new Promise((res, rej) => {
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: firebase.storage.UploadTaskSnapshot) =>
        snapshot.bytesTransferred && onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => rej(error),
      () => uploadTask.snapshot.ref.getDownloadURL().then(res)
    );
  });
}
