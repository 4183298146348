//
// user.d.ts
//
// Created by Thomas Schönmann on 14.07.2020
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { firestore } from "firebase";
import {
  Clientable,
  Expireable,
  Identifiable,
  SearchableStringTuple,
  SHCrudEntity,
  SHEntity,
  SHEntityRef,
  Timeable,
} from "./type.db";
import { SHAppThemeVariant } from "./type.theme";
import { SHPermissionGroups } from "./type.permission";

/*
 *
 * Interfaces.
 *
 */

/**
 * @deprecated
 */
export enum SHUserRole {
  NONE = "NONE",
  CUSTOMER = "CUSTOMER",
  STAFF = "STAFF",
  ADMIN = "ADMIN",
  DEVELOPER = "DEVELOPER",
  CEO = "CEO",
}

/**
 * Atomic permissions for each user. Improves upon role-based
 * authz for a more granular and flexible system.
 */
export enum SHUserPermission {}

export enum SHUserOrderBy {
  Name = "username",
  LastLogin = "lastLogin",
  Role = "role",
}

export interface SHUser extends SHEntity, Identifiable, Clientable {
  firstName: SearchableStringTuple;
  lastName: SearchableStringTuple;
  lastLogin: firestore.Timestamp;
  role: SHUserRole;
  client: SHEntityRef;
  email?: SHEntityRef;
  avatarUrl?: string;
  isActiveNow?: boolean;
}

export interface SHUserInvitation extends SHEntity, Timeable, Identifiable, Expireable {
  mail: string;
  firstName: string;
  lastName: string;
  permissions: SHPermissionGroups[];
  client: SHEntityRef;
  link: string;
}

export interface SHUserSettings extends SHCrudEntity {
  themeVariant?: SHAppThemeVariant;
  themeCustomName?: string;
  isUpdateNotificationDisabled?: boolean;
  textFieldVariant?: "standard" | "outlined" | "filled";
  textFieldSize?: "small" | "medium";
  traderViewSplitMode?: "header" | "horizontal";
  isProductDialogProducerNotAutoCollapsing?: boolean;
  tableSettings?: SHTableSettings;
}

export interface SHTableSettings {
  warehouse: SHTableColumn[];
  newProducts: SHTableColumn[];
  preSales: SHTableColumn[];
}

export interface SHTableColumn {
  id: string;
  label: string;
  visible: boolean;
}
