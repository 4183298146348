//
// type.media.tsx
//
// Created by Thomas on 22.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { SHProductProducerArticleImageAsset } from "../../../../shared/src/models/types/type.product";

export const UPDATE_MEDIA_STORE = "media/update";
export const RESET_MEDIA_STORE = "media/reset";

/*
 *
 * MARK: Store.
 *
 */

export interface MediaStore {
  openTarget?: MediaStoreOpenTarget;
  image?: SHProductProducerArticleImageAsset;
}

export type MediaStoreOpenTarget = "producer-article-image";

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateMediaStoreAction {
  type: typeof UPDATE_MEDIA_STORE;
  payload: UpdateMediaStorePayload;
}

export type UpdateMediaStorePayload = Partial<MediaStore>;

export interface ResetMediaStoreAction {
  type: typeof RESET_MEDIA_STORE;
}

/*
 *
 * MARK: Unions..
 *
 */

export type MediaStoreAction = UpdateMediaStoreAction | ResetMediaStoreAction;

export type MediaStorePayload = UpdateMediaStorePayload;
