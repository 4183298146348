//
// type.progress.tsx
//
// Created by Thomas on 12.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { UPDATE_PROGRESS } from "./type.appBar";

export const UPDATE = "progress/update";
export const RESET = "progress/reset";
export const FINISH_THREAD = "progress/finish-thread";

/*
 *
 * MARK: Store.
 *
 */

export interface ProgressStore {
  isLoading?: boolean;
  value?: number;
  buffer?: number;

  // Can be used to identify the last caller.
  origin?: "delete-product-core" | string;
  threads: string[];
}

/*
 *
 * MARK: Actions.
 *
 */

export interface UpdateProgressAction {
  type: typeof UPDATE_PROGRESS;
  payload: UpdateProgressPayload;
}

/** 'threadId' is used to to correctly resolve n-concurrent progress-requests. */
export type UpdateProgressPayload = Partial<ProgressStore> & { threadId?: string };

export interface ResetProgressAction {
  type: typeof RESET;
}

export interface FinishProgressThreadAction {
  type: typeof FINISH_THREAD;
  payload: FinishProgressThreadPayload;
}

export type FinishProgressThreadPayload = Pick<UpdateProgressPayload, "threadId">;

/*
 *
 * Union Action Type.
 *
 */

export type ProgressStoreAction = UpdateProgressAction | ResetProgressAction | FinishProgressThreadAction;

export type ProgressStorePayload = UpdateProgressPayload | FinishProgressThreadPayload;
