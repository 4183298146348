//
// i18n.js
// stockhouse
//
// Created by Thomas Schönmann on 22.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Internationalization!
//

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

/*
 *
 * MARK: Attributes.
 *
 */

export const dateLocaleFormatMap: Record<string, string> = {
  en: "MM/dd/yyyy",
  de: "dd.MM.yyyy",
};
export const dateTimeLocaleFormatMap: Record<string, string> = {
  en: "MM/dd/yyyy HH:mm",
  de: "dd.MM.yyyy HH:mm",
};

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param iso
 */
export async function getLocaleForIso(iso: string): Promise<Locale> {
  switch (iso) {
    case "de":
      return import("date-fns/locale/de").then((m) => m.default);
    default:
      return import("date-fns/locale/en-US").then((m) => m.default);
  }
}

/**
 *
 * @param iso
 */
export function getLocationCodeNameTuplesForIso(iso: string): Promise<Array<{ name: string; code: string }>> {
  switch (iso) {
    case "de":
      return import("./locations/i18n.location.codes.de.json").then((m) => m.default);
    default:
      return import("./locations/i18n.location.codes.en.json").then((m) => m.default);
  }
}

/*
 *
 * Interfaces.
 *
 */

export enum I18Namespace {
  Schema = "schema",
  Notifications = "notifications",
  Errors = "errors",
  Activity = "activity",
  Beta = "beta",
  Permissions = "permissions",
  Versions = "versions",
  Settings = "settings",
}

export enum I18nSchemaSubNamespace {
  ProductLables = "product-labels",
  TraderLabels = "trader-labels",
}

/*
 *
 * Setup.
 *
 */

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init  i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      backends: [XHR, LocalStorageBackend],
      backendOptions: [
        {
          /* below options */
        },
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json", // xhr load path for my own fallback
        },
      ],
    },
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then();

export default i18n;
