//
// NoMatchRoute.tsx
//
// Created by Thomas on 12.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, { memo } from "react";
import { Route, useHistory } from "react-router-dom";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import { ArrowTopLeft as ArrowTopLeftIcon } from "mdi-material-ui";
import Space from "../../layout/Space/Space";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SHUrlPath } from "../../models/types/type.route";

/*
 *
 * Interfaces.
 *
 */

interface NoRouteMatchProps {}

/*
 *
 * Components.
 *
 */

/**
 * Simple route to render if no other matches.
 *
 * Note: Only render this component at the end
 * of a <Switch>, else if gets rendered every time.
 */
export default memo<NoRouteMatchProps>(() => {
  return <Route path="*" component={NoRouteMatchContent} />;
});

const NoRouteMatchContent = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Space size="md" />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item className={classes.item}>
            <Typography style={{ textAlign: "center" }} variant="h6">
              No route matches your request
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowTopLeftIcon />}
              onClick={() => history.replace(SHUrlPath.Dashboard)}>
              <Typography variant="button">GO TO OVERVIEW</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

/*
 *
 * Functions.
 *
 */

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(3)
    },
    item: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);
