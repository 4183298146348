//
// saga.console.tsx
//
// Created by Thomas on 10.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

/*
 *
 * Functions.
 *
 */

export default function*() {
  yield console.log("no console-saga yet");
}
