//
// hook.notification.tsx
//
// Created by Thomas on 02.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import React, {useCallback, useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SHStore} from "../store/reducers";
import {NotificationStore, NotificationStoreAction, NotificationStorePayload} from "../store/types/type.notification";
import {useSnackbar} from "notistack";
import {initNotificationAction} from "../store/actions/action.notification";

/*
 *
 * Functions.
 *
 */

/**
 *
 */
export function useConnectedNotificationStore(): NotificationStore {
  return useSelector((s: SHStore) => s.notification, shallowEqual);
}

/**
 *
 * @param action
 */
export function useConnectedNotificationAction<A extends NotificationStoreAction, P extends NotificationStorePayload>(
  action: (p: P) => A
) {
  const dispatch = useDispatch();
  return useCallback((p: P) => dispatch(action(p)), [action, dispatch]);
}

/**
 *
 * @param action
 */
export function useConnectedNotificationEmptyAction<A extends NotificationStoreAction>(action: () => A) {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(action()), [action, dispatch]);
}

/**
 *
 */
export function useConnectedNotificationInit() {
  const init = useConnectedNotificationAction(initNotificationAction);
  const provider = useSnackbar();

  useEffect(() => {
    init({ provider });
  }, [provider, init]);
}
