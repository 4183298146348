//
// model.storage.tsx
//
// Created by Thomas on 06.03.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { storage } from "./model.firebase";

/*
 *
 * MARK: Enums.
 *
 */

export enum StoragePath {
  ProductPhotos = "products/photos/default/",
  ProductPreviews = "products/photos/previews/"
}

/*
 *
 * Functions.
 *
 */

/**
 *
 * @param props
 */
export async function removeStorageElementEffect(props: { url: string }) {
  const { url } = props;
  await storage.refFromURL(url).delete();
}
