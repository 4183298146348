//
// reducer.editor.js
// stockhouse
//
// Created by Thomas Schönmann on 28.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Root reducer for the redux state.
//

import { combineReducers } from "redux";

import user from "./reducer.user";
import appBar from "./reducer.appBar";
import app from "./reducer.app";
import integrations from "./reducer.integrations";
import console from "./reducer.console";
import warehouse from "./reducer.warehouse";
import userTasks from "./reducer.userTasks";
import error from "./reducer.error";
import notification from "./reducer.notification";
import root from "./reducer.root";
import manageUsers from "./reducer.manageUsers";
import progress from "./reducer.progress";
import newProduct from "./reducer.newProduct";
import media from "./reducer.media";
import trader from "./reducer.trader";
import sale from "./reducer.sale";
import presale from "./reducer.presale";
import disposal from "./reducer.disposal";

/*
 *
 * Functions.
 *
 */

const reducer = combineReducers({
  user,
  appBar,
  app,
  integrations,
  console,
  warehouse,
  userTasks,
  error,
  notification,
  manageUsers,
  progress,
  newProduct,
  media,
  trader,
  sale,
  presale,
  disposal,
});

/*
 *
 * Exports.
 *
 */

export default root(reducer);
export type SHStore = ReturnType<typeof reducer>;
