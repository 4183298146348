//
// reducer.disposal.tsx
//
// Created by Thomas on 08.10.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  ApplyDisposalCompanySelectionPayload,
  DisposalAction,
  DisposalPayload,
  DisposalStore,
  UpdateDisposalDialogDraftPayload,
} from "../types/type.disposal";
import { ReducerCaseFn } from "../util/util.type.reducer";

/*
 *
 * Interfaces.
 *
 */

type ReducerCase<P extends DisposalPayload> = ReducerCaseFn<"disposal", P>;

/*
 *
 * Attributes.
 *
 */

const store: DisposalStore = getDefaultStore();

/*
 *
 * Reducer.
 *
 */

export default function (s = store, a: DisposalAction): DisposalStore {
  switch (a.type) {
    case "disposal/update":
      return { ...s, ...a.payload };
    case "disposal/update-dialog":
      return { ...s, dialog: { ...s.dialog, ...a.payload } };
    case "disposal/update-dialog-disposal-draft":
      return updateDisposalDialogDraft(s, a.payload);

    case "disposal/close-dialog":
      return closeDisposalDialog(s);

    case "disposal/apply-company-selection":
      return applyDisposalTraderSelection(s, a.payload);

    case "disposal/open-dialog":
    case "disposal/remove":
    case "disposal/upsert-draft":
    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

/**
 *
 */
function getDefaultStore(): DisposalStore {
  return {
    dialog: {},
  };
}

/**
 *
 * @param s
 */
function closeDisposalDialog(s: DisposalStore): DisposalStore {
  return {
    ...s,
    isDisposalDialogOpen: false,
    dialog: {
      isTraderViewVisible: false,
      draft: undefined,
    },
  };
}

/**
 *
 * @param s
 * @param p
 */
const applyDisposalTraderSelection: ReducerCase<ApplyDisposalCompanySelectionPayload> = (s, p) => {
  return {
    ...s,
    dialog: {
      ...s.dialog,
      draft: { ...s.dialog.draft!, ...p },
      isTraderViewVisible: true,
    },
    isDisposalTraderSelectionDrawerOpen: false,
  };
};

/**
 *
 * @param s
 * @param p
 */
const updateDisposalDialogDraft: ReducerCase<UpdateDisposalDialogDraftPayload> = (s, p) => {
  return {
    ...s,
    dialog: {
      ...s.dialog,
      draft: { ...s.dialog.draft!, ...p },
    },
  };
};
