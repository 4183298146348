//
// hook.i18n.tsx
//
// Created by Thomas on 25.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { getLanguages } from "../i18n/languages/i18n.languages";
import { getLocaleForIso, getLocationCodeNameTuplesForIso, I18Namespace, I18nSchemaSubNamespace } from "../i18n/i18n";

/*
 *
 * Functions.
 *
 */

/**
 *
 */
export function useShorthandIsoLanguageKey() {
  const { i18n } = useTranslation();
  return i18n.language.substring(0, 2).toLocaleLowerCase();
}

/**
 * FIXME: data in de-json needs to be verified + data structure has to use {name, key}[].
 */
export function useI18nLanguageSuggestions() {
  const iso = useShorthandIsoLanguageKey();
  const [list, setList] = useState<string[]>();

  useEffect(() => {
    getLanguages(iso).then(setList);
  }, [iso]);

  return list;
}

/**
 *
 */
export function useI18nLocale() {
  const iso = useShorthandIsoLanguageKey();
  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    getLocaleForIso(iso).then(setLocale);
  }, [iso]);

  return locale;
}

/**
 *
 */
export function useI18nCountryCodeNameTuples(props: { isActive?: boolean }) {
  const iso = useShorthandIsoLanguageKey();
  const [tuples, setTuples] = useState<Array<{ name: string; code: string }>>();

  useEffect(() => {
    if (props.isActive) getLocationCodeNameTuplesForIso(iso).then(setTuples);
  }, [props.isActive, iso]);

  return tuples;
}

/**
 *
 * @param source
 */
export function useSchemaInputLabelTranslation(source: I18nSchemaSubNamespace) {
  return useTranslation(`${I18Namespace.Schema}/${source}`);
}

/**
 *
 * @param value     Number to use for formatting.
 * @param currency  EUR, USD, etc., gets translated to symbol if possible.
 */
export function useI18nCurrency(value: number, currency: string) {
  const iso = useShorthandIsoLanguageKey();
  return useMemo(() => new Intl.NumberFormat(iso, { style: "currency", currency }).format(value), [value, iso, currency]);
}
