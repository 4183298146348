//
// serverless.user.tsx
//
// Created by Thomas on 14.07.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {createCallableFn} from "./index";
import {
  CreateAccountInvitationPayload,
  CreateAccountInvitationRes
} from "../../../shared/src/models/types/type.serverless";
import {SHUserInvitation} from "../../../shared/src/models/types/type.user";

/*
 *
 * Endpoints.
 *
 */

enum ServerlessUserEndpoint {
  CreateAccountInvitation = "createAccountInvitation",
  SendInvite = "sendInvite",
  DeleteUser = "deleteUser",
  OnLogin = "onLogin"
}

/*
 *
 * Functions.
 *
 */

export const createAccountInvitation = createCallableFn<CreateAccountInvitationPayload, CreateAccountInvitationRes>(
  ServerlessUserEndpoint.CreateAccountInvitation
);

/**
 * @deprecated Handle in backend only.
 */
export const sendInvite = createCallableFn<SHUserInvitation, {}>(ServerlessUserEndpoint.SendInvite);

export const deleteUser = createCallableFn<{ uidToDelete: string }, undefined>(ServerlessUserEndpoint.DeleteUser);

export const onLogin = createCallableFn(ServerlessUserEndpoint.OnLogin);
