//
// model.db.js
// stockhouse
//
// Created by Thomas Schönmann on 10.05.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//
// Model for the DB & DBManagement.
//

import firebase from "firebase";
import { firestore } from "./model.firebase";
import { Continuous, SHCrudEntity, SHDomain, SHEntity, SHEntityRef } from "../../../shared/src/models/types/type.db";
import { SHUser } from "../../../shared/src/models/types/type.user";
import { SHClient } from "../../../shared/src/models/types/type.client";

/*
 *
 * Interfaces.
 *
 */

type FirestoreOpString = "<" | "<=" | "==" | ">" | ">=" | "array-contains" | "array-contains-any" | "in";

/*
 *
 * Classes.
 *
 */

export class DBError extends Error {
  constructor(s: string) {
    super(s);

    this.message = s;
  }
}

/*
 *
 * Functions.
 *
 */

/**
 * Simple default way to get a valid collection-reference for
 * a given client's ID.
 *
 * @param domain
 */
export function getCollectionRef(domain: SHDomain) {
  return firestore.collection(domain);
}

/**
 * Get client-constrained query for the db in the given domain.
 *
 * @param domain
 * @param client
 */
export function getClientDocumentsQueryRef({ domain, client }: { domain: SHDomain; client: string }) {
  return firestore.collection(domain).where("client", "==", client);
}

/**
 * Executes the callback function every time a new doc in the a collection
 * gets updated
 *
 * @param domain
 * @param client
 * @param callback
 */
export function clientCollectionListenerWithCallback<T extends SHEntity>({
  domain,
  client,
  callback,
}: {
  domain: SHDomain;
  client: string;
  callback: (entities: T[]) => Promise<void>;
}) {
  getCollectionRef(domain)
    .where("client", "==", client)
    .onSnapshot(async (snap) => callback(snap.docs.filter((d) => d.exists).map((d) => d.data() as T)));
}

/**
 *
 * @param props
 */
export function getDocumentsQueryRef<K extends SHEntity>(props: {
  domain: SHDomain;
  key: Extract<keyof K, string> | firebase.firestore.FieldPath;
  value: any;
  op: FirestoreOpString;
}) {
  return firestore.collection(props.domain).where(props.key, props.op, props.value);
}

/**
 *
 * @param props
 */
export async function getDocumentsQueryEntities<K extends SHEntity>(props: {
  domain: SHDomain;
  key: Extract<keyof K, string> | firebase.firestore.FieldPath;
  value: string | number | object | Array<string | number>;
  op: FirestoreOpString;
  order?: { key: Extract<keyof K, string>; dir: "asc" | "desc" };
}) {
  let query = getDocumentsQueryRef(props);
  if (props.order) query = query.orderBy(props.order.key, props.order.dir);

  return query.get().then((res) => {
    if (res.empty) return [];
    else return res.docs.map((d) => d.data() as K);
  });
}

/**
 *
 * @param props
 */
export async function getDocumentsQueryEntity<K extends SHEntity>(props: {
  domain: SHDomain;
  key: Extract<keyof K, string> | firebase.firestore.FieldPath;
  value: string | number | object | Array<string | number>;
  op: FirestoreOpString;
  order?: { key: Extract<keyof K, string>; dir: "asc" | "desc" };
}) {
  let query = getDocumentsQueryRef(props);
  if (props.order) query = query.orderBy(props.order.key, props.order.dir);

  return query.get().then((res) => {
    if (res.empty) return undefined;
    else {
      if (res.docs.length > 1) console.error("getDocumentsQueryEntity found > 1 doc for", props.value, "in", props.key);
      return res.docs[0].data() as K;
    }
  });
}

/**
 * Get all entities in a domain for a given client. Caution: can lead
 * to very large amounts of data!
 *
 * @param props
 */
export async function getClientEntities<T extends SHEntity>(props: { domain: SHDomain; client: SHEntityRef }): Promise<T[]> {
  return getClientDocumentsQueryRef(props)
    .get()
    .then((snap) => {
      if (snap.empty) return [];
      return snap.docs.filter((doc) => doc.exists).map((doc) => doc.data() as T);
    });
}

/**
 * Get a single KSEntity from the db by ID in the provided domain.
 *
 * @param props
 */
export async function getEntity<T extends SHEntity>(props: { domain: SHDomain; id: SHEntityRef }) {
  const snap = await getCollectionRef(props.domain).doc(props.id).get();

  if (snap.exists) {
    return snap.data() as T;
  }
}

/**
 * Little shortcut to find all docs that reference a given ID. The field name for the
 * reference has to be provided.
 *
 * @param props
 */
export async function getReferencedEntities<T extends SHEntity>(props: {
  domain: SHDomain;
  refId: SHEntityRef;
  refKey: Extract<keyof T, string>;
  multipleRef?: boolean;
}) {
  console.warn("reference entities", props);
  const op: FirestoreOpString = props.multipleRef ? "array-contains" : "==";
  const snap = await getCollectionRef(props.domain).where(props.refKey, op, props.refId).get();

  return snap.empty ? [] : snap.docs.map((doc) => doc.data() as T);
}

/**
 * Get a SHClient for a given user's ID. The ID
 * has to be linked to the KickScaleClient.
 *
 * @export
 * @param {string} uid
 * @returns
 */
export async function getClientForFirebaseUid(uid: string) {
  const { docs } = await firestore.collection(SHDomain.Clients).where("users", "array-contains", uid).get();

  if (docs.length === 0) throw Error("no clients for uid");
  return docs[0].data() as SHClient;
}

/**
 * Get a SHTeamMember for a given user's ID
 * and the associated client's ID.
 *
 * @export
 * @param {string} uid
 * @param {string} client
 * @returns {Promise<SHUser>}
 */
export async function getDbUserForUid(uid: string, client: string): Promise<SHUser> {
  const { docs, empty } = await firestore
    .collection(SHDomain.Users)
    .where("client", "==", client)
    .where(firebase.firestore.FieldPath.documentId(), "==", uid)
    .get();

  if (empty) throw Error(`[db][getDbUserUid] no data for ${uid}`);
  if (!docs[0].exists) throw Error(`[db][getDbUserUid] no data for ${uid}`);

  return docs[0].data() as SHUser;
}

/**
 * Special getter for data from the DB where a collection only contains
 * a single document per client.
 *
 * @export
 * @template T
 * @returns
 */
export async function getClientSingleDocumentData<T extends SHEntity>(props: { client: SHEntityRef; domain: SHDomain }) {
  const { client, domain } = props;
  const snap = await getClientDocumentsQueryRef({ domain, client }).get();

  return getSingleDocumentDataFromSnap<T>({ snap, client, domain });
}

/**
 * Acts as guard to only retrieve the single document.
 *
 * @export
 * @template T
 * @param {{ snap: firebase.firestore.QuerySnapshot }} props
 * @returns
 */
export function getSingleDocumentDataFromSnap<T extends SHEntity>(props: {
  snap: firebase.firestore.QuerySnapshot;
  client: string;
  domain: string;
}) {
  const { snap, client, domain } = props;

  //if (snap.empty) throw new DBError(`[getClientSingleDocumentData] no data for client ${client} in domain ${domain}`);
  if (snap.empty) return undefined;
  if (snap.docs.length > 1) throw new DBError(`[getClientSingleDocumentData] > 1 doc for client ${client} in domain ${domain}`);

  return snap.docs[0].data() as T;
}

/**
 * Plain update that assumes that the document exists.
 * Note: you can provide any key/val for T or string
 * to use firestore's nested object update.
 *
 * @param props
 */
export async function updateEntity<T extends SHEntity>(props: {
  domain: SHDomain;
  id: SHEntityRef;
  data: Partial<T> | Record<string, any>;
}) {
  const { domain, id, data } = props;

  return getCollectionRef(domain).doc(id).update(data);
}

/**
 * Upsert a specified document and return the id.
 *
 * @param props
 */
export async function upsertEntity<T extends SHCrudEntity>(props: {
  domain: SHDomain;
  client: string;
  data: Partial<T>;
}): Promise<T> {
  const { client, domain, data } = props;
  const snap = await getCollectionRef(domain);
  const now = Date.now();

  if (Boolean(data.id)) {
    const ref = snap.doc(data.id);

    const updatedData = { ...data, updatedAt: now, client };

    // We are not catching the error here -> if the id is set it an entity with this id should exist in the store.
    await ref.update(updatedData);
    return ref.get().then((res) => res.data() as T);
  } else {
    const newDoc = { ...data, createdAt: now, client };
    const created = await snap.add(newDoc);

    await created.update({ id: created.id });
    return { ...newDoc, id: created.id } as T;
  }
}

/**
 * Upsert a given entity and then update its continuousId as well as the
 * counter for all products for the client.
 *
 * Assigns and increments the global continuous-id-count if no 'id' is provided.
 * You can safely define a 'continuousId' during drafting (e.g. -1), as it has
 * no effect on the upsert. Upsert happens only if no 'id' is provided.
 *
 * @param props
 */
export async function upsertEntityWithContinuousId<T extends SHCrudEntity & Continuous>(props: {
  domain: SHDomain;
  client: string;
  data: Pick<SHCrudEntity, "id"> & Partial<T>;
}) {
  const { domain, client } = props;
  const entity = await upsertEntity(props);
  const entityRef = getCollectionRef(props.domain).doc(entity.id!);
  const counterRef = getCollectionRef(SHDomain.ContinuousIds).doc(client);

  // No need to increment - remember this is a upsert-fn,
  // and no create was needed, therefore no c-id increment.
  if (Boolean(props.data.id)) {
    return entity;
  }

  return firestore.runTransaction((transaction) => {
    return transaction.get(counterRef).then(async (res) => {
      if (res.exists) {
        console.log("c-ids exists", res.data());
        const doc = res.data() as Record<SHDomain, { count: number }>;
        const newCount = (doc[domain]?.count || 0) + 1;
        await Promise.all([
          transaction.update(counterRef, { [domain]: { count: newCount } }),
          transaction.update(entityRef, { continuousId: newCount }),
        ]);

        console.log("c-ids exists returns");

        return {
          ...entity,
          continuousId: newCount,
        };
      } else {
        console.log("c-ids != exists", res.data());
        await transaction.set(counterRef, { client, [domain]: { count: 1 } });
        await transaction.update(entityRef, { continuousId: 1 });

        console.log("c-ids != exists returns");

        return {
          ...entity,
          continuousId: 1,
        };
      }
    });
  });
}

/**
 *
 * @param props
 */
export async function createEntity<T extends SHEntity>(props: { domain: SHDomain; entity: T }) {
  const id = await getCollectionRef(props.domain)
    .add(props.entity)
    .then((d) => d.id);
  await updateEntity({ domain: props.domain, data: { ...props.entity, id }, id });
  return { ...props.entity, id };
}

/**
 *
 * @param props
 */
export async function removeEntity<T extends SHEntity>(props: { domain: SHDomain; id: SHEntityRef }) {
  return getCollectionRef(props.domain).doc(props.id).delete();
}
