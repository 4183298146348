//
// reducer.user.js
// stockhouse
//
// Created by Thomas Schönmann on 20.12.2018
// Copyright © 2018 expressFlow GmbH. All rights reserved.
//
// The user-reducer. Mostly about auth stuff.
//

import {
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
  LoginUserFailedPayload,
  LoginUserSucceededPayload,
  LOGOUT_USER,
  UPDATE,
  UPDATE_USER_STORE_VIEW,
  UserStore,
  UserStoreAction,
  UserStoreProfileTab,
} from "../types/type.user";

/*
 *
 * Attributes.
 *
 */

const store: UserStore = getDefaultStore();

/*
 *
 * Functions.
 *
 */

export default function (s = store, a: UserStoreAction): UserStore {
  switch (a.type) {
    case UPDATE:
      return { ...s, ...a.payload };
    case UPDATE_USER_STORE_VIEW:
      return { ...s, view: { ...s.view, ...a.payload } };

    case LOGIN_USER_SUCCEEDED:
      return loginUserSucceeded(s, a.payload);
    case LOGIN_USER_FAILED:
      return loginUserFailed(s, a.payload);
    case LOGOUT_USER:
      return getDefaultStore();

    default:
      return s;
  }
}

/*
 *
 * Helpers.
 *
 */

function loginUserSucceeded(state: UserStore, payload: LoginUserSucceededPayload): UserStore {
  return { ...state, ...payload, state: "loggedIn" };
}

function loginUserFailed(state: UserStore, { error }: LoginUserFailedPayload): UserStore {
  return { ...state, errors: { ...state.errors, user: error } };
}

function getDefaultStore(): UserStore {
  return {
    state: "init",
    errors: {},
    view: {
      selectedUserProfileTab: UserStoreProfileTab.core,
    },
  };
}
