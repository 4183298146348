//
// reducer.progress.tsx
//
// Created by Thomas on 12.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  FinishProgressThreadPayload,
  ProgressStore,
  ProgressStoreAction,
  ProgressStorePayload,
  UpdateProgressPayload,
} from "../types/type.progress";
import { ReducerCaseFn } from "../util/util.type.reducer";

/*
 *
 * Interfaces.
 *
 */

type ReducerCase<P extends ProgressStorePayload> = ReducerCaseFn<"progress", P>;

/*
 *
 * Attributes.
 *
 */

const store: ProgressStore = getDefaultStore();

/*
 *
 * Reducer.
 *
 */

export default function (s = store, a: ProgressStoreAction): ProgressStore {
  switch (a.type) {
    case "appBar/update/progress":
      return update(s, a.payload);
    case "progress/finish-thread":
      return finishThread(s, a.payload);

    case "progress/reset":
      return getDefaultStore();

    default:
      return s;
  }
}

/*
 *
 * Functions.
 *
 */

/**
 *
 */
function getDefaultStore(): ProgressStore {
  return {
    threads: [],
  };
}

/**
 *
 * @param s
 * @param p
 */
const update: ReducerCase<UpdateProgressPayload> = (s, p) => {
  const threads = p.threadId
    ? p.isLoading
      ? [...s.threads, p.threadId]
      : [...s.threads.filter((id) => id !== p.threadId)]
    : s.threads;

  return {
    ...s,
    ...p,
    threads,
    isLoading: threads.length > 0,
  };
};

/**
 *
 * @param s
 * @param p
 */
const finishThread: ReducerCase<FinishProgressThreadPayload> = (s, p) => {
  const threads = s.threads.filter((id) => id !== p.threadId);
  return { ...s, threads, isLoading: threads.length > 0 };
};
