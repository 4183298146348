//
// saga.shop.ts
// stockhouse
//
// Created by Thomas Schönmann on 03.09.2019
// Copyright © 2019 expressFlow GmbH. All rights reserved.
//

import {call, put, takeEvery} from "redux-saga/effects";

import {INIT_REQ, InitAction} from "../types/type.shop";
import {initFailedAction, initSucceededAction, updateAction} from "../actions/action.shop";
import {SearchFilter, SearchNativeSelectContainer, SearchNativeSelectElement} from "../../models/types/type.search";

/*
 *
 * Functions.
 *
 */

export default function*() {
  yield takeEvery(INIT_REQ, initSaga);
}

function* initSaga(a: InitAction) {
  try {
    // DEBUG:
    const {
      productFieldFilter,
      productFieldSelection
    }: { productFieldFilter: SearchFilter; productFieldSelection: SearchNativeSelectContainer } = yield call(getDebugSelectors);

    yield put(updateAction({ filterSelectors: [productFieldSelection], filters: [productFieldFilter] }));

    yield put(initSucceededAction());
  } catch (error) {
    console.error(error);
    yield put(initFailedAction());
  }
}

function getDebugSelectors() {
  const productFieldFilter: SearchFilter = {
    id: "productFields",
    lenses: [
      {
        field: "continousId",
        type: "number",
        comp: "=="
      },
      { field: "warehouse.position" },
      { field: "warehouse.count", type: "number", comp: "==" },
      { field: "device.manufacturer" }
    ]
  };

  const productFieldSelection: SearchNativeSelectContainer = {
    id: productFieldFilter.id,
    elements: productFieldFilter.lenses.map<SearchNativeSelectElement>(e => ({ value: e.field, t: e.field }))
  };

  return { productFieldFilter, productFieldSelection };
}
