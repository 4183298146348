//
// reducer.warehouse.tsx
//
// Created by Thomas on 14.02.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import {
  CHANGE_STATE,
  CLONE_DRAFT,
  CloneDraftAction,
  INIT_NEW_PRODUCT,
  INIT_PRODUCT_DIALOG,
  PRODUCT_UPSERT_FAILED,
  PRODUCT_UPSERT_SUCCEEDED,
  ProductUpsertSucceededAction,
  REMOVE_DRAFT,
  RESET,
  SELECT_DRAFT,
  SELECT_PRODUCER_CORE,
  SELECT_PRODUCT,
  SelectDraftAction,
  UNSELECT_PRODUCT,
  UPDATE,
  UPDATE_CACHE,
  UPDATE_INPUT,
  UPDATE_PRODUCER,
  UPDATE_PRODUCER_ARTICLE_IMAGES,
  UPDATE_PRODUCT_CORE_DRAFT,
  UPDATE_PRODUCT_CORE_DRAFT_VALUES,
  UPDATE_PRODUCT_CORE_VALUES,
  UpdateProducerArticleImagesAction,
  UpdateWarehouseInputAction,
  WarehouseStore,
  WarehouseStoreAction,
  WarehouseStoreProductCoreDraft
} from "../types/type.warehouse";
import { v4 as uuidv4 } from "uuid";
import { getHotDraftBackIntoAllDrafts } from "../util/util.mix.draft";

/*
 *
 * Attributes.
 *
 */

const store: WarehouseStore = getDefaultStore();

/*
 *
 * Functions.
 *
 */

export default function (s = store, a: WarehouseStoreAction): WarehouseStore {
  switch (a.type) {
    case INIT_PRODUCT_DIALOG:
      return { ...s, state: s.state === "init" ? "selectProducer" : s.state, ...a.payload };
    case UPDATE:
      return { ...s, ...a.payload };
    case UPDATE_INPUT:
      return updateInput(s, a);
    case UPDATE_PRODUCER:
      return { ...s, ...a.payload };
    case UPDATE_PRODUCT_CORE_VALUES:
      return {
        ...s,
        selectedProductCore: {
          ...s.selectedProductCore!,
          values: { ...(s.selectedProductCore!.values || {}), ...a.payload },
        },
      };
    case UPDATE_CACHE:
      return { ...s, cache: { ...s.cache, ...a.payload } };
    case UPDATE_PRODUCT_CORE_DRAFT_VALUES:
      return {
        ...s,
        selectedDraft: {
          ...s.selectedDraft!,
          entity: { ...s.selectedDraft!.entity, values: { ...s.selectedDraft!.entity!.values, ...a.payload } },
        },
      };
    case UPDATE_PRODUCT_CORE_DRAFT:
      return { ...s, selectedDraft: { ...s.selectedDraft!, ...a.payload } };
    case UPDATE_PRODUCER_ARTICLE_IMAGES:
      return updateProducerArticleImages(s, a);

    case CHANGE_STATE:
      return { ...s, state: a.payload.state };

    case RESET:
      switch (a.payload.variant) {
        case "product-upsert":
          return {
            ...s,
            state: "init",
            producerCore: undefined,
            producerArticle: undefined,
            producerMedia: undefined,
            products: undefined,
            selectedProductCore: undefined,
            selectedDraft: undefined,
            inputProducer: getDefaultStore().inputProducer,
            cache: getDefaultStore().cache,
            drafts: [],
            isProducerFormCollapsed: undefined,
            isProductUpsertDialogOpen: undefined,
          };
        case "all":
        default:
          return getDefaultStore();
      }

    case PRODUCT_UPSERT_SUCCEEDED:
      return productUpsertSucceeded(s, a);
    case PRODUCT_UPSERT_FAILED:
      return { ...s, state: "init" };

    case "producer/create/req":
      return { ...s, state: "createProducerData" };
    case "producer/create/suc":
      return { ...s, state: "productsList", ...a.payload };
    case "producer/create/err":
      return s;

    case SELECT_PRODUCT:
      return { ...s, selectedProductCore: a.payload.product, state: "productDetail", isProducerFormCollapsed: true };
    case UNSELECT_PRODUCT:
      return { ...s, selectedProductCore: undefined, state: "productsList" };
    case INIT_NEW_PRODUCT:
      return { ...s, state: "createProductCore", isProducerFormCollapsed: true };
    case SELECT_PRODUCER_CORE:
      return { ...s, producerCore: a.payload.core };

    case CLONE_DRAFT:
      return cloneDraft(s, a);
    case SELECT_DRAFT:
      return selectDraft(s, a);
    case REMOVE_DRAFT:
      const updated = s.drafts.filter((d) => d.draftId !== s.selectedDraft?.draftId);
      return { ...s, drafts: updated, selectedDraft: updated[updated.length - 1] };

    // Processed in saga.
    case "warehouse/product/upsert/req":
    case "warehouse/product/delete-core":
    case "warehouse/product/select-from-other-context":
    case "warehouse/remove/image-from-producer-article-media":
    case "warehouse/upload/images-from-input":
    default:
      return s;
  }
}

/*
 *
 * MARK: Helpers.
 *
 */

function updateInput(s: WarehouseStore, a: UpdateWarehouseInputAction): WarehouseStore {
  if (s.state !== "productsList" && s.state !== "selectProducer") {
    console.warn("reducer - warehouse - won't 'updateInput'");
    return s;
  }

  let mutation: WarehouseStore = { ...s };

  if (a.payload.producerArticle)
    mutation = {
      ...mutation,
      producerArticle: undefined,
      products: undefined,
      producerMedia: undefined,
      selectedProductCore: undefined,
      inputProducer: {
        ...mutation.inputProducer,
        producerPosition: "",
        producerArticleNr: "",
        producerArea: "",
      },
    };
  if (a.payload.producerName) {
    mutation = {
      ...mutation,
      producerArticle: undefined,
      products: undefined,
      producerMedia: undefined,
      selectedProductCore: undefined,
      inputProducer: {
        ...mutation.inputProducer,
        producerPosition: "",
        producerArticleNr: "",
        producerArea: "",
        producerArticle: "",
      },
    };
  }

  return {
    ...mutation,
    inputProducer: {
      ...mutation.inputProducer,
      ...a.payload,
    },
  };
}

/**
 *
 * @param s
 * @param a
 */
function productUpsertSucceeded(s: WarehouseStore, a: ProductUpsertSucceededAction): WarehouseStore {
  const cores = a.payload.productCores;
  const store: WarehouseStore = {
    ...s,
    state: "productsList",
    drafts: [],
    selectedDraft: undefined,
    selectedProductCore: undefined,
  };

  // We append/update existing entries only if product cores are already loaded.
  // If they aren't yet loaded, we don't create them here, as the list gets lazy
  // populated with the cores at mount.
  if (store.products && cores) {
    cores.forEach((core) => {
      if (store.products!.every((p) => p.id !== core.id)) {
        store.products = [core, ...store.products!];
      } else {
        const i = store.products!.findIndex((p) => p.id === core.id);
        store.products![i] = core;
      }
    });
  }

  return store;
}

/**
 * Copy the exiting hot draft back into the list of all drafts and clone
 * the currently selected one.
 *
 * @param s
 * @param a
 */
function cloneDraft(s: WarehouseStore, a: CloneDraftAction): WarehouseStore {
  const latestDrafts = getHotDraftBackIntoAllDrafts(s.drafts, s.selectedDraft!);
  const source = latestDrafts[a.payload.index];
  const clone: WarehouseStoreProductCoreDraft = {
    draftId: uuidv4(),
    count: 1,
    entity: { ...source.entity, values: { ...source.entity.values, "_serial-nr": "" } },
  };
  return { ...s, drafts: [...latestDrafts, clone], selectedDraft: clone };
}

/**
 * Copy the existing hot draft back into the list of all drafts and select
 * the new one by index.
 *
 * @param s
 * @param a
 */
function selectDraft(s: WarehouseStore, a: SelectDraftAction): WarehouseStore {
  return { ...s, drafts: getHotDraftBackIntoAllDrafts(s.drafts, s.selectedDraft!), selectedDraft: s.drafts[a.payload.index] };
}

/**
 *
 * @param s
 * @param a
 */
function updateProducerArticleImages(s: WarehouseStore, a: UpdateProducerArticleImagesAction): WarehouseStore {
  const { images, variant } = a.payload;

  if (!s.producerMedia) return s;
  else if (variant === "append")
    return { ...s, producerMedia: { ...s.producerMedia, images: [...s.producerMedia.images, ...images] } };
  else
    return {
      ...s,
      producerMedia: {
        ...s.producerMedia,
        images: [...s.producerMedia.images.filter((img) => images.every((i) => i.url !== img.url))],
      },
    };
}

/**
 *
 */
function getDefaultStore(): WarehouseStore {
  return {
    state: "init",
    inputProducer: {
      producerName: "",
      producerArticle: "",
      producerArticleNr: "",
      producerArea: "",
      producerPosition: "",
      countNewProducts: 1,
    },
    cache: {
      producerCores: [],
      producerArticles: [],
    },
    drafts: [],
  };
}
