import {
  CHANGE_ORDER,
  ChangeUserAction,
  ChangeUserOrderAction,
  ChangeUserOrderPayload,
  ChangeUserPayload,
  ChangeUserSucceededAction,
  ChangeUserSucceededPayload,
  CleanupUserAction,
  StoreUserPersonalInfoAction,
  StoreUserPersonalInfoPayload,
  USER_MANAGEMENT_CHANGE_USER_REQ,
  USER_MANAGEMENT_CHANGE_USER_SUCCEEDED,
  USER_MANAGEMENT_CLEANUP_USER,
  USER_MANAGEMENT_PERSONAL_INFO,
} from "../types/type.manageUsers";

export const changeUserOrder = (payload: ChangeUserOrderPayload): ChangeUserOrderAction => ({
  type: CHANGE_ORDER,
  payload,
});

// load current user for user managment

export const changeUser = (payload: ChangeUserPayload): ChangeUserAction => ({
  type: USER_MANAGEMENT_CHANGE_USER_REQ,
  payload
});

export const changeUserSucceeded = (payload: ChangeUserSucceededPayload): ChangeUserSucceededAction => ({
  type: USER_MANAGEMENT_CHANGE_USER_SUCCEEDED,
  payload
});

export const cleanupUserAction = (): CleanupUserAction => ({
  type: USER_MANAGEMENT_CLEANUP_USER
});

// store changes to personal info

export const storeUserPersonalInfo = (payload: StoreUserPersonalInfoPayload): StoreUserPersonalInfoAction => ({
  type: USER_MANAGEMENT_PERSONAL_INFO,
  payload
});
