//
// hook.presale.tsx
//
// Created by Thomas on 27.08.20
// Copyright © 2020 expressFlow GmbH. All rights reserved.
//

import { getConnectedActionHook, getConnectedEmptyActionHook, getConnectedStore } from "../store/util/util.hook";
import { PresaleStoreAction, PresaleStorePayload } from "../store/types/type.presale";

/*
 *
 * Functions.
 *
 */

export const useConnectedPresaleStore = getConnectedStore("presale");

export const useConnectedPresaleStoreAction = getConnectedActionHook<PresaleStoreAction, PresaleStorePayload>();

export const useConnectedPresaleStoreEmptyAction = getConnectedEmptyActionHook<PresaleStoreAction>();
